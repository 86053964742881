.gradient-hero {
  margin-top: 140px;
  background: $bg-gradient-1-reverse;
  padding-top: 80px;
  color: white;
  padding-bottom: 20px;
  margin-bottom: 100px;
  @include media-breakpoint-down(sm) {
    text-align: center;
    margin-top: 75px;
    padding-top: 30px;
    padding-bottom: 15px;
    margin-bottom: 0; }
  &--lg {
    @include media-breakpoint-down(md) {
      padding-top: 70px;
      padding-bottom: 50px; } }
  &--center-mobile {
    display: flex;
    align-items: center;
    min-height: 200px; }
  &__content {
    width: 100%; }
  &--text {
    background: $bg-gradient-1;
    min-height: 420px;
    margin-bottom: 60px;
    padding-top: 100px;
    @include media-breakpoint-down(lg) {
      padding-top: 85px; }
    @include media-breakpoint-down(sm) {
      min-height: 1px;
      padding-top: 60px;
      padding-bottom: 40px; }
    @include media-breakpoint-down(xs) {
      margin-bottom: 0; } }
  &--slider {
    padding-bottom: 205px;
    @include media-breakpoint-down(sm) {
      padding-top: 42px;
      padding-bottom: 185px; } }
  &__title {
    @include f-header;
    margin-bottom: 40px;
    &--mb-lg {
      margin-bottom: 63px; }
    @include media-breakpoint-down(sm) {
      @include f-title-forced;
      font-weight: 700;
      margin-bottom: 20px; }
    &--mobile-mb-lg {
      @include media-breakpoint-down(sm) {
        margin-bottom: 45px; } }
    &--mobile-mb-md {
      @include media-breakpoint-down(sm) {
        margin-bottom: 30px; } }
    &--mt {
      @include media-breakpoint-up(md) {
        margin-top: 100px; }
      @include media-breakpoint-up(lg) {
        margin-top: 55px; } } }
  &__text {
    color: #eaeaea;
    margin-bottom: 24px;
    &--lg {
      @include f-body-lg; } }
  &__subtitle {
    @include f-title;
    @include media-breakpoint-down(sm) {
      line-height: 30px; }
    &--mb {
      margin-bottom: 24px; }
    &--sm {
      @include f-subtitle; } }
  &__image {
    margin-bottom: -120px;
    width: 100%;
    object-fit: cover;
    @include media-breakpoint-down(sm) {
      display: none; }
    &--sm {
      margin-bottom: -78px; }
    &--lg {
      min-height: 458px; } } }
