.text-info {
  &__title {
    font-family: $title-ff;
    font-size: 40px;
    font-weight: 600;
    @include f-subtitle;
    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 30px; }
    &--lg {
      font-size: 40px;
      line-height: 56px;
      font-weight: 700;
      margin-bottom: 40px; } }
  &__content {
    &--lg {
      @include f-body-lg; }
    p {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0; } } } }
