.number-info {
  display: flex;
  align-items: center;
  min-height: 140px;
  margin-bottom: 60px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
    padding-right: 10px;
    min-height: 1px; }
  &__digin {
    margin-right: 40px;
    font-size: 100px;
    line-height: 122px;
    color: $text-special-color;
    font-weight: 700;
    @include media-breakpoint-down(sm) {
      font-size: 75px;
      line-height: 91px; } }
  &__text {
    max-width: 270px;
    flex-shrink: 0;
    @include f-body-lg;
    @include media-breakpoint-down(sm) {
      max-width: 66vw; } } }
