.info-row {
  &:not(:last-child) {
    margin-bottom: 100px;
    @include media-breakpoint-down(md) {
      margin-bottom: 80px; }
    @include media-breakpoint-down(sm) {
      margin-bottom: 60px; } }
  &__title {
    @include f-header;
    margin-bottom: 20px;
    color: $text-special-color;
    @include media-breakpoint-down(sm) {
      font-size: 30px;
      line-height: 40px; } }
  &__content {
    p {
      &:not(:last-child) {
        margin-bottom: 16px; } }
    ul {
      padding: 0;
      list-style-type: none;
      margin-bottom: 0;
      &:not(:last-child) {
        margin-bottom: 16px; }
      li {
        &:not(:last-child) {
          margin-bottom: 16px; }
        &::before {
          content:  '- '; } } } }
  &__icon {
    width: 100%;
    height: auto;
    @include media-breakpoint-down(sm) {
      width: 65px;
      height: 65px;
      margin-bottom: 20px; } }


  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse; } }
