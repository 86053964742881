.feedback {
  padding: 10px 20px;
  display: inline-block;
  &--danger {
    background: rgba(225, 38, 38, 0.1);
    color: $danger; }
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px; } } }
