.gradient-hero-slider {
  margin-top: -233px;
  margin-bottom: 60px;
  position: relative;
  @include media-breakpoint-down(md) {
    margin-bottom: 80px; }
  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
    margin-top: -95px;
    .container {
      padding-right: 0;
      padding-left: 0; } }
  &__item {
    width: 100%;
    height: auto; }
  &__controls {
    position: absolute;
    right: 0;
    top: -94px;
    display: flex;
    @include media-breakpoint-down(sm) {
      top: -80px;
      right: 20px; }
    .btn {
      &:not(:last-child) {
        margin-right: 20px; } } }
  &__btn {
    padding-right: 31px;
    padding-left: 31px;
    &[disabled] {
      opacity: 0;
      pointer-events: none; } } }

