.human-info {
  padding-top: 100px;
  padding-bottom: 100px;
  @include media-breakpoint-down(sm) {
    padding-top: 50px;
    padding-bottom: 37px; }
  &__title {
    @include f-header;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      font-size: 30px;
      line-height: 37px; } }
  &__subtitle {
    @include f-subheader;
    margin-bottom: 0;
    &--mb {
      margin-bottom: 24px;
      @include media-breakpoint-down(sm) {
        font-size: 21px;
        line-height: 30px; } } } }
