//@import "./normalize"
@import "./vars";
@import "./mixins";
@import "./fonts";
//@import "./sprite_generated"

// Bootstrap compatible gutter variable => $gutter
$grid-gutter-width: 40px;
$gutter: $grid-gutter-width;

$grid-breakpoints-for-gridmaker:  ( xs: 0, sm: 800px, md: 1199px, lg: 1601px);

$grid-breakpoints: ( xs: 0, sm: 800px, md: 1199px, lg: 1599px, xl: 1900px);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: ( sm: 800px, md: 1040px, lg: 1440px, xl: 1441px);


// IMPORT SECTION
@import '../../libs/bootstrap/scss/bootstrap-reboot.scss';
@import '../../libs/bootstrap/scss/mixins/_breakpoints.scss';
@import '../../libs/bootstrap/scss/bootstrap-grid.scss';


@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .text#{$infix}-left {
      text-align: left !important; }
    .text#{$infix}-right {
      text-align: right !important; }
    .text#{$infix}-center {
      text-align: center !important; } } }


@import '../../libs/animate.css/source/fading_entrances/fadeInUp';
@import '../../libs/animate.css/source/fading_entrances/fadeInRight';
@import '../../libs/animate.css/source/fading_entrances/fadeIn';

@import '../../libs/@splidejs/splide/dist/css/splide-core.min';
@import '../../libs/flatpickr/dist/flatpickr.min';
//@import '../../libs/hint.css/hint.min'
@import '../../libs/html-hint/dist/html-hint.min';

.hint {
  &__content {
    width: 220px;
    background: $primary;
    color: white;
    font-size: 12px;
    line-height: 18px;
    padding: 10px;
    box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.12);
    border-radius: 10px;
    z-index: 6 !important; }
  &__title {
    font-size: 14px;
    font-family: $title-ff;
    line-height: 17px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 10px; }
  &__text {
    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0; } } } }

.hint--right .hint__content {
    transform: none;
    top: 0;
    bottom: unset;
    text-transform: none;
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      left: -10px;
      top: 13px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 10.4px 6px 0;
      border-color: transparent $primary transparent transparent; } }

.hint--bottom {
  .hint__content {
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 10.4px 6px;
      border-color: transparent transparent $primary transparent; } } }


.hint--right:focus .hint__content,
.hint--right:hover .hint__content {
    transform: translateX(5px); }
.hint--right {
  .hint__content {
    margin-bottom: 0; } }

// From libs:
//@import "../../libs/LIB_NAME/path/to/style.scss"

* {
    box-sizing: border-box;
 }    // min-height: 0.001vw; // — Fixes Safari bug with viewport units in calc()

body {
    display: flex;
    flex-direction: column;
    background: #FFF;
    font-family: $text-ff;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 24px;
    &.body-other {
      background: $bg-gray; } }

a {
  text-decoration: none;
  color: $title-text-color;
  transition: color .1s ease-in-out;
  &:hover {
    text-decoration: underline;
    color: $primary; } }

.row {
  &:not(.no-gutters) {
    @include media-breakpoint-down(sm) {
      margin-right: -10px;
      margin-left: -10px;
      > .col,
      > [class*="col-"] {
        padding-right: 10px;
        padding-left: 10px; } } } }

.wrapper {
  width: 100%;
  max-width: 850px;
  min-width: 320px;
  margin: 0 auto;
  padding: 0 40px; }


@mixin f-header {
  font-size: $fz-header;
  line-height: $lh-header;
  font-family: $title-ff;
  font-weight: 700;
  @include media-breakpoint-down(md) {
    font-size: 48px;
    line-height: 58.51px; } }

.f-header {
  @include f-header; }

@mixin f-subheader-forced {
  font-size: $fz-subheader;
  line-height: $lh-subheader;
  font-family: $title-ff;
  font-weight: 700; }

@mixin f-subheader {
  font-size: $fz-subheader;
  line-height: $lh-subheader;
  font-family: $title-ff;
  font-weight: 700;
  @include media-breakpoint-down(md) {
    font-size: 30px;
    line-height: 36.57px; } }

.f-subheader {
  @include f-subheader; }



@mixin f-title-forced {
  font-size: $fz-title;
  line-height: $lh-title;
  font-family: $title-ff;
  font-weight: 600; }

@mixin f-title {
  font-size: $fz-title;
  line-height: $lh-title;
  font-family: $title-ff;
  font-weight: 600;
  @include media-breakpoint-down(md) {
    font-size: 21px;
    line-height: 36px; } }

.f-title {
  @include f-title; }

@mixin f-subtitle {
  font-size: $fz-subtitle;
  line-height: $lh-subtitle;
  font-family: $title-ff;
  font-weight: 600; }

.f-subtitle {
  @include f-subtitle; }

.f-tabs {
  font-size: $fz-tabs;
  line-height: $lh-tabs; }

@mixin f-body-lg {
  font-size: $fz-body-lg;
  line-height: $lh-body-lg; }

.f-body-lg {
  @include f-body-lg; }


@mixin f-body {
  font-size: $fz-body;
  line-height: $lh-body; }


.f-body {
  @include f-body; }

@mixin small-caps {
  font-family: $title-ff;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  text-transform: uppercase; }

.font-weight-medium {
  font-weight: 500; }

.font-weight-semibold {
  font-weight: 600; }

.font-weight-bold {
  font-weight: 700; }

.demo {
  background: #e5e5e5;
  padding: 20px 50px 50px;
  p {
    &:not([class*="demo"]) {
      margin-top: 0;
      margin-bottom: 10px; } }
  &__title {
    color: $placeholder-text-color;
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 30px;
    font-style: italic; }
  button {
    margin-right: 20px;
    margin-bottom: 20px; } }

.text-primary {
  color: $primary; }

.m-0 {
  margin: 0 !important; }
.p-0 {
  padding: 0 !important; }

.wow {
 }  //visibility: hidden

.modal-instructor {
  width: 160px;
  height: 160px;
  object-fit: cover;
  margin-bottom: 40px;
  margin-top: 00px;
  border-radius: 20px; }

.instructor-main {
  margin-top: -298px;
  width: 100%;
  height: auto;
  filter: drop-shadow(-10px 10px 40px rgba(33, 78, 119, 0.12));
  @include media-breakpoint-down(md) {
    margin-top: -161px; }
  @include media-breakpoint-down(sm) {
    margin-bottom: 68px;
    margin-top: -200px; }
  @include media-breakpoint-down(xs) {
    margin-bottom: 46px;
    margin-top: -144px;
    height: 190px;
    object-fit: cover; } }

.thumbnail-preview-list {
  margin-bottom: 96px;
  @include media-breakpoint-down(md) {
    margin-bottom: 60px; }
  @include media-breakpoint-down(sm) {
    //hide all cards after 4
    > [class*="col"] {
      &:nth-child(4) {
        ~ * {
          display: none; } } } }
  @include media-breakpoint-down(xs) {
    margin-bottom: 0; } }

.our-facilities-list {
  margin-bottom: 39px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 17px; }
  @include media-breakpoint-down(md) {
    margin-bottom: 20px; } }

.comment-list {
  &__btn {
    margin-top: 18px; }
  margin-bottom: 136px;
  @include media-breakpoint-down(md) {
    margin-bottom: 100px; }
  @include media-breakpoint-down(xs) {
    margin-bottom: 40px; } }

.star {
  background: url(../images/sprites/main.stack.svg#image-star-warning);
  background-repeat: no-repeat; }

//.big-statistic-slider
//@include media-breakpoint-down(sm)
//  margin-top: 56px
//@include media-breakpoint-down(xs)
//  margin-top: 39px

.d-block {
  display: block; }
.d-none {
  display: none; }
.d-md-none {
  @include media-breakpoint-up(md) {
    display: none; } }

.d-md-block {
  @include media-breakpoint-up(md) {
    display: block; } }

.d-md-inline-block {
  @include media-breakpoint-up(md) {
    display: inline-block; } }


.d-lg-block {
  @include media-breakpoint-up(lg) {
    display: block; } }

.d-lg-none {
  @include media-breakpoint-up(lg) {
    display: none; } }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-start {
  justify-content: flex-start; }

.d-flex {
  display: flex; }

.overflow-hidden {
  overflow: hidden; }

.big-statistic-slider {
  &__control {
    display: none;
    height: 6px;
    background: #EAEAEA;
    position: absolute;
    left: 0;
    left: 10px;
    right: 10px;
    top: 12px;
    @include media-breakpoint-down(sm) {
      display: block; }
    @include media-breakpoint-down(xs) {
      top: -12px; }
    &-in {
      display: block;
      height: 12px;
      background: #C2DEF0;
      width: 10%;
      transition: width 0.3s ease-in-out;
      position: absolute;
      top: -3px;
      left: 0; } } }

.text-placeholder-color {
  color: $placeholder-text-color; }

.text-color {
  color: $text-color; }

.text-special-color {
  color: $text-special-color; }

.text-center-mobile {
  @include media-breakpoint-down(sm) {
    text-align: center; } }

.p-static {
  position: static; }
.p-relative {
  position: relative; }
.img-thumbnail {
  width: 100%;
  height: auto; }
.custom-side-image {
  position: absolute;
  left: 50%;
  width: 33.9vw;
  transform: translateX(16.1vw);
  height: auto;
  max-height: 650px;
  object-fit: cover;
  @include media-breakpoint-down(md) {
    position: static;
    transform: none; }
  @include media-breakpoint-down(sm) {
    width: 75vw;
    height: auto;
    margin-left: auto;
    display: block;
    margin-bottom: 60px;
    position: relative;
    transform: none;
    left: 20px; } }

.row-sm-reverse {
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse; } }

.contact-us-map {
  height: 600px;
  width: 100%;
  @include media-breakpoint-down(sm) {
    height: 360px; } }


.link {
  color: $primary;
  &--td {
    text-decoration: underline;
    &:hover {
      text-decoration: none; } } }
.text-danger {
  color: $danger !important; }

.card-caption-link {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  min-height: 120px;
  box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
  border: none;
  transition: color 0.2s ease-in-out;
  &:hover,
  &:focus {
    color: $primary;
    outline: none; } }

.bg-transparent {
  background: transparent !important; }
.font-weight-normal {
  font-weight: normal; }
.align-items-center {
  align-items: center; }
.article-source {
  @include media-breakpoint-down(sm) {
    margin-top: -40px; }
  h1,h2,h3,h4,h5,h6 {
    @include f-title-forced;
    text-align: center;
    max-width: 410px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 42px;
    margin-top: 80px;
    @include media-breakpoint-down(sm) {
      @include f-subtitle; } }
  p,ul,ol {
    margin-bottom: 24px; }
  &-image {
    display: none;
    width: 100%;
    height: auto;
    max-height: 360px;
    object-fit: cover;
    margin-top: 25px;
    @include media-breakpoint-down(sm) {
      display: block; }
    @include media-breakpoint-down(xs) {
      margin-left: -20px;
      width: 100vw; } } }

.mobile-no-br {
  @include media-breakpoint-down(sm) {
    br {
      display: none; } } }

.side-map-search {
  position: fixed;
  width: 48.6vw;
  right: 0;
  top: 135px;
  bottom: 0;
  height: 100%; }

#side-map-search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.search-map {
  width: 100%;
  height: 720px;
  position: absolute;
  top: 0;
  left: 0;
  @include media-breakpoint-down(sm) {
    top: 0;
    width: 100vw;
    bottom: 0;
    max-height: 90vh;
    height: 90vh;
    left: 0;
    right: unset;
    position: absolute; } }


.row-map-view {
  .gm-style-iw {
    width: 340px;
    min-height: 150px;
    font-size: 16px; }
  .gm-style .gm-style-iw-c {
    padding: 0;
    border-radius: 0;
    background: transparent;
    font-size: 16px;
    box-shadow: none;
    font-family: $text-ff;
    .card {
      margin-top: 15px;
      .card__meta-item--icon {
        display: flex; }
      .hint--bottom {
        .hint__content {
          transform: translateX(-70%);
          &::before {
            left: 70%; } }
        &:hover {
          .hint__content {
            transform: translateX(-70%) translateY(8px); } } } } }
  .gm-style .gm-style-iw-d {
    overflow: unset !important; }
  .gm-style-iw-d + button {
    display: none !important; } }

.bg-white {
  background: white !important; }

.text-overflow-for-link {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.map-slider {
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 40px;
  display: none;
  opacity: 0;
  @include media-breakpoint-down(sm) {
    display: block; } }

.readonly {
  user-select: none;
  pointer-events: none; }
