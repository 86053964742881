.welcome-row {
  padding-bottom: 60px;
  min-height: 660px;
  @include media-breakpoint-down(sm) {
    min-height: 1px;
    padding-bottom: 46px;
    &::after {
      content: ' ';
      z-index: 1;
      display: block;
      position: absolute;
      left: 0;
      top: 76px;
      width: 100%;
      height: 320px;
      background: black;
      background: linear-gradient(180deg, #24374E 0%, rgba(36, 55, 78, 0.4) 70%);
      transform: rotate(180deg); } }
  &__content {
    padding-top: 80px;
    @include f-body-lg;
    @include media-breakpoint-down(md) {
      padding-top: 90px; }
    @include media-breakpoint-down(sm) {
      padding-top: 0; }
    &-in {
      @include media-breakpoint-down(sm) {
        text-align: center;
        margin-top: 360px; } } }
  &__title {
    z-index: 2;
    @include f-subheader;
    margin-bottom: 40px;
    @include media-breakpoint-down(md) {
      margin-bottom: 50px; }
    @include media-breakpoint-down(sm) {
      display: block;
      color: white;
      position: absolute;
      text-align: center;
      left: 10px;
      right: 10px;
      top: 160px;
      transform: translateY(-50%); } }
  &__image {
    height: 600px;
    position: absolute;
    right: 0;
    object-fit: cover;
    top: 140px;
    right: 0;
    width: 48.8vw;
    @include media-breakpoint-down(md) {
      height: 450px; }
    @include media-breakpoint-down(sm) {
      height: 320px;
      left: 0;
      top: 76px;
      width: 100%; } } }

