.big-statistic {
  @include media-breakpoint-down(sm) {
    padding-top: 56px; }
  @include media-breakpoint-down(xs) {
    padding-top: 39px; }
  @include media-breakpoint-only(md) {
    position: relative;
    bottom: 20px; }
  @include media-breakpoint-down(sm) {
    text-align: center; }
  &__title {
    font-size: 240px;
    font-weight: 700;
    font-family: $title-ff;
    line-height: 230px;
    color: $text-special-color;
    @include media-breakpoint-down(md) {
      font-size: 180px;
      line-height: 200px; }
    @include media-breakpoint-down(sm) {
      font-size: 80px;
      line-height: 97.52px; } }
  &__subtitle {
    @include f-subheader;
    margin: 0 0 0 240px;
    min-height: 113px;
    @include media-breakpoint-down(md) {
      margin-left: 180px; }
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      font-size: 22px;
      min-height: 65px;
      line-height: 30px; }
    @include media-breakpoint-down(xs) {
      margin-bottom: 21px; } } }
