.side-card {
  margin-top: 46px;
  position: relative;
  padding-left: 160px;
  height: 324px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @include media-breakpoint-down(md) {
    padding-left: 10px;
    margin-top: 5px; }
  @include media-breakpoint-down(sm) {
    padding-left: 30px;
    margin-top: 38px; }
  @include media-breakpoint-only(sm) {
    .section__title {
      @include f-subheader-forced; } }
  @include media-breakpoint-only(xs) {
    &:not(.side-card--full-width) {
      margin-top: -8px;
      position: relative;
      flex-direction: column-reverse;
      height: 260px;
      .section__title {
        font-size: 21px;
        line-height: 1.5;
        margin-bottom: 12px; }
      .section__descr {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0; } } }
  &::before {
    content: ' ';
    display: block;
    height: 100%;
    width: 100vw;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: linear-gradient(97.22deg, #EAF3F9 10.66%, #FFFFFF 98.43%);
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    border-top-left-radius: 160px;
    border-bottom-left-radius: 160px;
    @include media-breakpoint-down(md) {
      left: -12vw; }
    @include media-breakpoint-down(xs) {
      left: 0; } }
  &--full-width {
    padding-left: 0;
    height: auto;
    padding-top: 80px;
    padding-bottom: 100px;
    &::before {
      border-radius: 0; }
    @include media-breakpoint-down(md) {
      padding-top: 60px;
      padding-bottom: 25px;
      margin-top: 0; } }
  &__content {
    position: relative;
    z-index: 2;
    max-width: 290px;
    margin-top: 60px;
    @include media-breakpoint-down(xs) {
      max-width: 220px;
      left: 35px; } }
  &__media {
    position: relative;
    z-index: 2;
    margin-top: 40px;
    @include media-breakpoint-down(xs) {
      margin-top: 38px;
      left: 12px; } }
  &__center-media,
  &__center-title {
    position: relative;
    z-index: 2; }
  &__center-title {
    margin-bottom: 84px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 55px; } } }


