.instructor-slider {
  &--mt {
    margin-top: 101px;
    @include media-breakpoint-down(sm) {
      margin-top: 55px; }
    @include media-breakpoint-down(xs) {
      margin-top: 34px; } }
  &__title {
    @include f-title;
    margin-bottom: 62px;
    font-weight: bold;
    @include media-breakpoint-only(sm) {
      @include f-title-forced;
      margin-bottom: 53px; }
    @include media-breakpoint-down(xs) {
      margin-bottom: 35px; } } }
