.pagination {
  display: inline-block;
  @include media-breakpoint-down(sm) {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; }
  &--mb {
    margin-bottom: 40px; }
  &__nav {
    margin-bottom: 44px;
    font-weight: 500;
    font-family: $title-ff; }
  &__list {
    display: flex; }
  &__arr {
    border-bottom: 1px solid;
    &:hover {
      text-decoration: none; }
    &--left {
      margin-right: 36px;
      &::before {
        content: '< '; } }
    &--right {
      &::after {
        content: ' >'; } } }
  &__item {
    margin-right: 20px;
    color: $placeholder-text-color;
    transition: color 0.3s ease-in-out;
    &--active {
      color: $primary;
      &:hover {
        cursor: default;
        text-decoration: none; } } } }

