.help-block {
  padding-top: 40px;
  padding-bottom: 44px;
  padding-left: 20px;
  padding-right: 20px;
  background: linear-gradient(262.78deg, #EAF3F9 10.66%, #FFFFFF 98.43%);
  box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
  @include media-breakpoint-down(xs) {
    margin-right: -20px;
    margin-left: -20px; }
  &__title {
    margin-bottom: 24px;
    @include f-subtitle; }
  &__text {
    margin-bottom: 24px; }
  &__icon {
    display: block;
    width: 37.5px;
    height: 37.5px;
    margin-left: auto;
    margin-right: auto; } }

