.popup-menu {
  position: absolute;
  top: 140px;
  left: 100px;
  padding: 40px 40px 40px 40px;
  display: flex;
  border: 1px solid #C2E7FF;
  box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.12);
  background: white;
  width: 1030px;
  &--with-descr {
    width: 1130px;
    padding-bottom: 0; }
  @include media-breakpoint-down(sm) {
    width: auto;
    position: static;
    padding: 0;
    box-shadow: none;
    border: none;
    padding-left: 20px;
    display: none; }
  &__descr {
    max-width: 300px;
    margin-right: 50px;
    flex-shrink: 0;
    @include media-breakpoint-down(sm) {
      display: none; } }
  &__list {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    margin-right: -25px;
    margin-left: -25px;
    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-left: 0;
      flex-direction: column; }
    .expanded-link {
      margin-right: 25px;
      margin-left: 25px;
      margin-bottom: 50px; }
    &--single-line {
      .expanded-link {
        margin-bottom: 0; } } }
  &__list {
    .expanded-link {
      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 32px;
        .expanded-link__thumbnail {
          display: none; }
        .expanded-link__caption {
          padding-bottom: 0;
          font-family: $text-ff;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          &::after,
          &::before {
            display: none; } } } } } }
