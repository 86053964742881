.line-link-list {
  .line-link {
    margin-bottom: 140px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 61px; } } }

.line-link {
  color: $title-text-color;
  &:hover {
    text-decoration: none;
    color: $title-text-color; }
  &:focus {
    outline: none; }
  &:focus,
  &:hover {
    .line-link__caption {
      &::before {
        border-color: $primary; }
      &::after {
        opacity: 1;
        transform: none; } } }
  &__caption {
    margin: 10px 0 0 0;
    display: block;
    width: 100%;
    @include f-subheader-forced;
    font-weight: 600;
    &::before {
      content:  ' ';
      display: block;
      position: absolute;
      top: 85px;
      bottom: 75px;
      left: 0;
      right: 0;
      border: 2px solid #C6D5DD;
      transition: border-color 0.3s ease-in-out;
      z-index: 2;
      @include media-breakpoint-down(lg) {
        top: 45px;
        bottom: 25px; }
      @include media-breakpoint-down(sm) {
        top: 20px;
        bottom: 10px; } }
    @include media-breakpoint-down(lg) {
      @include f-title-forced; }
    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 26px; }
    &::after {
      content:  '>';
      display: block;
      position: absolute;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 10.5px;
      padding-top: 10.5px;
      left: 14%;
      bottom: 56px;
      background: white;
      font-family: $title-ff;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      border: 2px solid $primary;
      box-sizing: border-box;
      border-radius: 32px;
      z-index: 3;
      color: $primary;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      transform: translateX(-100px);
      opacity: 0;
      @include media-breakpoint-down(lg) {
        transform: translateX(-50px);
        bottom: 6px; }
      @include media-breakpoint-down(md) {
        transform: translateX(-10px); }
      @include media-breakpoint-down(sm) {
        bottom: -10px; } }
    &--left {
      margin-left: 12.6%;
      &::before {
        right: -65px;
        left: 20px;
        @include media-breakpoint-down(sm) {
          left: 10px; } }
      @include media-breakpoint-down(sm) {
        text-align: right; } }
    &--right {
      margin-right: 12.6%;
      text-align: right;
      &::before {
        left: -65px;
        right: 20px;
        @include media-breakpoint-down(sm) {
          right: 10px; } }
      @include media-breakpoint-down(sm) {
        text-align: left; }
      &::after {
        content: '>';
        transform: translateX(100px);
        left: unset;
        right: 14%;
        @include media-breakpoint-down(lg) {
          transform: translateX(50px); }
        @include media-breakpoint-down(md) {
          transform: translateX(10px); } } }
    &-wrap {
      display: flex;
      align-items: center; } }
  &__thumbnail {
    width: 100%;
    height: 360px;
    object-fit: cover;
    position: relative;
    z-index: 3;
    box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.12);
    @include media-breakpoint-down(lg) {
      height: 270px; }
    @include media-breakpoint-down(sm) {
      height: 132px; } } }


