.col-text {
  margin-top: 73px;
  margin-bottom: 72px;
  position: relative;
  @include media-breakpoint-down(sm) {
    margin-top: 55px; }
  &:not(:last-child) {
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      width: 2px;
      height: 100%;
      background: #C6D5DD;
      border-radius: 2px;
      right: 0;
      top: 0;
      @include media-breakpoint-down(sm) {
        height: 2px;
        left: 3vw;
        width: 88vw;
        bottom: -60px;
        right: unset;
        top: unset; } } }
  &__content {
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0; } } }
  &__title {
    @include f-title-forced;
    margin-bottom: 40px;
    @include media-breakpoint-down(sm) {
      @include f-subtitle; } } }


