.page-select {
  margin: 0 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start; }
  &__item {
    margin-bottom: 40px;
    &--prev {
      margin-right: 10px;
      align-self: flex-end; } } }


