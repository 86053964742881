.form-check {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  &__input {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    &:not(:checked) {
      + .form-check__caption--error {
        &::before {
          border-color: $danger; } } }
    &:checked {
      + .form-check__caption {
        &::before {
          background: url(../images/sprites/main.stack.svg#image-check-primary);
          background-position: center center;
          background-size: 13px;
          background-repeat: no-repeat; } } } }

  &__caption {
    margin-left: 40px;
    margin-bottom: 0;
    position: relative;
    margin-bottom: 16px;
    &::before {
      content: ' ';
      position: absolute;
      display: block;
      left: -40px;
      //top: 50%
      //transform: translateY(-50%)
      top: 1px;
      width: 20px;
      height: 20px;
      border: 2px solid $primary;
      box-sizing: border-box;
      border-radius: 4px; }
    &--sm {
      margin-left: 30px;
      &::before {
        left: -30px; } }
    a {
      color: $primary; } }
  &--radio {
    .form-check__caption {
      &::before {
        border-radius: 50%; } }
    .form-check__input {
      &:checked {
        + .form-check__caption {
          &::before {
            background: url(../images/sprites/main.stack.svg#image-circle-primary);
            background-position: center center;
            background-size: 12px;
            background-repeat: no-repeat; } } } } } }


.form-control {
  width: 100%;
  border-radius: 10px;
  padding: 5px 10px 6px;
  position: relative;
  transition: background 0.2s ease-in-out;
  margin-left: -10px;
  &__caption {
    display: block;
    margin-bottom: 16px;
    &-detail {
      font-size: 80%;
      color: $placeholder-text-color; } }
  &__select-content {
    opacity: 0;
    visibility: hidden;
    transform: translateY(105%);
    transition: all 0.2s ease-in-out;
    z-index: -1;
    display: flex;
    align-items: center;
    background: white;
    padding: 18px 20px;
    position: absolute;
    bottom: 0;
    left: 10px;
    border: 1px solid #C2E7FF;
    max-width: 100%;
    max-width: 360px;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
      width: 222px;
      bottom: 40px; } }
  &__select-input {
    display: block;
    padding: 0;
    border: none;
    background: transparent;
    width: 100%;
    border-bottom: 1px solid $text-light-color;
    flex-shrink: 0;
    margin-bottom: 10px;
    padding-bottom: 5px;
    &:focus {
      outline: none; } }
  &__select-item {
    min-width: 54px;
    text-align: center;
    padding: 0 12px;
    border-radius: 20px;
    background: $bg-gray;
    border: none;
    margin-right: 20px;
    margin-bottom: 15px;
    transition: background 0.2s ease-in-out;
    flex-shrink: 0;
    @include media-breakpoint-down(sm) {
      margin-bottom: 15px; }
    &:focus {
      outline: none; }
    &:hover,
    &:focus {
      background: $bg-component; }
    &--active {
      background: $bg-component;
      &:hover,
      &:focus {
        background: $bg-component; } } }
  &__select-wrap {
    width: 100%;
    display: block;
    margin-top: 15px; }
  &__select-btn {}
  &__select-caption {
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: $placeholder-text-color;
    font-weight: 500;
    font-family: $title-ff;
    margin: 0;
    flex-shrink: 0;
    min-width: 110px;
    @include media-breakpoint-down(sm) {
      width: 100%; } }
  &--select-active {
    .form-control__select-content {
      opacity: 1;
      visibility: visible;
      z-index: 3;
      transform: translateY(100%); } }
  &:not(.form-control--static) {
    &:hover {
      background:  #EAEAEA; }
    &:focus-within {
      background: #DFDFDF; } }
  &__check-list {
    display: flex;
    align-items: center;
    > span {
      margin-left: 12px;
      margin-right: 12px; } }
  &__feedback {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    margin: 0;
    padding-right: 10px;
    &--select {
      position: static;
      transform: none;
      margin-bottom: 10px; }
    a {
      color: $primary; }
    @include media-breakpoint-down(sm) {
      bottom: -2px;
      font-size: 14px;
      line-height: 1.2; } }
  &__icon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
    top: 47px;
    &--pass {
      background: url(../images/icons/eye.png);
      background-size: cover;
      &-active {
        background: url(../images/icons/eye-open.png);
        background-size: cover;
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: center center; } } }
  &__label {
    @include f-body;
    margin-bottom: 12px;
    display: block; }
  &__input {
    display: block;
    padding: 0;
    border: none;
    background: transparent;
    width: 100%;
    &--with-icon {
      margin-left: 30px; }
    &:focus {
      outline: none; }
    &:placeholder {
      color: $placeholder-text-color;
      opacity: 1; } } }
