.event-card {
  min-height: 300px;
  position: relative;
  margin-bottom: 60px;
  background: white;
  display: flex;
  @include media-breakpoint-down(xs) {
    flex-direction: column;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 50px; }
  &__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    max-width: 70%;
    @include media-breakpoint-down(md) {
      max-width: 75%; }
    @include media-breakpoint-down(xs) {
      padding: 40px 20px 36px; } }
  &__thumbnail {
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
    height: 238px;
    object-fit: cover;
    @include media-breakpoint-down(md) {
      width: 133px;
      height: 158px; }
    @include media-breakpoint-down(xs) {
      width: 100%;
      height: 240px;
      position: static; } }
  &__meta {
    position: absolute;
    @include small-caps;
    left: 40px;
    top: 0;
    transform: translateY(-50%);
    background: $text-special-color;
    padding: 6px 12px 5px;
    @include media-breakpoint-down(xs) {
      display: none; } }
  &__title {
    margin-bottom: 0;
    @include f-subtitle; }
  &__content {
    margin-top: 20px;
    margin-bottom: 20px;
    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0; } } } }
