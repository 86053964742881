.card-image-info {
  padding: 60px;
  position: relative;
  min-height: 400px;
  color: white;
  margin-bottom: 40px;
  overflow: hidden;
  // fixed safari border-radius bug on scale into overflow block
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  &--lg {
    min-height: 500px; }
  @include media-breakpoint-down(md) {
    min-height: 300px; }
  @include media-breakpoint-down(sm) {
    min-height: 260px;
    padding: 25px;
    margin-bottom: 20px; }
  &--adaptive-lg {
    @include media-breakpoint-down(md) {
      min-height: 375px; }
    @include media-breakpoint-down(sm) {
      min-height: 265px; } }
  &::before {
    content:  ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(180deg, #24374E 0%, transparent 70%);
    opacity: 1;
    transition: all 0.3s ease-in-out;
    @include media-breakpoint-down(sm) {
      content: none; } }
  &::after {
    content:  ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    background: linear-gradient(180deg, transparent 0%, rgba(36, 55, 78, 0.4) 70%);
    @include media-breakpoint-down(sm) {
      opacity: 1; } }
  &--has-shadow {
    box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.12); }
  @include media-breakpoint-up(md) {
    &:not(.card-image-info--always-vissible) {
      &:hover {
        &::before {
          opacity: 1; }
        &::after {
          opacity: 1; }
        .card-image-info__title {
          opacity: 0;
          visibility: hidden;
          transform: translateY(-15px); }
        .card-image-info__content {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px); }
        .card-image-info__bg {
           transform: scale(1.03); } } } }
  &__title {
    @include f-subheader;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
    @include media-breakpoint-down(sm) {
      font-size: 21px;
      line-height: 30px;
      margin-bottom: 10px; } }
  &__text {
    max-width: 220px;
    width: 100%;
    @include media-breakpoint-down(sm) {
      max-width: 280px;
      margin-bottom: 32px;
      height: 94px; } }
  &__in {
    z-index: 3;
    position: relative; }
  &__bg {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out; }
  &--top-left {
    border-top-left-radius: 60px;
    @include media-breakpoint-down(lg) {
      border-top-left-radius: 40px; }
    &::before {
      border-top-left-radius: 60px;
      @include media-breakpoint-down(lg) {
        border-top-left-radius: 40px; } }
    .card-image-info__bg {
      border-top-left-radius: 60px;
      @include media-breakpoint-down(lg) {
        border-top-left-radius: 40px; } } }
  &--top-right {
    border-top-right-radius: 60px;
    @include media-breakpoint-down(lg) {
      border-top-right-radius: 40px; }
    &::before {
      border-top-right-radius: 60px;
      @include media-breakpoint-down(lg) {
        border-top-right-radius: 40px; } }
    .card-image-info__bg {
      border-top-right-radius: 60px;
      @include media-breakpoint-down(lg) {
        border-top-right-radius: 40px; } } }
  &--bottom-left {
    border-bottom-left-radius: 60px;
    @include media-breakpoint-down(lg) {
      border-bottom-left-radius: 40px; }
    &::before {
      border-bottom-left-radius: 60px;
      @include media-breakpoint-down(lg) {
        border-bottom-left-radius: 40px; } }
    .card-image-info__bg {
      border-bottom-left-radius: 60px;
      @include media-breakpoint-down(lg) {
        border-bottom-left-radius: 40px; } } }
  &--bottom-right {
    border-bottom-right-radius: 60px;
    @include media-breakpoint-down(lg) {
      border-bottom-right-radius: 40px; }
    &::before {
      border-bottom-right-radius: 60px;
      @include media-breakpoint-down(lg) {
        border-bottom-right-radius: 40px; } }
    .card-image-info__bg {
      border-bottom-right-radius: 60px;
      @include media-breakpoint-down(lg) {
        border-bottom-right-radius: 40px; } } }
  @include media-breakpoint-down(sm) {
    border-radius: 0;
    &::before {
      border-radius: 0; }
    .card-image-info__bg {
      border-radius: 0; } }
  &__content {
    visibility: hidden;
    opacity: 0;
    transform: translateY(15px);
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include media-breakpoint-down(sm) {
      visibility: visible;
      opacity: 1;
      transform: none;
      position: static;
      flex-direction: column; } }
  &__more {
    &--static {
      position: absolute;
      left: 60px;
      bottom: 60px;
      z-index: 3;
      @include media-breakpoint-down(sm) {
        left: 25px;
        bottom: 25px; } } } }
