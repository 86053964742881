.accordion-wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 140px;
  z-index: 3;
  height: 0;
  @include media-breakpoint-down(sm) {
    display: none; } }

.accordion {
  width: 320px;
  margin-top: 60px;
  &__apply-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
    border: none;
    top: -50px;
    right: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0.4s;
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    background-image: url(../images/sprites/main.stack.svg#image-search) !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 16px !important;
    transition: background-color 0.3s ease-in-out;
    &:hover,
    &:focus {
      background-color: $primary-hover; } }
  &__item {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    color: $title-text-color;
    &--open {
      .accordion__title {
        &::after {
          transform: rotate(-180deg); } }
      .accordion__apply-btn {
        z-index: 2;
        opacity: 1;
        pointer-events: auto; } }
    &--link {
      .accordion__title {
        &::after {
          transform: rotate(-90deg); } }
      &:hover {
        color: $title-text-color;
        text-decoration: none; } } }
  &__content {
    background: white;
    display: none;
    position: relative;
    padding-left: 40px;
    padding-right: 15px;
    padding-bottom: 35px; }
  &__control {
    margin-top: 19px;
    position: relative;
    &::before {
      content: ' ';
      background: #EAEAEA;
      border-radius: 6px;
      width: 60px;
      height: 1px;
      position: absolute;
      top: -12px;
      left: 10px; } }
  &__header {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    background: white;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    cursor: pointer; }
  &__title {
    @include small-caps;
    margin: 0;
    &::after {
      content: ' ';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-left: 2px;
      background: url(../images/sprites/main.stack.svg#image-arr-expand-down);
      background-repeat: no-repeat;
      position: relative;
      top: 5px;
      transition: all 0.3s ease-in-out; } }
  &__control {} }
