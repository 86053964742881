@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@font-face {
  font-family: 'Roobert';
  src: url("../fonts/Roobert/Roobert-Regular.woff2") format("woff2"), url("../fonts/Roobert/Roobert-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roobert';
  src: url("../fonts/Roobert/Roobert-SemiBold.woff2") format("woff2"), url("../fonts/Roobert/Roobert-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roobert';
  src: url("../fonts/Roobert/Roobert-Bold.woff2") format("woff2"), url("../fonts/Roobert/Roobert-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

/*!
 * Bootstrap Reboot v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #4AA6FA;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0781f1;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*!
 * Bootstrap Grid v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 800px) {
  .container, .container-sm {
    max-width: 800px; } }

@media (min-width: 1199px) {
  .container, .container-sm, .container-md {
    max-width: 1040px; } }

@media (min-width: 1599px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1440px; } }

@media (min-width: 1900px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1441px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 800px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1199px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1601px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 800px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 1199px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1599px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1900px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 30%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  animation-name: fadeInUp; }

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  animation-name: fadeInRight; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes splide-loading {
  0% {
    transform: rotate(0); }
  to {
    transform: rotate(1turn); } }

.splide__container {
  position: relative;
  box-sizing: border-box; }

.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: -webkit-max-content;
  width: max-content;
  will-change: transform; }

.splide.is-active .splide__list {
  display: flex; }

.splide__pagination {
  display: inline-flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0; }

.splide__pagination li {
  list-style-type: none;
  display: inline-block;
  line-height: 1;
  margin: 0; }

.splide {
  visibility: hidden; }

.splide, .splide__slide {
  position: relative;
  outline: none; }

.splide__slide {
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  flex-shrink: 0; }

.splide__slide img {
  vertical-align: bottom; }

.splide__slider {
  position: relative; }

.splide__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #999;
  border-left-color: transparent;
  animation: splide-loading 1s linear infinite; }

.splide__track {
  position: relative;
  z-index: 0;
  overflow: hidden; }

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  -webkit-user-select: none;
  user-select: none; }

.splide--fade > .splide__track > .splide__list {
  display: block; }

.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0; }

.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1; }

.splide--rtl {
  direction: rtl; }

.splide--ttb > .splide__track > .splide__list {
  display: block; }

.splide--ttb > .splide__pagination {
  width: auto; }

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6,5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6,5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before, .flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9); }

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none; }

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  left: 0; }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  right: 0; }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px; }

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill .1s;
  transition: fill .1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input, .numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper input::-ms-clear {
  display: none; }

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%; }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%; }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: .5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 .5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 .5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto; }

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6; }

.flatpickr-day.today {
  border-color: #959ea9; }

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff; }

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7; }

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7; }

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px; }

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6,5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6,5px 0 0 #e6e6e6; }

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1); }

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7,5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7,5px 0 0 #569ff7; }

.flatpickr-day.hidden {
  visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eee; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

[data-hint] {
  position: relative;
  display: inline-block; }

[data-hint]:before, [data-hint]:after {
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms; }

[data-hint]:hover:before, [data-hint]:hover:after {
  visibility: visible;
  opacity: 1; }

[data-hint]:hover:before, [data-hint]:hover:after {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  transition-delay: 100ms; }

[data-hint]:before {
  content: '';
  position: absolute;
  background: transparent;
  border: 6px solid transparent;
  z-index: 1000001; }

[data-hint]:after {
  content: attr(data-hint);
  background: #383838;
  color: white;
  padding: 8px 10px;
  font-size: 12px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  line-height: 12px;
  white-space: nowrap; }

[data-hint='']:before, [data-hint='']:after {
  display: none !important; }

.hint--top-left:before {
  border-top-color: #383838; }

.hint--top-right:before {
  border-top-color: #383838; }

.hint--top:before {
  border-top-color: #383838; }

.hint--bottom-left:before {
  border-bottom-color: #383838; }

.hint--bottom-right:before {
  border-bottom-color: #383838; }

.hint--bottom:before {
  border-bottom-color: #383838; }

.hint--left:before {
  border-left-color: #383838; }

.hint--right:before {
  border-right-color: #383838; }

.hint--top:before {
  margin-bottom: -12px; }

.hint--top:before, .hint--top:after {
  bottom: 100%;
  left: 50%; }

.hint--top:after, .hint--top:before {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%); }

.hint--top:hover:after, .hint--top:hover:before, .hint--top:focus:after, .hint--top:focus:before {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px); }

.hint--bottom:before {
  margin-top: -12px; }

.hint--bottom:before, .hint--bottom:after {
  top: 100%;
  left: 50%; }

.hint--bottom:after, .hint--bottom:before {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%); }

.hint--bottom:hover:after, .hint--bottom:hover:before, .hint--bottom:focus:after, .hint--bottom:focus:before {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px); }

.hint--right:before {
  margin-left: -12px;
  margin-bottom: -6px; }

.hint--right:after {
  margin-bottom: -14px; }

.hint--right:before, .hint--right:after {
  left: 100%;
  bottom: 50%; }

.hint--right:hover:after, .hint--right:hover:before, .hint--right:focus:after, .hint--right:focus:before {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

.hint--left:before {
  margin-right: -12px;
  margin-bottom: -6px; }

.hint--left:after {
  margin-bottom: -14px; }

.hint--left:before, .hint--left:after {
  right: 100%;
  bottom: 50%; }

.hint--left:hover:after, .hint--left:hover:before, .hint--left:focus:after, .hint--left:focus:before {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

.hint--top-left:before {
  margin-bottom: -12px; }

.hint--top-left:before, .hint--top-left:after {
  bottom: 100%;
  left: 50%; }

.hint--top-left:after, .hint--top-left:before {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%); }

.hint--top-left:after {
  margin-left: 6px; }

.hint--top-left:hover:after, .hint--top-left:hover:before, .hint--top-left:focus:after, .hint--top-left:focus:before {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px); }

.hint--top-right:before {
  margin-bottom: -12px; }

.hint--top-right:before, .hint--top-right:after {
  bottom: 100%;
  left: 50%; }

.hint--top-right:after, .hint--top-right:before {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0); }

.hint--top-right:after {
  margin-left: -6px; }

.hint--top-right:hover:after, .hint--top-right:hover:before, .hint--top-right:focus:after, .hint--top-right:focus:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

.hint--bottom-left:before {
  margin-top: -12px; }

.hint--bottom-left:before, .hint--bottom-left:after {
  top: 100%;
  left: 50%; }

.hint--bottom-left:after, .hint--bottom-left:before {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%); }

.hint--bottom-left:after {
  margin-left: 6px; }

.hint--bottom-left:hover:after, .hint--bottom-left:hover:before, .hint--bottom-left:focus:after, .hint--bottom-left:focus:before {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px); }

.hint--bottom-right:before {
  margin-top: -12px; }

.hint--bottom-right:before, .hint--bottom-right:after {
  top: 100%;
  left: 50%; }

.hint--bottom-right:after, .hint--bottom-right:before {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0); }

.hint--bottom-right:after {
  margin-left: -6px; }

.hint--bottom-right:hover:after, .hint--bottom-right:hover:before, .hint--bottom-right:focus:after, .hint--bottom-right:focus:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

.hint--small:after, .hint--medium:after, .hint--large:after {
  white-space: normal;
  line-height: 1.4em; }

.hint--small:after {
  width: 80px; }

.hint--medium:after {
  width: 150px; }

.hint--large:after {
  width: 300px; }

[data-hint]:after {
  text-shadow: 0 -1px 0px #000;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3); }

.hint--error:after {
  background-color: #b34e4d;
  text-shadow: 0 -1px 0px #592726; }

.hint--error.hint--top-left:before {
  border-top-color: #b34e4d; }

.hint--error.hint--top-right:before {
  border-top-color: #b34e4d; }

.hint--error.hint--top:before {
  border-top-color: #b34e4d; }

.hint--error.hint--bottom-left:before {
  border-bottom-color: #b34e4d; }

.hint--error.hint--bottom-right:before {
  border-bottom-color: #b34e4d; }

.hint--error.hint--bottom:before {
  border-bottom-color: #b34e4d; }

.hint--error.hint--left:before {
  border-left-color: #b34e4d; }

.hint--error.hint--right:before {
  border-right-color: #b34e4d; }

.hint--warning:after {
  background-color: #c09854;
  text-shadow: 0 -1px 0px #6c5328; }

.hint--warning.hint--top-left:before {
  border-top-color: #c09854; }

.hint--warning.hint--top-right:before {
  border-top-color: #c09854; }

.hint--warning.hint--top:before {
  border-top-color: #c09854; }

.hint--warning.hint--bottom-left:before {
  border-bottom-color: #c09854; }

.hint--warning.hint--bottom-right:before {
  border-bottom-color: #c09854; }

.hint--warning.hint--bottom:before {
  border-bottom-color: #c09854; }

.hint--warning.hint--left:before {
  border-left-color: #c09854; }

.hint--warning.hint--right:before {
  border-right-color: #c09854; }

.hint--info:after {
  background-color: #3986ac;
  text-shadow: 0 -1px 0px #1a3c4d; }

.hint--info.hint--top-left:before {
  border-top-color: #3986ac; }

.hint--info.hint--top-right:before {
  border-top-color: #3986ac; }

.hint--info.hint--top:before {
  border-top-color: #3986ac; }

.hint--info.hint--bottom-left:before {
  border-bottom-color: #3986ac; }

.hint--info.hint--bottom-right:before {
  border-bottom-color: #3986ac; }

.hint--info.hint--bottom:before {
  border-bottom-color: #3986ac; }

.hint--info.hint--left:before {
  border-left-color: #3986ac; }

.hint--info.hint--right:before {
  border-right-color: #3986ac; }

.hint--success:after {
  background-color: #458746;
  text-shadow: 0 -1px 0px #1a321a; }

.hint--success.hint--top-left:before {
  border-top-color: #458746; }

.hint--success.hint--top-right:before {
  border-top-color: #458746; }

.hint--success.hint--top:before {
  border-top-color: #458746; }

.hint--success.hint--bottom-left:before {
  border-bottom-color: #458746; }

.hint--success.hint--bottom-right:before {
  border-bottom-color: #458746; }

.hint--success.hint--bottom:before {
  border-bottom-color: #458746; }

.hint--success.hint--left:before {
  border-left-color: #458746; }

.hint--success.hint--right:before {
  border-right-color: #458746; }

.hint--always:after, .hint--always:before {
  opacity: 1;
  visibility: visible; }

.hint--always.hint--top:after, .hint--always.hint--top:before {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px); }

.hint--always.hint--top-left:after, .hint--always.hint--top-left:before {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px); }

.hint--always.hint--top-right:after, .hint--always.hint--top-right:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

.hint--always.hint--bottom:after, .hint--always.hint--bottom:before {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px); }

.hint--always.hint--bottom-left:after, .hint--always.hint--bottom-left:before {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px); }

.hint--always.hint--bottom-right:after, .hint--always.hint--bottom-right:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

.hint--always.hint--left:after, .hint--always.hint--left:before {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

.hint--always.hint--right:after, .hint--always.hint--right:before {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

.hint--rounded:after {
  border-radius: 4px; }

.hint--no-animate:before, .hint--no-animate:after {
  -webkit-transition-duration: 0ms;
  -moz-transition-duration: 0ms;
  transition-duration: 0ms; }

.hint--bounce:before, .hint--bounce:after {
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.3s ease, visibility 0.3s ease, -moz-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24); }

.hint--html.hint:after {
  display: none;
  opacity: 0; }

.hint__content {
  background: #383838;
  color: white;
  padding: 8px 10px;
  font-size: 12px;
  line-height: 12px;
  pointer-events: none; }

.hint--hoverable .hint__content {
  pointer-events: auto; }

.hint--html {
  position: relative;
  display: inline-block; }

.hint--html:before, .hint--html:after {
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms; }

.hint--html:hover:before, .hint--html:hover:after {
  visibility: visible;
  opacity: 1; }

.hint--html:hover:before, .hint--html:hover:after {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  transition-delay: 100ms; }

.hint--html:before {
  content: '';
  position: absolute;
  background: transparent;
  border: 6px solid transparent;
  z-index: 1000001; }

.hint--html .hint__content {
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms; }

.hint--html:hover .hint__content, .hint--html:focus .hint__content {
  visibility: visible;
  opacity: 1; }

.hint--html:hover .hint__content {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  transition-delay: 100ms; }

.hint--html.hint--notrans:before, .hint--html.hint--notrans:after {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none; }

.hint--html.hint--notrans .hint__content {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none; }

.hint--top .hint__content {
  bottom: 100%;
  left: 50%; }

.hint--top:before, .hint--top .hint__content {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%); }

.hint--top .hint__content:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

.hint--top:hover .hint__content, .hint--top:focus .hint__content {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px); }

.hint--top-left .hint__content {
  bottom: 100%;
  left: 50%; }

.hint--top-left:before, .hint--top-left .hint__content {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%); }

.hint--top-left:before {
  margin-left: 6px; }

.hint--top-left .hint__content {
  margin-left: 12px; }

.hint--top-left .hint__content:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

.hint--top-left:hover .hint__content, .hint--top-left:focus .hint__content {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px); }

.hint--top-right .hint__content {
  bottom: 100%;
  left: 50%; }

.hint--top-right:before, .hint--top-right .hint__content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0); }

.hint--top-right:before {
  margin-left: -6px; }

.hint--top-right .hint__content {
  margin-left: -12px; }

.hint--top-right .hint__content:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

.hint--top-right:hover .hint__content, .hint--top-right:focus .hint__content {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

.hint--bottom .hint__content {
  top: 100%;
  left: 50%; }

.hint--bottom:before, .hint--bottom .hint__content {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%); }

.hint--bottom .hint__content:after {
  content: ' ';
  position: absolute;
  top: -12px;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

.hint--bottom:hover .hint__content, .hint--bottom:focus .hint__content {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px); }

.hint--bottom-left .hint__content {
  top: 100%;
  left: 50%; }

.hint--bottom-left:before, .hint--bottom-left .hint__content {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%); }

.hint--bottom-left:before {
  margin-left: 6px; }

.hint--bottom-left .hint__content {
  margin-left: 12px; }

.hint--bottom-left .hint__content:after {
  content: ' ';
  position: absolute;
  top: -12px;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

.hint--bottom-left:hover .hint__content, .hint--bottom-left:focus .hint__content {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px); }

.hint--bottom-right .hint__content {
  top: 100%;
  left: 50%; }

.hint--bottom-right:before, .hint--bottom-right .hint__content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0); }

.hint--bottom-right:before {
  margin-left: -6px; }

.hint--bottom-right .hint__content {
  margin-left: -12px; }

.hint--bottom-right .hint__content:after {
  content: ' ';
  position: absolute;
  top: -12px;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red; }

.hint--bottom-right:hover .hint__content, .hint--bottom-right:focus .hint__content {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

.hint--right:before {
  margin-left: -12px;
  margin-bottom: -6px; }

.hint--right .hint__content {
  margin-bottom: -14px; }

.hint--right .hint__content {
  left: 100%;
  bottom: 50%; }

.hint--right .hint__content:after {
  content: ' ';
  position: absolute;
  left: -12px;
  top: 0;
  height: 100%;
  width: 12px;
  opacity: 0;
  background-color: red; }

.hint--right:hover .hint__content, .hint--right:focus .hint__content {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

.hint--left:before {
  margin-right: -12px;
  margin-bottom: -6px; }

.hint--left .hint__content {
  margin-bottom: -14px; }

.hint--left .hint__content {
  right: 100%;
  bottom: 50%; }

.hint--left .hint__content:after {
  content: ' ';
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 12px;
  opacity: 0;
  background-color: red; }

.hint--left:hover .hint__content, .hint--left:focus .hint__content {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

.hint--error .hint__content {
  background-color: #b34e4d; }

.hint--error.hint--top-left:before {
  border-top-color: #b34e4d; }

.hint--error.hint--top-right:before {
  border-top-color: #b34e4d; }

.hint--error.hint--top:before {
  border-top-color: #b34e4d; }

.hint--error.hint--bottom-left:before {
  border-bottom-color: #b34e4d; }

.hint--error.hint--bottom-right:before {
  border-bottom-color: #b34e4d; }

.hint--error.hint--bottom:before {
  border-bottom-color: #b34e4d; }

.hint--error.hint--left:before {
  border-left-color: #b34e4d; }

.hint--error.hint--right:before {
  border-right-color: #b34e4d; }

.hint--warning .hint__content {
  background-color: #c09854; }

.hint--warning.hint--top-left:before {
  border-top-color: #c09854; }

.hint--warning.hint--top-right:before {
  border-top-color: #c09854; }

.hint--warning.hint--top:before {
  border-top-color: #c09854; }

.hint--warning.hint--bottom-left:before {
  border-bottom-color: #c09854; }

.hint--warning.hint--bottom-right:before {
  border-bottom-color: #c09854; }

.hint--warning.hint--bottom:before {
  border-bottom-color: #c09854; }

.hint--warning.hint--left:before {
  border-left-color: #c09854; }

.hint--warning.hint--right:before {
  border-right-color: #c09854; }

.hint--info .hint__content {
  background-color: #3986ac; }

.hint--info.hint--top-left:before {
  border-top-color: #3986ac; }

.hint--info.hint--top-right:before {
  border-top-color: #3986ac; }

.hint--info.hint--top:before {
  border-top-color: #3986ac; }

.hint--info.hint--bottom-left:before {
  border-bottom-color: #3986ac; }

.hint--info.hint--bottom-right:before {
  border-bottom-color: #3986ac; }

.hint--info.hint--bottom:before {
  border-bottom-color: #3986ac; }

.hint--info.hint--left:before {
  border-left-color: #3986ac; }

.hint--info.hint--right:before {
  border-right-color: #3986ac; }

.hint--success .hint__content {
  background-color: #458746; }

.hint--success.hint--top-left:before {
  border-top-color: #458746; }

.hint--success.hint--top-right:before {
  border-top-color: #458746; }

.hint--success.hint--top:before {
  border-top-color: #458746; }

.hint--success.hint--bottom-left:before {
  border-bottom-color: #458746; }

.hint--success.hint--bottom-right:before {
  border-bottom-color: #458746; }

.hint--success.hint--bottom:before {
  border-bottom-color: #458746; }

.hint--success.hint--left:before {
  border-left-color: #458746; }

.hint--success.hint--right:before {
  border-right-color: #458746; }

.hint--html.hint--hidden:hover:after, .hint--html.hint--hidden:hover:before {
  opacity: 0;
  visibility: hidden; }

.hint--html.hint--hidden:hover .hint__content {
  opacity: 0;
  visibility: hidden; }

.hint--always:after, .hint--always:before {
  opacity: 1;
  visibility: visible; }

.hint--always .hint__content {
  opacity: 1;
  visibility: visible; }

.hint--always.hint--top .hint__content {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px); }

.hint--always.hint--top-left .hint__content {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px); }

.hint--always.hint--top-right .hint__content {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px); }

.hint--always.hint--bottom .hint__content {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px); }

.hint--always.hint--bottom-left .hint__content {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px); }

.hint--always.hint--bottom-right .hint__content {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px); }

.hint--always.hint--left .hint__content {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px); }

.hint--always.hint--right .hint__content {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px); }

.hint__content {
  width: 220px;
  background: #4AA6FA;
  color: white;
  font-size: 12px;
  line-height: 18px;
  padding: 10px;
  box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.12);
  border-radius: 10px;
  z-index: 6 !important; }

.hint__title {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  line-height: 17px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 10px; }

.hint__text p {
  margin-bottom: 10px; }
  .hint__text p:last-child {
    margin-bottom: 0; }

.hint--right .hint__content {
  transform: none;
  top: 0;
  bottom: unset;
  text-transform: none; }
  .hint--right .hint__content::before {
    content: ' ';
    display: block;
    position: absolute;
    left: -10px;
    top: 13px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 10.4px 6px 0;
    border-color: transparent #4AA6FA transparent transparent; }

.hint--bottom .hint__content::before {
  content: ' ';
  display: block;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 10.4px 6px;
  border-color: transparent transparent #4AA6FA transparent; }

.hint--right:focus .hint__content,
.hint--right:hover .hint__content {
  transform: translateX(5px); }

.hint--right .hint__content {
  margin-bottom: 0; }

* {
  box-sizing: border-box; }

body {
  display: flex;
  flex-direction: column;
  background: #FFF;
  font-family: "Roobert", "Helvetica", Arial, sans-serif;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 24px; }
  body.body-other {
    background: #F7F7F9; }

a {
  text-decoration: none;
  color: #222222;
  transition: color .1s ease-in-out; }
  a:hover {
    text-decoration: underline;
    color: #4AA6FA; }

@media (max-width: 1198.98px) {
  .row:not(.no-gutters) {
    margin-right: -10px;
    margin-left: -10px; }
    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*="col-"] {
      padding-right: 10px;
      padding-left: 10px; } }

.wrapper {
  width: 100%;
  max-width: 850px;
  min-width: 320px;
  margin: 0 auto;
  padding: 0 40px; }

.f-header {
  font-size: 64px;
  line-height: 78px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700; }
  @media (max-width: 1598.98px) {
    .f-header {
      font-size: 48px;
      line-height: 58.51px; } }

.f-subheader {
  font-size: 40px;
  line-height: 56px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700; }
  @media (max-width: 1598.98px) {
    .f-subheader {
      font-size: 30px;
      line-height: 36.57px; } }

.f-title {
  font-size: 28px;
  line-height: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600; }
  @media (max-width: 1598.98px) {
    .f-title {
      font-size: 21px;
      line-height: 36px; } }

.f-subtitle {
  font-size: 22px;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600; }

.f-tabs {
  font-size: 20px;
  line-height: 28px; }

.f-body-lg {
  font-size: 20px;
  line-height: 28px; }

.f-body {
  font-size: 16px;
  line-height: 24px; }

.font-weight-medium {
  font-weight: 500; }

.font-weight-semibold {
  font-weight: 600; }

.font-weight-bold {
  font-weight: 700; }

.demo {
  background: #e5e5e5;
  padding: 20px 50px 50px; }
  .demo p:not([class*="demo"]) {
    margin-top: 0;
    margin-bottom: 10px; }
  .demo__title {
    color: #81929B;
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 30px;
    font-style: italic; }
  .demo button {
    margin-right: 20px;
    margin-bottom: 20px; }

.text-primary {
  color: #4AA6FA; }

.m-0 {
  margin: 0 !important; }

.p-0 {
  padding: 0 !important; }

.modal-instructor {
  width: 160px;
  height: 160px;
  object-fit: cover;
  margin-bottom: 40px;
  margin-top: 00px;
  border-radius: 20px; }

.instructor-main {
  margin-top: -298px;
  width: 100%;
  height: auto;
  filter: drop-shadow(-10px 10px 40px rgba(33, 78, 119, 0.12)); }
  @media (max-width: 1598.98px) {
    .instructor-main {
      margin-top: -161px; } }
  @media (max-width: 1198.98px) {
    .instructor-main {
      margin-bottom: 68px;
      margin-top: -200px; } }
  @media (max-width: 799.98px) {
    .instructor-main {
      margin-bottom: 46px;
      margin-top: -144px;
      height: 190px;
      object-fit: cover; } }

.thumbnail-preview-list {
  margin-bottom: 96px; }
  @media (max-width: 1598.98px) {
    .thumbnail-preview-list {
      margin-bottom: 60px; } }
  @media (max-width: 1198.98px) {
    .thumbnail-preview-list > [class*="col"]:nth-child(4) ~ * {
      display: none; } }
  @media (max-width: 799.98px) {
    .thumbnail-preview-list {
      margin-bottom: 0; } }

.our-facilities-list {
  margin-bottom: 39px; }
  @media (max-width: 1899.98px) {
    .our-facilities-list {
      margin-bottom: 17px; } }
  @media (max-width: 1598.98px) {
    .our-facilities-list {
      margin-bottom: 20px; } }

.comment-list {
  margin-bottom: 136px; }
  .comment-list__btn {
    margin-top: 18px; }
  @media (max-width: 1598.98px) {
    .comment-list {
      margin-bottom: 100px; } }
  @media (max-width: 799.98px) {
    .comment-list {
      margin-bottom: 40px; } }

.star {
  background: url(../images/sprites/main.stack.svg#image-star-warning);
  background-repeat: no-repeat; }

.d-block {
  display: block; }

.d-none {
  display: none; }

@media (min-width: 1199px) {
  .d-md-none {
    display: none; } }

@media (min-width: 1199px) {
  .d-md-block {
    display: block; } }

@media (min-width: 1199px) {
  .d-md-inline-block {
    display: inline-block; } }

@media (min-width: 1599px) {
  .d-lg-block {
    display: block; } }

@media (min-width: 1599px) {
  .d-lg-none {
    display: none; } }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-start {
  justify-content: flex-start; }

.d-flex {
  display: flex; }

.overflow-hidden {
  overflow: hidden; }

.big-statistic-slider__control {
  display: none;
  height: 6px;
  background: #EAEAEA;
  position: absolute;
  left: 0;
  left: 10px;
  right: 10px;
  top: 12px; }
  @media (max-width: 1198.98px) {
    .big-statistic-slider__control {
      display: block; } }
  @media (max-width: 799.98px) {
    .big-statistic-slider__control {
      top: -12px; } }
  .big-statistic-slider__control-in {
    display: block;
    height: 12px;
    background: #C2DEF0;
    width: 10%;
    transition: width 0.3s ease-in-out;
    position: absolute;
    top: -3px;
    left: 0; }

.text-placeholder-color {
  color: #81929B; }

.text-color {
  color: #305C7B; }

.text-special-color {
  color: #C2DEF0; }

@media (max-width: 1198.98px) {
  .text-center-mobile {
    text-align: center; } }

.p-static {
  position: static; }

.p-relative {
  position: relative; }

.img-thumbnail {
  width: 100%;
  height: auto; }

.custom-side-image {
  position: absolute;
  left: 50%;
  width: 33.9vw;
  transform: translateX(16.1vw);
  height: auto;
  max-height: 650px;
  object-fit: cover; }
  @media (max-width: 1598.98px) {
    .custom-side-image {
      position: static;
      transform: none; } }
  @media (max-width: 1198.98px) {
    .custom-side-image {
      width: 75vw;
      height: auto;
      margin-left: auto;
      display: block;
      margin-bottom: 60px;
      position: relative;
      transform: none;
      left: 20px; } }

@media (max-width: 1198.98px) {
  .row-sm-reverse {
    flex-direction: column-reverse; } }

.contact-us-map {
  height: 600px;
  width: 100%; }
  @media (max-width: 1198.98px) {
    .contact-us-map {
      height: 360px; } }

.link {
  color: #4AA6FA; }
  .link--td {
    text-decoration: underline; }
    .link--td:hover {
      text-decoration: none; }

.text-danger {
  color: #E12626 !important; }

.card-caption-link {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  min-height: 120px;
  box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
  border: none;
  transition: color 0.2s ease-in-out; }
  .card-caption-link:hover, .card-caption-link:focus {
    color: #4AA6FA;
    outline: none; }

.bg-transparent {
  background: transparent !important; }

.font-weight-normal {
  font-weight: normal; }

.align-items-center {
  align-items: center; }

@media (max-width: 1198.98px) {
  .article-source {
    margin-top: -40px; } }

.article-source h1, .article-source h2, .article-source h3, .article-source h4, .article-source h5, .article-source h6 {
  font-size: 28px;
  line-height: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-align: center;
  max-width: 410px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 42px;
  margin-top: 80px; }
  @media (max-width: 1198.98px) {
    .article-source h1, .article-source h2, .article-source h3, .article-source h4, .article-source h5, .article-source h6 {
      font-size: 22px;
      line-height: 30px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600; } }

.article-source p, .article-source ul, .article-source ol {
  margin-bottom: 24px; }

.article-source-image {
  display: none;
  width: 100%;
  height: auto;
  max-height: 360px;
  object-fit: cover;
  margin-top: 25px; }
  @media (max-width: 1198.98px) {
    .article-source-image {
      display: block; } }
  @media (max-width: 799.98px) {
    .article-source-image {
      margin-left: -20px;
      width: 100vw; } }

@media (max-width: 1198.98px) {
  .mobile-no-br br {
    display: none; } }

.side-map-search {
  position: fixed;
  width: 48.6vw;
  right: 0;
  top: 135px;
  bottom: 0;
  height: 100%; }

#side-map-search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.search-map {
  width: 100%;
  height: 720px;
  position: absolute;
  top: 0;
  left: 0; }
  @media (max-width: 1198.98px) {
    .search-map {
      top: 0;
      width: 100vw;
      bottom: 0;
      max-height: 90vh;
      height: 90vh;
      left: 0;
      right: unset;
      position: absolute; } }

.row-map-view .gm-style-iw {
  width: 340px;
  min-height: 150px;
  font-size: 16px; }

.row-map-view .gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
  background: transparent;
  font-size: 16px;
  box-shadow: none;
  font-family: "Roobert", "Helvetica", Arial, sans-serif; }
  .row-map-view .gm-style .gm-style-iw-c .card {
    margin-top: 15px; }
    .row-map-view .gm-style .gm-style-iw-c .card .card__meta-item--icon {
      display: flex; }
    .row-map-view .gm-style .gm-style-iw-c .card .hint--bottom .hint__content {
      transform: translateX(-70%); }
      .row-map-view .gm-style .gm-style-iw-c .card .hint--bottom .hint__content::before {
        left: 70%; }
    .row-map-view .gm-style .gm-style-iw-c .card .hint--bottom:hover .hint__content {
      transform: translateX(-70%) translateY(8px); }

.row-map-view .gm-style .gm-style-iw-d {
  overflow: unset !important; }

.row-map-view .gm-style-iw-d + button {
  display: none !important; }

.bg-white {
  background: white !important; }

.text-overflow-for-link {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.map-slider {
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 40px;
  display: none;
  opacity: 0; }
  @media (max-width: 1198.98px) {
    .map-slider {
      display: block; } }

.readonly {
  user-select: none;
  pointer-events: none; }

.header {
  padding-left: 100px;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10;
  background: white;
  transition: background 0.3s ease-in-out, box-shadow 0.2s ease-in-out; }
  @media (max-width: 1198.98px) {
    .header {
      padding-left: 0; } }
  .header--top {
    background: transparent; }
    .header--top .header__link:not(.header__link--toggle-active):not(:hover) {
      color: white; }
      @media (max-width: 1198.98px) {
        .header--top .header__link:not(.header__link--toggle-active):not(:hover) {
          color: #222222; } }
      .header--top .header__link:not(.header__link--toggle-active):not(:hover)::before {
        background: url(../images/sprites/main.stack.svg#image-expand-down-white); }
        @media (max-width: 1198.98px) {
          .header--top .header__link:not(.header__link--toggle-active):not(:hover)::before {
            background: url(../images/sprites/main.stack.svg#image-expand-down); } }
  .header--shadow {
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05); }
  @media (min-width: 1199px) {
    .header--thin .header__in {
      height: 90px; }
    .header--thin .popup-menu {
      top: 90px; } }
  .header__in {
    height: 140px;
    transition: height 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 1198.98px) {
      .header__in {
        height: 76px;
        background: white;
        padding-left: 20px;
        padding-right: 20px; } }
  .header__logo-link {
    margin-right: 83px;
    position: relative;
    z-index: 3; }
    @media (max-width: 1899.98px) {
      .header__logo-link {
        margin-right: 60px; } }
    @media (max-width: 1598.98px) {
      .header__logo-link {
        margin-right: 26px; } }
  @media (max-width: 1198.98px) {
    .header__menu-in {
      position: absolute;
      padding: 96px 20px 20px 20px;
      background: white;
      flex-direction: column;
      align-items: flex-start;
      left: 0;
      width: 100%;
      bottom: 76px;
      transform: none;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
      display: flex;
      z-index: 3;
      max-height: 100vh;
      overflow-y: auto;
      box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05); } }
  .header__burger {
    display: none;
    width: 36px;
    height: 36px;
    position: relative;
    padding: 0;
    border: none;
    background: transparent;
    z-index: 4; }
    .header__burger:focus {
      outline: none; }
    .header__burger::before, .header__burger::after {
      content: ' ';
      position: absolute;
      display: block;
      left: 50%;
      height: 2px;
      width: 20px;
      transform: translateX(-50%);
      transition: all 0.3s ease-in-out;
      background: #305C7B; }
    .header__burger::before {
      top: 13px; }
    .header__burger::after {
      bottom: 13px; }
    .header__burger--active::before {
      top: 17px;
      transform: translateX(-50%) rotate(45deg); }
    .header__burger--active::after {
      bottom: 17px;
      transform: translateX(-50%) rotate(-45deg); }
    @media (max-width: 1198.98px) {
      .header__burger--active ~ .header__menu-in {
        transform: translateY(100%);
        visibility: visible; } }
    @media (max-width: 1198.98px) {
      .header__burger {
        display: block; } }
  .header__logo {
    width: 120px;
    height: auto; }
  .header__submenu {
    transform: translateX(-20%);
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: -1; }
    @media (max-width: 1198.98px) {
      .header__submenu {
        transform: none;
        opacity: 1;
        z-index: 1;
        visibility: visible;
        display: none; } }
  .header__link {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase; }
    .header__link:not(:last-child) {
      margin-right: 13px; }
    @media (max-width: 1198.98px) {
      .header__link {
        font-size: 20px;
        line-height: 24.38px;
        margin-right: 0;
        margin-bottom: 32px;
        display: inline-block; } }
    .header__link:hover, .header__link:focus {
      text-decoration: none; }
    .header__link--toggle {
      padding-right: 24px;
      position: relative; }
      .header__link--toggle::before, .header__link--toggle::after {
        content: ' ';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        right: 3px;
        top: 55%;
        transform: translateY(-50%);
        transition: opacity .1s ease-in-out, transform 0.2s ease-in-out; }
      .header__link--toggle::after {
        opacity: 0; }
      .header__link--toggle::before {
        background: url(../images/sprites/main.stack.svg#image-expand-down); }
      .header__link--toggle::after {
        background: url(../images/sprites/main.stack.svg#image-expand-down-primary); }
      .header__link--toggle:hover::after {
        opacity: 1; }
      .header__link--toggle:hover::before {
        opacity: 0; }
      .header__link--toggle-active {
        color: #4AA6FA; }
        .header__link--toggle-active::before, .header__link--toggle-active::after {
          transform: translateY(-50%) rotate(180deg); }
        .header__link--toggle-active::before {
          opacity: 0; }
        .header__link--toggle-active::after {
          opacity: 1; }
        .header__link--toggle-active + .header__submenu {
          transform: none;
          opacity: 1;
          visibility: visible;
          z-index: 3; }
          @media (max-width: 1198.98px) {
            .header__link--toggle-active + .header__submenu {
              animation: fadeIn 0.5s;
              display: block; } }
  .header__menu {
    display: flex;
    align-items: center;
    flex-shrink: 0; }
    @media (max-width: 1198.98px) {
      .header__menu {
        width: 100%;
        justify-content: space-between;
        background: white;
        z-index: 3; } }
  .header__side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #4AA6FA;
    border-right: none;
    box-sizing: border-box;
    border-radius: 60px 0px 0px 60px;
    width: auto;
    position: relative; }
    @media (max-width: 1198.98px) {
      .header__side {
        display: none; } }
    .header__side::after {
      content: ' ';
      height: 32px;
      width: 1px;
      background: #4AA6FA;
      position: absolute;
      right: 114px;
      top: 50%;
      transform: translateY(-50%); }
    .header__side-search-link {
      display: flex;
      position: relative;
      height: 60px;
      padding-left: 62px;
      padding-right: 29px;
      align-items: center;
      border-radius: 60px 0px 0px 60px;
      margin-left: -1px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      text-transform: uppercase;
      color: #4AA6FA;
      transition: color 0.3s ease-in-out, background 0.3s ease-in-out; }
      @media (max-width: 1598.98px) {
        .header__side-search-link {
          max-width: 310px;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap; } }
      .header__side-search-link-caption {
        font-size: 13px; }
        @media (max-width: 1598.98px) {
          .header__side-search-link-caption {
            vertical-align: middle;
            line-height: 60px; } }
      .header__side-search-link::before, .header__side-search-link::after {
        content: ' ';
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        transition: opacity 0.3s ease-in-out;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 32px;
        top: 53%;
        transform: translateY(-50%); }
      .header__side-search-link::before {
        opacity: 1;
        background: url(../images/sprites/main.stack.svg#image-side-search-primary);
        background-size: contain;
        background-repeat: no-repeat; }
      .header__side-search-link::after {
        opacity: 0;
        background: url(../images/sprites/main.stack.svg#image-side-search-white);
        background-size: contain;
        background-repeat: no-repeat; }
      .header__side-search-link:hover {
        color: white;
        background: #4AA6FA;
        text-decoration: none; }
        .header__side-search-link:hover::before {
          opacity: 0; }
        .header__side-search-link:hover::after {
          opacity: 1; }
    .header__side-menu {
      display: flex;
      flex-direction: column;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      background: white;
      padding: 40px 30px;
      border: 2px solid #C2E7FF;
      box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
      position: absolute;
      bottom: -40px;
      right: 30px;
      transform: translateY(100%); }
      .header__side-menu-link:not(:last-of-type) {
        margin-bottom: 32px; }
      .header__side-menu-toggle {
        display: block;
        flex-grow: 1;
        border: none;
        height: 60px;
        border: none;
        background: transparent;
        transition: background 0.3s ease-in-out;
        position: relative;
        margin-left: -1px;
        width: 115px; }
        .header__side-menu-toggle:focus {
          outline: none; }
        .header__side-menu-toggle:hover, .header__side-menu-toggle--active {
          background: #4AA6FA; }
          .header__side-menu-toggle:hover .header__side-menu-toggle-icon::before,
          .header__side-menu-toggle:hover .header__side-menu-toggle-arr::before, .header__side-menu-toggle--active .header__side-menu-toggle-icon::before,
          .header__side-menu-toggle--active .header__side-menu-toggle-arr::before {
            opacity: 0; }
          .header__side-menu-toggle:hover .header__side-menu-toggle-icon::after,
          .header__side-menu-toggle:hover .header__side-menu-toggle-arr::after, .header__side-menu-toggle--active .header__side-menu-toggle-icon::after,
          .header__side-menu-toggle--active .header__side-menu-toggle-arr::after {
            opacity: 1; }
        .header__side-menu-toggle--active + .header__side-menu {
          bottom: -20px;
          visibility: visible;
          opacity: 1; }
        .header__side-menu-toggle--active .header__side-menu-toggle-arr {
          transform: translateY(-50%) rotate(180deg); }
        .header__side-menu-toggle-icon {
          width: 32px;
          height: 32px;
          display: block;
          position: absolute;
          top: 50%;
          left: 32px;
          transform: translateY(-50%); }
          .header__side-menu-toggle-icon::before, .header__side-menu-toggle-icon::after {
            content: ' ';
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            transition: opacity 0.3s ease-in-out;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%; }
          .header__side-menu-toggle-icon::before {
            opacity: 1;
            background: url(../images/sprites/main.stack.svg#image-side-user-primary);
            background-size: contain;
            background-repeat: no-repeat; }
          .header__side-menu-toggle-icon::after {
            opacity: 0;
            background: url(../images/sprites/main.stack.svg#image-side-user-white);
            background-size: contain;
            background-repeat: no-repeat; }
        .header__side-menu-toggle-arr {
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          right: 32px;
          top: 50%;
          transform: translateY(-50%); }
          .header__side-menu-toggle-arr::before, .header__side-menu-toggle-arr::after {
            content: ' ';
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            transition: opacity 0.3s ease-in-out;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%; }
          .header__side-menu-toggle-arr::before {
            opacity: 1;
            background: url(../images/sprites/main.stack.svg#image-expand-down-primary);
            background-size: contain;
            background-repeat: no-repeat; }
          .header__side-menu-toggle-arr::after {
            opacity: 0;
            background: url(../images/sprites/main.stack.svg#image-expand-down-white);
            background-size: contain;
            background-repeat: no-repeat; }

@-moz-document url-prefix() {
  .header__side-search-link {
    font-size: 15px; } }

.main {
  flex-grow: 1; }

.btn {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  padding: 13px 33px 9px;
  text-transform: uppercase;
  border-radius: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border: none;
  transition: all 0.2s ease-in-out;
  border: 2px solid; }
  .btn:hover, .btn:focus {
    text-decoration: none;
    cursor: pointer; }
  .btn:focus {
    outline: none; }
  .btn--default-font {
    font-family: "Roobert", "Helvetica", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-variant: normal;
    text-transform: none; }
  .btn--back {
    padding-left: 60px;
    position: relative;
    text-transform: none; }
    .btn--back::before, .btn--back::after {
      content: ' ';
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      left: 20px;
      transform: translateY(-50%);
      top: 50%;
      transition: opacity 0.3s ease-in-out; }
    .btn--back::before {
      background: url(../images/sprites/main.stack.svg#image-arr-back);
      background-repeat: no-repeat;
      opacity: 1; }
    .btn--back::after {
      background: url(../images/sprites/main.stack.svg#image-arr-back-primary);
      background-repeat: no-repeat;
      opacity: 0; }
    .btn--back:hover::before {
      opacity: 0; }
    .btn--back:hover::after {
      opacity: 1; }
  .btn--fill-white {
    background: white;
    color: #393939;
    border-color: white;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05); }
  .btn--primary {
    border-color: #4AA6FA;
    background: #4AA6FA;
    color: white; }
    .btn--primary:hover, .btn--primary:focus {
      color: white;
      box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
      background: #72B5F3;
      border-color: #72B5F3; }
  .btn--outline-primary {
    border-color: #4AA6FA;
    color: #4AA6FA;
    background: transparent; }
    .btn--outline-primary:hover, .btn--outline-primary:focus {
      color: #fff;
      background: #4AA6FA; }
  .btn--white {
    border-color: #fff;
    color: #fff;
    background: transparent; }
    .btn--white:hover, .btn--white:focus {
      color: #4AA6FA;
      background: #fff; }
  .btn--link {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    border: none;
    background: transparent; }
    .btn--link::after {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      bottom: 8px;
      width: 100%;
      height: 1px;
      transition: background 0.2s ease-in-out; }
  .btn--link-dark {
    color: #222222; }
    .btn--link-dark::after {
      background: #222222; }
    .btn--link-dark:hover, .btn--link-dark:focus {
      color: #4AA6FA; }
      .btn--link-dark:hover::after, .btn--link-dark:focus::after {
        background: #4AA6FA; }
  .btn--link-primary {
    color: #4AA6FA; }
    .btn--link-primary::after {
      background: #4AA6FA; }
    .btn--link-primary:hover, .btn--link-primary:focus {
      color: #72B5F3; }
      .btn--link-primary:hover::after, .btn--link-primary:focus::after {
        background: #72B5F3; }

.accordion-wrap {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 140px;
  z-index: 3;
  height: 0; }
  @media (max-width: 1198.98px) {
    .accordion-wrap {
      display: none; } }

.accordion {
  width: 320px;
  margin-top: 60px; }
  .accordion__apply-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
    border: none;
    top: -50px;
    right: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0.4s;
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    background-image: url(../images/sprites/main.stack.svg#image-search) !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 16px !important;
    transition: background-color 0.3s ease-in-out; }
    .accordion__apply-btn:hover, .accordion__apply-btn:focus {
      background-color: #72B5F3; }
  .accordion__item {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    color: #222222; }
    .accordion__item--open .accordion__title::after {
      transform: rotate(-180deg); }
    .accordion__item--open .accordion__apply-btn {
      z-index: 2;
      opacity: 1;
      pointer-events: auto; }
    .accordion__item--link .accordion__title::after {
      transform: rotate(-90deg); }
    .accordion__item--link:hover {
      color: #222222;
      text-decoration: none; }
  .accordion__content {
    background: white;
    display: none;
    position: relative;
    padding-left: 40px;
    padding-right: 15px;
    padding-bottom: 35px; }
  .accordion__control {
    margin-top: 19px;
    position: relative; }
    .accordion__control::before {
      content: ' ';
      background: #EAEAEA;
      border-radius: 6px;
      width: 60px;
      height: 1px;
      position: absolute;
      top: -12px;
      left: 10px; }
  .accordion__header {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    background: white;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    cursor: pointer; }
  .accordion__title {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase;
    margin: 0; }
    .accordion__title::after {
      content: ' ';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-left: 2px;
      background: url(../images/sprites/main.stack.svg#image-arr-expand-down);
      background-repeat: no-repeat;
      position: relative;
      top: 5px;
      transition: all 0.3s ease-in-out; }

.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 10px;
  user-select: none; }
  .not-found__title {
    margin-bottom: 100px; }
    @media (max-width: 1598.98px) {
      .not-found__title {
        margin-bottom: 40px; } }
  .not-found__image {
    width: 400px;
    height: auto;
    max-width: 80vw;
    margin-bottom: 80px;
    pointer-events: none; }
    @media (max-width: 1598.98px) {
      .not-found__image {
        margin-bottom: 40px; } }
  .not-found__text {
    text-align: center;
    margin-bottom: 24px; }
    .not-found__text a {
      color: #4AA6FA; }

.loader {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-in-out;
  visibility: visible;
  z-index: 100;
  background: linear-gradient(180deg, #FFFFFF 0%, #D3EBFB 100%);
  opacity: 1; }
  .loader__image {
    width: 400px;
    height: auto;
    max-width: 80vw;
    position: relative;
    transition: top 0.2s ease-in-out;
    opacity: 1;
    top: 0;
    animation: loaderImageUpDown 1s infinite  alternate; }

.is-loaded .loader {
  opacity: 0;
  visibility: hidden; }

@keyframes loaderImageUpDown {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-20px); } }

.hero {
  padding-top: 432px;
  padding-bottom: 253px;
  background: black;
  color: white;
  position: relative; }
  @media (max-width: 1899.98px) {
    .hero {
      padding-top: 313px;
      padding-bottom: 217px; } }
  @media (max-width: 1598.98px) {
    .hero {
      padding-top: 216px;
      padding-bottom: 151px; } }
  @media (max-width: 1198.98px) {
    .hero {
      background: transparent;
      margin-top: 76px;
      padding-top: 254px;
      padding-bottom: 15px; } }
  @media (max-width: 799.98px) {
    .hero {
      padding-top: 56px; } }
  .hero__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1; }
    @media (max-width: 1198.98px) {
      .hero__video {
        height: 700px; } }
    @media (max-width: 799.98px) {
      .hero__video {
        height: 210px; } }
  .hero::before {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(8, 16, 18, 0.4) 0%, rgba(24, 42, 48, 0.4) 16.58%, rgba(159, 159, 159, 0.076) 100%);
    z-index: 2; }
    @media (max-width: 1198.98px) {
      .hero::before {
        height: 700px; } }
    @media (max-width: 799.98px) {
      .hero::before {
        height: 210px; } }
  .hero--static-height {
    padding-top: 170px;
    padding-bottom: 50px;
    min-height: 100vh;
    display: flex;
    background: transparent; }
    @media (max-width: 1598.98px) {
      .hero--static-height {
        min-height: 1px;
        min-height: 525px; } }
    @media (max-width: 1198.98px) {
      .hero--static-height {
        margin-top: 76px;
        height: 210px;
        min-height: 210px;
        padding-top: 0;
        padding-bottom: 0; } }
    .hero--static-height::before {
      background: linear-gradient(180deg, #24374E 0%, rgba(36, 55, 78, 0.4) 70%);
      transform: rotate(180deg);
      height: 100%; }
  .hero--always-sm {
    padding-top: 0;
    padding-bottom: 0;
    height: 320px;
    min-height: 320px;
    margin-top: 140px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 1198.98px) {
      .hero--always-sm {
        margin-top: 75px; } }
    .hero--always-sm::before {
      content: none; }
  .hero--bg-gradient {
    background: linear-gradient(236.19deg, #305C7B 0%, #366384 0%, #BFD9EB 100%); }
  @media (max-width: 1198.98px) {
    .hero--has-mobile-descr {
      padding-top: 47px;
      padding-bottom: 47px;
      background: linear-gradient(123.81deg, #305C7B 0%, #366384 0%, #BFD9EB 100%);
      height: auto; }
      .hero--has-mobile-descr .hero__title {
        font-size: 30px;
        line-height: 36px; }
      .hero--has-mobile-descr .hero__bg-img {
        display: none; } }
  .hero__container-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2; }
  .hero__bg-img {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .hero--home {
    padding-top: 140px;
    padding-bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    @media (max-width: 1598.98px) {
      .hero--home {
        padding-bottom: 114px; } }
    @media (max-width: 1198.98px) {
      .hero--home {
        padding-top: 256px;
        padding-bottom: 0; } }
    @media (max-width: 799.98px) {
      .hero--home {
        padding-top: 56px; } }
    @media (min-width: 1199px) {
      .hero--home {
        min-height: 100vh; } }
    .hero--home::after {
      height: 700px;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      background: url(../images/bg/hero.webp);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      z-index: 1; }
      @media (max-width: 799.98px) {
        .hero--home::after {
          height: 210px; } }
  .hero__row {
    position: relative;
    z-index: 3;
    justify-content: space-between; }
    @media (max-width: 1198.98px) {
      .hero__row {
        justify-content: center; } }
    .hero__row--mb {
      margin-bottom: 120px; }
      @media (max-width: 1899.98px) {
        .hero__row--mb {
          margin-bottom: 97px; } }
      @media (max-width: 1598.98px) {
        .hero__row--mb {
          margin-bottom: 70px; } }
      @media (max-width: 799.98px) {
        .hero__row--mb {
          margin-bottom: 100px; } }
  .hero__title {
    font-size: 64px;
    line-height: 78px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 40px; }
    @media (max-width: 1598.98px) {
      .hero__title {
        font-size: 48px;
        line-height: 58.51px; } }
    @media (max-width: 1198.98px) {
      .hero__title {
        text-align: center; } }
    @media (max-width: 799.98px) {
      .hero__title {
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 20px; } }
    .hero__title--mobile-alone {
      margin-bottom: 40px; }
      .hero__title--mobile-alone-mb {
        margin-bottom: 65px; }
      @media (max-width: 1198.98px) {
        .hero__title--mobile-alone {
          font-size: 30px;
          line-height: 40px; } }
      @media (max-width: 1198.98px) {
        .hero__title--mobile-alone {
          margin-bottom: 0; } }
  .hero__subtitle {
    font-size: 28px;
    line-height: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 0; }
    @media (max-width: 1598.98px) {
      .hero__subtitle {
        font-size: 21px;
        line-height: 36px; } }
    @media (max-width: 1198.98px) {
      .hero__subtitle {
        text-align: center; } }
    @media (max-width: 799.98px) {
      .hero__subtitle {
        font-size: 16px;
        line-height: 19.5px; } }
  .hero__col-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-bottom: 10px; }
    @media (max-width: 1198.98px) {
      .hero__col-text {
        text-align: center;
        align-items: center;
        margin-top: 314px;
        color: #222222; } }
    @media (max-width: 799.98px) {
      .hero__col-text {
        margin-top: 125px; } }
  .hero__text {
    margin-bottom: 45px;
    font-size: 20px;
    line-height: 28px; }
    @media (min-width: 1199px) and (max-width: 1598.98px) {
      .hero__text {
        margin-top: 8px; } }
    @media (max-width: 1598.98px) {
      .hero__text {
        margin-bottom: 20px; } }
    @media (max-width: 1198.98px) {
      .hero__text {
        margin-bottom: 27px; } }
    .hero__text--desktop-only:last-child {
      margin-bottom: 0; }
    @media (max-width: 1198.98px) {
      .hero__text--desktop-only {
        display: none; } }
  .hero__link {
    text-decoration: none !important;
    position: relative;
    display: inline-block;
    border-bottom: 1px solid #FFFFFF;
    transition: all 0.15s ease-in-out;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase; }
    .hero__link:not(:hover) {
      color: white; }
      @media (max-width: 1198.98px) {
        .hero__link:not(:hover) {
          color: #222222; } }
    @media (max-width: 1198.98px) {
      .hero__link {
        border-bottom-color: #222222; } }
    .hero__link:hover {
      border-bottom-color: #4AA6FA; }

.hero-append {
  margin-top: -140px;
  position: relative;
  z-index: 5; }
  @media (max-width: 1598.98px) {
    .hero-append {
      margin-top: -95px; } }

.hero-append-text {
  display: none; }
  @media (max-width: 1198.98px) {
    .hero-append-text {
      display: block;
      margin-top: 40px; } }

.no-webp .hero--home::after {
  background: url(../images/bg/hero.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.gradient-hero {
  margin-top: 140px;
  background: linear-gradient(123.81deg, #305C7B 0%, #366384 0%, #BFD9EB 100%);
  padding-top: 80px;
  color: white;
  padding-bottom: 20px;
  margin-bottom: 100px; }
  @media (max-width: 1198.98px) {
    .gradient-hero {
      text-align: center;
      margin-top: 75px;
      padding-top: 30px;
      padding-bottom: 15px;
      margin-bottom: 0; } }
  @media (max-width: 1598.98px) {
    .gradient-hero--lg {
      padding-top: 70px;
      padding-bottom: 50px; } }
  .gradient-hero--center-mobile {
    display: flex;
    align-items: center;
    min-height: 200px; }
  .gradient-hero__content {
    width: 100%; }
  .gradient-hero--text {
    background: linear-gradient(236.19deg, #305C7B 0%, #366384 0%, #BFD9EB 100%);
    min-height: 420px;
    margin-bottom: 60px;
    padding-top: 100px; }
    @media (max-width: 1899.98px) {
      .gradient-hero--text {
        padding-top: 85px; } }
    @media (max-width: 1198.98px) {
      .gradient-hero--text {
        min-height: 1px;
        padding-top: 60px;
        padding-bottom: 40px; } }
    @media (max-width: 799.98px) {
      .gradient-hero--text {
        margin-bottom: 0; } }
  .gradient-hero--slider {
    padding-bottom: 205px; }
    @media (max-width: 1198.98px) {
      .gradient-hero--slider {
        padding-top: 42px;
        padding-bottom: 185px; } }
  .gradient-hero__title {
    font-size: 64px;
    line-height: 78px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 40px; }
    @media (max-width: 1598.98px) {
      .gradient-hero__title {
        font-size: 48px;
        line-height: 58.51px; } }
    .gradient-hero__title--mb-lg {
      margin-bottom: 63px; }
    @media (max-width: 1198.98px) {
      .gradient-hero__title {
        font-size: 28px;
        line-height: 36px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-weight: 700;
        margin-bottom: 20px; } }
    @media (max-width: 1198.98px) {
      .gradient-hero__title--mobile-mb-lg {
        margin-bottom: 45px; } }
    @media (max-width: 1198.98px) {
      .gradient-hero__title--mobile-mb-md {
        margin-bottom: 30px; } }
    @media (min-width: 1199px) {
      .gradient-hero__title--mt {
        margin-top: 100px; } }
    @media (min-width: 1599px) {
      .gradient-hero__title--mt {
        margin-top: 55px; } }
  .gradient-hero__text {
    color: #eaeaea;
    margin-bottom: 24px; }
    .gradient-hero__text--lg {
      font-size: 20px;
      line-height: 28px; }
  .gradient-hero__subtitle {
    font-size: 28px;
    line-height: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
    @media (max-width: 1598.98px) {
      .gradient-hero__subtitle {
        font-size: 21px;
        line-height: 36px; } }
    @media (max-width: 1198.98px) {
      .gradient-hero__subtitle {
        line-height: 30px; } }
    .gradient-hero__subtitle--mb {
      margin-bottom: 24px; }
    .gradient-hero__subtitle--sm {
      font-size: 22px;
      line-height: 30px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600; }
  .gradient-hero__image {
    margin-bottom: -120px;
    width: 100%;
    object-fit: cover; }
    @media (max-width: 1198.98px) {
      .gradient-hero__image {
        display: none; } }
    .gradient-hero__image--sm {
      margin-bottom: -78px; }
    .gradient-hero__image--lg {
      min-height: 458px; }

.gradient-hero-slider {
  margin-top: -233px;
  margin-bottom: 60px;
  position: relative; }
  @media (max-width: 1598.98px) {
    .gradient-hero-slider {
      margin-bottom: 80px; } }
  @media (max-width: 1198.98px) {
    .gradient-hero-slider {
      margin-bottom: 20px;
      margin-top: -95px; }
      .gradient-hero-slider .container {
        padding-right: 0;
        padding-left: 0; } }
  .gradient-hero-slider__item {
    width: 100%;
    height: auto; }
  .gradient-hero-slider__controls {
    position: absolute;
    right: 0;
    top: -94px;
    display: flex; }
    @media (max-width: 1198.98px) {
      .gradient-hero-slider__controls {
        top: -80px;
        right: 20px; } }
    .gradient-hero-slider__controls .btn:not(:last-child) {
      margin-right: 20px; }
  .gradient-hero-slider__btn {
    padding-right: 31px;
    padding-left: 31px; }
    .gradient-hero-slider__btn[disabled] {
      opacity: 0;
      pointer-events: none; }

.scroll-down-caption {
  position: relative;
  padding-bottom: 18px;
  color: white !important;
  text-decoration: none !important;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  transition: opacity 0.15s ease-in-out;
  z-index: 2; }
  .scroll-down-caption:hover {
    opacity: 0.5; }
  .scroll-down-caption::after {
    content: ' ';
    width: 22px;
    height: 22px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: scrollDownCaptionUpDown  1s infinite  alternate;
    background: url(../images/sprites/main.stack.svg#image-scroll-down-arr) no-repeat 0 0;
    background-size: contain;
    background-repeat: no-repeat; }

@keyframes scrollDownCaptionUpDown {
  0% {
    transform: translateX(-50%) translateY(0); }
  100% {
    transform: translateX(-50%) translateY(5px); } }

.card-image-info {
  padding: 60px;
  position: relative;
  min-height: 400px;
  color: white;
  margin-bottom: 40px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black); }
  .card-image-info--lg {
    min-height: 500px; }
  @media (max-width: 1598.98px) {
    .card-image-info {
      min-height: 300px; } }
  @media (max-width: 1198.98px) {
    .card-image-info {
      min-height: 260px;
      padding: 25px;
      margin-bottom: 20px; } }
  @media (max-width: 1598.98px) {
    .card-image-info--adaptive-lg {
      min-height: 375px; } }
  @media (max-width: 1198.98px) {
    .card-image-info--adaptive-lg {
      min-height: 265px; } }
  .card-image-info::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(180deg, #24374E 0%, transparent 70%);
    opacity: 1;
    transition: all 0.3s ease-in-out; }
    @media (max-width: 1198.98px) {
      .card-image-info::before {
        content: none; } }
  .card-image-info::after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    background: linear-gradient(180deg, transparent 0%, rgba(36, 55, 78, 0.4) 70%); }
    @media (max-width: 1198.98px) {
      .card-image-info::after {
        opacity: 1; } }
  .card-image-info--has-shadow {
    box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.12); }
  @media (min-width: 1199px) {
    .card-image-info:not(.card-image-info--always-vissible):hover::before {
      opacity: 1; }
    .card-image-info:not(.card-image-info--always-vissible):hover::after {
      opacity: 1; }
    .card-image-info:not(.card-image-info--always-vissible):hover .card-image-info__title {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-15px); }
    .card-image-info:not(.card-image-info--always-vissible):hover .card-image-info__content {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px); }
    .card-image-info:not(.card-image-info--always-vissible):hover .card-image-info__bg {
      transform: scale(1.03); } }
  .card-image-info__title {
    font-size: 40px;
    line-height: 56px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out; }
    @media (max-width: 1598.98px) {
      .card-image-info__title {
        font-size: 30px;
        line-height: 36.57px; } }
    @media (max-width: 1198.98px) {
      .card-image-info__title {
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 10px; } }
  .card-image-info__text {
    max-width: 220px;
    width: 100%; }
    @media (max-width: 1198.98px) {
      .card-image-info__text {
        max-width: 280px;
        margin-bottom: 32px;
        height: 94px; } }
  .card-image-info__in {
    z-index: 3;
    position: relative; }
  .card-image-info__bg {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out; }
  .card-image-info--top-left {
    border-top-left-radius: 60px; }
    @media (max-width: 1899.98px) {
      .card-image-info--top-left {
        border-top-left-radius: 40px; } }
    .card-image-info--top-left::before {
      border-top-left-radius: 60px; }
      @media (max-width: 1899.98px) {
        .card-image-info--top-left::before {
          border-top-left-radius: 40px; } }
    .card-image-info--top-left .card-image-info__bg {
      border-top-left-radius: 60px; }
      @media (max-width: 1899.98px) {
        .card-image-info--top-left .card-image-info__bg {
          border-top-left-radius: 40px; } }
  .card-image-info--top-right {
    border-top-right-radius: 60px; }
    @media (max-width: 1899.98px) {
      .card-image-info--top-right {
        border-top-right-radius: 40px; } }
    .card-image-info--top-right::before {
      border-top-right-radius: 60px; }
      @media (max-width: 1899.98px) {
        .card-image-info--top-right::before {
          border-top-right-radius: 40px; } }
    .card-image-info--top-right .card-image-info__bg {
      border-top-right-radius: 60px; }
      @media (max-width: 1899.98px) {
        .card-image-info--top-right .card-image-info__bg {
          border-top-right-radius: 40px; } }
  .card-image-info--bottom-left {
    border-bottom-left-radius: 60px; }
    @media (max-width: 1899.98px) {
      .card-image-info--bottom-left {
        border-bottom-left-radius: 40px; } }
    .card-image-info--bottom-left::before {
      border-bottom-left-radius: 60px; }
      @media (max-width: 1899.98px) {
        .card-image-info--bottom-left::before {
          border-bottom-left-radius: 40px; } }
    .card-image-info--bottom-left .card-image-info__bg {
      border-bottom-left-radius: 60px; }
      @media (max-width: 1899.98px) {
        .card-image-info--bottom-left .card-image-info__bg {
          border-bottom-left-radius: 40px; } }
  .card-image-info--bottom-right {
    border-bottom-right-radius: 60px; }
    @media (max-width: 1899.98px) {
      .card-image-info--bottom-right {
        border-bottom-right-radius: 40px; } }
    .card-image-info--bottom-right::before {
      border-bottom-right-radius: 60px; }
      @media (max-width: 1899.98px) {
        .card-image-info--bottom-right::before {
          border-bottom-right-radius: 40px; } }
    .card-image-info--bottom-right .card-image-info__bg {
      border-bottom-right-radius: 60px; }
      @media (max-width: 1899.98px) {
        .card-image-info--bottom-right .card-image-info__bg {
          border-bottom-right-radius: 40px; } }
  @media (max-width: 1198.98px) {
    .card-image-info {
      border-radius: 0; }
      .card-image-info::before {
        border-radius: 0; }
      .card-image-info .card-image-info__bg {
        border-radius: 0; } }
  .card-image-info__content {
    visibility: hidden;
    opacity: 0;
    transform: translateY(15px);
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media (max-width: 1198.98px) {
      .card-image-info__content {
        visibility: visible;
        opacity: 1;
        transform: none;
        position: static;
        flex-direction: column; } }
  .card-image-info__more--static {
    position: absolute;
    left: 60px;
    bottom: 60px;
    z-index: 3; }
    @media (max-width: 1198.98px) {
      .card-image-info__more--static {
        left: 25px;
        bottom: 25px; } }

.section {
  padding-top: 120px;
  padding-bottom: 120px; }
  .section--first {
    margin-top: 140px; }
    @media (max-width: 1198.98px) {
      .section--first {
        margin-top: 76px; } }
  .section-search {
    padding-top: 0;
    padding-bottom: 90px; }
  .section-benefists-package {
    padding-top: 40px;
    padding-bottom: 18px; }
    @media (max-width: 1198.98px) {
      .section-benefists-package {
        padding-top: 60px; } }
  .section-slider-shedule {
    padding-top: 80px;
    padding-bottom: 185px;
    background: #F7F7F9; }
    @media (max-width: 1198.98px) {
      .section-slider-shedule {
        padding-top: 50px;
        padding-bottom: 77px; } }
  @media (max-width: 1598.98px) {
    .section-article-list {
      padding-top: 102px; } }
  @media (max-width: 1198.98px) {
    .section-article-list {
      padding-top: 45px; } }
  .section-article-info {
    padding-top: 20px; }
    @media (max-width: 1598.98px) {
      .section-article-info {
        padding-top: 0;
        padding-bottom: 40px; } }
  .section-events {
    padding-top: 72px; }
    @media (max-width: 1598.98px) {
      .section-events {
        padding-bottom: 40px; } }
    @media (max-width: 799.98px) {
      .section-events {
        padding-top: 60px; } }
  .section-slider-facts {
    padding-top: 105px;
    padding-bottom: 240px; }
    @media (min-width: 1599px) {
      .section-slider-facts .section__title {
        margin-bottom: 60px; } }
    @media (max-width: 1198.98px) {
      .section-slider-facts {
        padding-top: 70px;
        padding-bottom: 60px; } }
  .section-welcome-row-side {
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 918px; }
    @media (max-width: 1198.98px) {
      .section-welcome-row-side {
        min-height: 1px;
        padding-top: 40px;
        padding-bottom: 62px; } }
  .section-welcome-row {
    padding-top: 0;
    padding-bottom: 0; }
  .section-image-info {
    padding-top: 80px;
    padding-bottom: 40px; }
    @media (max-width: 1198.98px) {
      .section-image-info {
        padding-top: 40px;
        margin-bottom: 0; } }
  .section-info {
    padding-top: 84px;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 40px; }
    .section-info .section__title {
      margin-bottom: 40px; }
      @media (max-width: 1899.98px) {
        .section-info .section__title {
          margin-bottom: 50px; } }
    @media (max-width: 1899.98px) {
      .section-info {
        padding-bottom: 20px; } }
    @media (max-width: 1598.98px) {
      .section-info {
        padding-top: 75px; } }
  .section-info-card {
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 1598.98px) {
      .section-info-card {
        padding-bottom: 20px; } }
  .section-line-links {
    padding-top: 80px;
    padding-bottom: 1px; }
    @media (max-width: 1598.98px) {
      .section-line-links {
        padding-top: 10px;
        padding-bottom: 10px; } }
  .section-instructor-detail {
    padding-top: 80px;
    padding-bottom: 80px;
    background: #F7F7F9;
    font-size: 20px;
    line-height: 28px; }
    .section-instructor-detail p {
      margin-bottom: 20px; }
      .section-instructor-detail p:last-of-type {
        margin-bottom: 40px; }
    @media (max-width: 1198.98px) {
      .section-instructor-detail {
        padding-top: 40px;
        padding-bottom: 40px; }
        .section-instructor-detail > .container > .row {
          flex-direction: column-reverse; }
        .section-instructor-detail .section__btns {
          margin-bottom: 40px; } }
  .section-core-values {
    padding-top: 80px;
    padding-bottom: 60px;
    background: #F7F7F9; }
    @media (max-width: 1198.98px) {
      .section-core-values {
        padding-top: 40px;
        padding-bottom: 0; } }
    .section-core-values .section__title {
      margin-bottom: 13px; }
  .section-course-detail {
    padding-top: 80px;
    min-height: 316px;
    padding-bottom: 80px;
    font-size: 20px;
    line-height: 28px; }
    @media (max-width: 1198.98px) {
      .section-course-detail {
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px; } }
    .section-course-detail p {
      margin-bottom: 28px; }
      .section-course-detail p:last-child {
        margin-bottom: 0; }
    .section-course-detail .section__title {
      margin-bottom: 40px; }
    .section-course-detail .special-date {
      position: absolute;
      right: 20px;
      top: 0; }
      @media (max-width: 1198.98px) {
        .section-course-detail .special-date {
          position: static;
          margin-bottom: 40px; } }
  .section-with-side-image {
    padding-bottom: 80px;
    padding-bottom: 256px;
    font-size: 20px;
    line-height: 28px; }
    @media (max-width: 1899.98px) {
      .section-with-side-image {
        padding-bottom: 150px; } }
    @media (max-width: 1198.98px) {
      .section-with-side-image {
        padding-top: 60px; } }
    @media (max-width: 1198.98px) {
      .section-with-side-image {
        padding-bottom: 100px;
        font-size: 16px;
        line-height: 24px; } }
    .section-with-side-image p {
      margin-bottom: 80px; }
      @media (max-width: 1198.98px) {
        .section-with-side-image p {
          margin-bottom: 40px; } }
      .section-with-side-image p:last-child {
        margin-bottom: 0; }
  .section-offer {
    margin-top: 120px;
    margin-bottom: 108px;
    padding-top: 60px;
    padding-bottom: 68px;
    background: linear-gradient(236.19deg, #305C7B 0%, #366384 0%, #BFD9EB 100%);
    color: white; }
    @media (max-width: 1198.98px) {
      .section-offer--mobile-wide {
        margin-right: -20px;
        margin-left: -20px; } }
    .section-offer--mt-sm {
      margin-top: 100px; }
    .section-offer--mt-xs {
      margin-top: 60px; }
    .section-offer--mt-sm-down {
      margin-top: 80px; }
    .section-offer--second-bg {
      background: linear-gradient(180deg, #CDE2EF 0%, #B5D7EE 100%);
      color: #222222; }
    .section-offer__btn {
      margin-bottom: 35px; }
    @media (max-width: 1198.98px) {
      .section-offer {
        margin-top: 0px;
        margin-bottom: 40px; } }
    .section-offer--sm {
      margin-top: 80px;
      padding-top: 40px;
      padding-bottom: 48px; }
      @media (max-width: 1198.98px) {
        .section-offer--sm {
          margin-top: 100px;
          margin-bottom: 80px; } }
      @media (max-width: 799.98px) {
        .section-offer--sm {
          margin-left: -20px;
          margin-right: -20px; } }
    @media (max-width: 1198.98px) {
      .section-offer--mobile-sm {
        padding-top: 40px;
        padding-bottom: 40px; } }
    @media (max-width: 1198.98px) {
      .section-offer--mobile-mt {
        margin-top: 60px; } }
    .section-offer--mb-sm {
      margin-bottom: 48px; }
  .section-details {
    padding-top: 60px;
    padding-bottom: 62px;
    background: #F7F7F9; }
    @media (max-width: 1198.98px) {
      .section-details {
        padding-top: 40px;
        padding-bottom: 55px; } }
  .section-chess {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 80px; }
    @media (max-width: 1598.98px) {
      .section-chess {
        margin-bottom: 87px; } }
    @media (max-width: 1198.98px) {
      .section-chess {
        margin-bottom: 42px; } }
    .section-chess--special {
      overflow-x: hidden; }
      .section-chess--special > .chess > .container > .row {
        position: relative; }
        .section-chess--special > .chess > .container > .row::before {
          content: ' ';
          display: block;
          position: absolute;
          z-index: -1;
          left: 50%;
          top: 0;
          width: 100vw;
          transform: translateX(-50vw);
          height: 100%;
          background: #F7F7F9; }
        @media (max-width: 1198.98px) {
          .section-chess--special > .chess > .container > .row {
            position: static; }
            .section-chess--special > .chess > .container > .row::before {
              content: none; } }
    .section-chess--first {
      margin-top: 100px; }
      @media (max-width: 1598.98px) {
        .section-chess--first {
          margin-top: 108px; } }
      @media (max-width: 1198.98px) {
        .section-chess--first {
          margin-top: 60px; } }
  .section-facilities {
    padding-top: 0; }
    @media (max-width: 1198.98px) {
      .section-facilities {
        margin-top: 40px;
        padding-bottom: 0; } }
  .section-other {
    padding-top: 180px; }
  @media (max-width: 1198.98px) {
    .section-map-view {
      padding-top: 0;
      padding-bottom: 0;
      position: relative;
      margin-top: 75px; } }
  .section-detail-view {
    padding-top: 140px;
    padding-bottom: 0; }
    @media (max-width: 1198.98px) {
      .section-detail-view {
        padding-top: 96px; } }
  .section-statistic {
    padding-bottom: 145px; }
    .section-statistic .container {
      position: relative; }
    @media (max-width: 1899.98px) {
      .section-statistic {
        padding-bottom: 124px; } }
    @media (max-width: 1598.98px) {
      .section-statistic {
        padding-top: 132px; } }
    @media (max-width: 1198.98px) {
      .section-statistic {
        padding-top: 50px; }
        .section-statistic .section__title {
          margin-bottom: 27px; } }
  .section-col-steps {
    padding-bottom: 70px; }
    @media (max-width: 1198.98px) {
      .section-col-steps {
        padding-top: 60px;
        padding-bottom: 40px; } }
    .section-col-steps .section__title {
      margin-bottom: 80px; }
      @media (max-width: 1198.98px) {
        .section-col-steps .section__title {
          margin-bottom: 40px; } }
  .section-col-steps-lg {
    padding-top: 100px;
    padding-bottom: 92px;
    background: #F7F7F9; }
    @media (max-width: 1198.98px) {
      .section-col-steps-lg {
        padding-top: 60px;
        padding-bottom: 40px; } }
    .section-col-steps-lg .section__title {
      margin-bottom: 100px; }
      @media (max-width: 1198.98px) {
        .section-col-steps-lg .section__title {
          margin-bottom: 40px; } }
  .section-steps {
    background: #F7F7F9;
    padding-top: 80px;
    padding-bottom: 20px; }
    .section-steps .section__title {
      margin-bottom: 100px; }
      @media (max-width: 1198.98px) {
        .section-steps .section__title {
          margin-bottom: 60px; } }
  .section-instructor-list {
    margin-top: 170px;
    padding-top: 128px;
    padding-bottom: 69px;
    background: linear-gradient(236.19deg, #305C7B 0%, #366384 0%, #BFD9EB 100%);
    color: white; }
    @media (max-width: 1899.98px) {
      .section-instructor-list {
        margin-top: 176px;
        padding-top: 120px;
        padding-bottom: 77px; } }
    @media (max-width: 1598.98px) {
      .section-instructor-list {
        margin-top: 73px;
        padding-top: 103px;
        padding-bottom: 45px; } }
    @media (max-width: 1198.98px) {
      .section-instructor-list {
        padding-bottom: 76px; }
        .section-instructor-list .section__descr {
          margin-bottom: 20px; } }
    @media (max-width: 799.98px) {
      .section-instructor-list {
        margin-top: 15px;
        padding-bottom: 32px; } }
  .section-our-facilities {
    padding-top: 100px;
    padding-bottom: 88px; }
    @media (max-width: 1899.98px) {
      .section-our-facilities {
        padding-top: 83px;
        padding-bottom: 80px; } }
    @media (min-width: 1199px) and (max-width: 1598.98px) {
      .section-our-facilities .section__title {
        margin-bottom: 76px; } }
    @media (max-width: 1198.98px) {
      .section-our-facilities {
        padding-bottom: 97px; } }
    @media (max-width: 799.98px) {
      .section-our-facilities {
        padding-top: 53px;
        padding-bottom: 72px; } }
  .section-current-facilities {
    padding-top: 50px;
    padding-bottom: 88px; }
    @media (max-width: 1598.98px) {
      .section-current-facilities {
        padding-bottom: 70px; } }
    @media (max-width: 1198.98px) {
      .section-current-facilities {
        padding-top: 10px; }
        .section-current-facilities .section__title {
          margin-bottom: 40px; } }
  .section-comment-list {
    padding-top: 100px; }
    @media (max-width: 1198.98px) {
      .section-comment-list {
        padding-top: 60px;
        padding-bottom: 60px; } }
  .section-find-instructor {
    padding-top: 50px; }
  .section-our-family {
    padding-top: 80px;
    background: #F7F7F9;
    padding-bottom: 116px;
    overflow-x: hidden; }
    @media (max-width: 1598.98px) {
      .section-our-family {
        padding-top: 90px;
        padding-bottom: 124px; } }
    @media (max-width: 1198.98px) {
      .section-our-family {
        padding-top: 79px;
        padding-bottom: 144px; }
        .section-our-family > .container > .section__title {
          margin-bottom: 50px; } }
    @media (max-width: 1198.98px) and (max-width: 799.98px) {
      .section-our-family > .container > .section__title {
        font-size: 21px;
        line-height: 1.5;
        margin-bottom: 26px; } }
    @media (max-width: 799.98px) {
      .section-our-family {
        padding-top: 35px;
        padding-bottom: 75px; } }
  .section-verify {
    padding-top: 60px;
    padding-bottom: 65px;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    background: linear-gradient(180deg, #CDE2EF 0%, #B5D7EE 100%); }
    @media (max-width: 1198.98px) {
      .section-verify .section__title {
        margin-bottom: 58px; } }
    @media (max-width: 799.98px) {
      .section-verify .section__title {
        margin-bottom: 30px; } }
    .section-verify--lg {
      padding-top: 77px;
      padding-bottom: 100px; }
      @media (max-width: 1198.98px) {
        .section-verify--lg {
          padding-top: 60px; } }
  .section-questions {
    padding-top: 129px;
    padding-bottom: 87px; }
    @media (max-width: 1899.98px) {
      .section-questions {
        padding-top: 100px;
        padding-bottom: 190px; } }
    @media (max-width: 1598.98px) {
      .section-questions {
        padding-top: 126px;
        padding-bottom: 133px; } }
    @media (max-width: 1198.98px) {
      .section-questions {
        padding-top: 101px;
        padding-bottom: 185px; }
        .section-questions .section__descr {
          margin-bottom: 38px;
          font-size: 14px;
          line-height: 18px; } }
    @media (max-width: 799.98px) {
      .section-questions {
        padding-top: 61px;
        padding-bottom: 67px; } }
    .section-questions .container {
      position: relative; }
    .section-questions .section__image {
      position: absolute;
      object-fit: cover;
      right: 145px;
      width: 440px;
      height: auto;
      bottom: -87px; }
      @media (max-width: 1899.98px) {
        .section-questions .section__image {
          bottom: -100px; } }
      @media (max-width: 1598.98px) {
        .section-questions .section__image {
          width: 330px;
          bottom: -31px;
          right: 110px; } }
      @media (max-width: 1198.98px) {
        .section-questions .section__image {
          display: none; } }
  .section__back-btn {
    margin-bottom: 60px; }
    @media (max-width: 1198.98px) {
      .section__back-btn {
        margin-bottom: 20px; } }
  .section__title {
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 56px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700; }
    @media (max-width: 1598.98px) {
      .section__title {
        font-size: 30px;
        line-height: 36.57px; } }
    @media (min-width: 1199px) and (max-width: 1598.98px) {
      .section__title {
        margin-bottom: 27px; } }
    .section__title--subheader-desktop {
      font-size: 40px;
      line-height: 56px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700; }
      @media (max-width: 1198.98px) {
        .section__title--subheader-desktop {
          font-size: 21px;
          line-height: 25px; } }
    .section__title--mb-md {
      margin-bottom: 40px; }
    .section__title--mb-lg {
      margin-bottom: 60px; }
      @media (max-width: 1198.98px) {
        .section__title--mb-lg {
          margin-bottom: 27px; } }
    .section__title--mobile-mb-md {
      margin-bottom: 50px; }
    .section__title--mb-xl {
      margin-bottom: 100px; }
      @media (max-width: 1198.98px) {
        .section__title--mb-xl {
          margin-bottom: 40px; } }
    .section__title--sm-forced {
      font-size: 28px;
      line-height: 36px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600; }
    .section__title--sm {
      font-size: 28px;
      line-height: 36px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600; }
      @media (max-width: 1598.98px) {
        .section__title--sm {
          font-size: 21px;
          line-height: 36px; } }
      @media (max-width: 1198.98px) {
        .section__title--sm {
          font-size: 21px;
          line-height: 30px; } }
    .section__title--xs {
      font-size: 22px;
      line-height: 30px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600; }
    @media (max-width: 1198.98px) {
      .section__title--mobile-xxs {
        font-size: 16px; } }
    @media (max-width: 1198.98px) {
      .section__title--mobile-subtitle {
        font-size: 22px;
        line-height: 30px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600; } }
  .section__descr {
    margin-bottom: 60px; }
    .section__descr--mb-sm {
      margin-bottom: 40px; }
  .section__btns {
    display: flex; }
    @media (max-width: 1198.98px) {
      .section__btns {
        flex-direction: column;
        align-items: flex-start; } }
    .section__btns .btn:not(:last-child) {
      margin-right: 20px; }
      @media (max-width: 1198.98px) {
        .section__btns .btn:not(:last-child) {
          margin-bottom: 20px; } }

@media (max-width: 1198.98px) {
  .big-statistic {
    padding-top: 56px; } }

@media (max-width: 799.98px) {
  .big-statistic {
    padding-top: 39px; } }

@media (min-width: 1199px) and (max-width: 1598.98px) {
  .big-statistic {
    position: relative;
    bottom: 20px; } }

@media (max-width: 1198.98px) {
  .big-statistic {
    text-align: center; } }

.big-statistic__title {
  font-size: 240px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  line-height: 230px;
  color: #C2DEF0; }
  @media (max-width: 1598.98px) {
    .big-statistic__title {
      font-size: 180px;
      line-height: 200px; } }
  @media (max-width: 1198.98px) {
    .big-statistic__title {
      font-size: 80px;
      line-height: 97.52px; } }

.big-statistic__subtitle {
  font-size: 40px;
  line-height: 56px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 0 0 0 240px;
  min-height: 113px; }
  @media (max-width: 1598.98px) {
    .big-statistic__subtitle {
      font-size: 30px;
      line-height: 36.57px; } }
  @media (max-width: 1598.98px) {
    .big-statistic__subtitle {
      margin-left: 180px; } }
  @media (max-width: 1198.98px) {
    .big-statistic__subtitle {
      margin-left: 0;
      font-size: 22px;
      min-height: 65px;
      line-height: 30px; } }
  @media (max-width: 799.98px) {
    .big-statistic__subtitle {
      margin-bottom: 21px; } }

.facilities-item {
  width: 100%;
  transition: color 0.2s ease-in-out;
  margin-bottom: 60px;
  display: block; }
  @media (max-width: 1198.98px) {
    .facilities-item {
      text-decoration: underline;
      margin-bottom: 20px; } }
  .facilities-item__image-wrap {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    margin-bottom: 20px; }
    @media (max-width: 1198.98px) {
      .facilities-item__image-wrap {
        display: none; } }
  .facilities-item__image {
    height: 56px;
    width: auto; }
    @media (max-width: 1598.98px) {
      .facilities-item__image {
        height: 50px; } }
  .facilities-item__descr {
    font-size: 20px;
    line-height: 28px; }
    @media (max-width: 1198.98px) {
      .facilities-item__descr {
        font-size: 16px;
        line-height: 24px;
        text-align: center; }
        .facilities-item__descr br {
          display: none; } }

.resource-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 144px; }
  @media (max-width: 1198.98px) {
    .resource-list {
      max-width: none; } }
  @media (max-width: 799.98px) {
    .resource-list {
      justify-content: center; } }
  .resource-list--lg {
    max-width: 244px; }
  .resource-list--xl {
    max-width: 100%; }
  .resource-list__item {
    width: 32px;
    height: 32px;
    transition: opacity 0.3s ease-in-out;
    opacity: 1; }
    .resource-list__item--hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0; }
  .resource-list__link {
    display: inline-block;
    margin-left: 40px;
    margin-bottom: 40px;
    transition: opacity 0.3s ease-in-out;
    position: relative; }
    @media (max-width: 1899.98px) {
      .resource-list__link {
        margin-left: 38px; } }
    @media (max-width: 1198.98px) {
      .resource-list__link {
        margin-right: 20px;
        margin-left: 20px; } }
    .resource-list__link--lg {
      margin-left: 0;
      margin-right: 60px; }
      @media (max-width: 799.98px) {
        .resource-list__link--lg {
          margin-left: 24px;
          margin-right: 0;
          margin-bottom: 50px; } }
      .resource-list__link--lg .resource-list__item {
        width: 60px;
        height: 60px; }
        @media (max-width: 799.98px) {
          .resource-list__link--lg .resource-list__item {
            height: 32px;
            width: 32px; } }
    @media (max-width: 1598.98px) {
      .resource-list__link--mobile-lg .resource-list__item {
        width: 40px;
        height: 40px;
        margin-right: 24px;
        margin-bottom: 0; } }
    .resource-list__link:not(.resource-list--custom-hover):hover {
      opacity: 0.5; }
    .resource-list__link--custom-hover:hover .resource-list__item {
      opacity: 0; }
      .resource-list__link--custom-hover:hover .resource-list__item--hover {
        opacity: 1; }

a.thumbnail-preview:hover {
  text-decoration: none; }
  a.thumbnail-preview:hover .thumbnail-preview__img {
    transform: scale(1.03); }
  a.thumbnail-preview:hover .thumbnail-preview__title {
    text-decoration: underline; }

.thumbnail-preview {
  display: block;
  height: 320px;
  width: 100%;
  position: relative;
  padding: 30px;
  margin-bottom: 40px;
  overflow: hidden;
  display: flex;
  -webkit-mask-image: -webkit-radial-gradient(white, black); }
  @media (max-width: 1598.98px) {
    .thumbnail-preview {
      height: 220px; } }
  @media (max-width: 1198.98px) {
    .thumbnail-preview {
      height: 370px;
      margin-bottom: 20px; } }
  @media (max-width: 799.98px) {
    .thumbnail-preview {
      height: 150px;
      padding: 20px; } }
  .thumbnail-preview--sm {
    height: 220px;
    padding: 20px; }
    @media (max-width: 799.98px) {
      .thumbnail-preview--sm {
        height: 176px; } }
  .thumbnail-preview--rounded {
    border-radius: 20px; }
    .thumbnail-preview--rounded::before {
      border-radius: 20px; }
  .thumbnail-preview--slider {
    margin: 0; }
  .thumbnail-preview::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #24374E 0%, rgba(36, 55, 78, 0) 70%);
    transform: rotate(-180deg);
    z-index: 3; }
  .thumbnail-preview__in {
    position: relative;
    z-index: 4;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    width: 100%; }
  .thumbnail-preview__title {
    color: white;
    text-decoration: none;
    margin: 0; }
  .thumbnail-preview__icon {
    width: 20px;
    height: 20px;
    display: block;
    background: url(../images/sprites/main.stack.svg#image-inst-footer);
    background-size: contain; }
  .thumbnail-preview__img {
    transition: transform 0.3s ease-in-out;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.circle-media {
  position: relative;
  height: 480px;
  width: 100%; }
  .circle-media__img {
    position: absolute;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    transition: transform 0.3s ease-in-out; }
  .circle-media__icon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.3s ease-in-out;
    z-index: 3; }
    .circle-media__icon--sm {
      width: 32px;
      height: 32px; }
  .circle-media__item {
    display: block;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    overflow: hidden; }
    .circle-media__item:hover .circle-media__img {
      transform: scale(1.03); }
    .circle-media__item::before {
      content: ' ';
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: linear-gradient(180deg, #24374E 0%, rgba(36, 55, 78, 0) 70%); }
    .circle-media__item--lg {
      width: 220px;
      height: 220px; }
    .circle-media__item--sm {
      width: 160px;
      height: 160px; }
    .circle-media__item--xs {
      width: 120px;
      height: 120px; }
    .circle-media__item--top-left {
      left: 0;
      top: 0;
      animation: float 18s ease-in-out infinite; }
    .circle-media__item--top-right {
      right: 0;
      top: 0;
      animation: float 15s ease-in-out infinite; }
    .circle-media__item--bottom-left {
      top: 260px;
      left: 0;
      animation: float 12s ease-in-out infinite; }
    .circle-media__item--bottom-right {
      top: 180px;
      right: 0;
      animation: float 9s ease-in-out infinite; }

@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-15px); }
  100% {
    transform: translatey(0px); } }

.side-card {
  margin-top: 46px;
  position: relative;
  padding-left: 160px;
  height: 324px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  @media (max-width: 1598.98px) {
    .side-card {
      padding-left: 10px;
      margin-top: 5px; } }
  @media (max-width: 1198.98px) {
    .side-card {
      padding-left: 30px;
      margin-top: 38px; } }
  @media (min-width: 800px) and (max-width: 1198.98px) {
    .side-card .section__title {
      font-size: 40px;
      line-height: 56px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700; } }
  @media (max-width: 799.98px) {
    .side-card:not(.side-card--full-width) {
      margin-top: -8px;
      position: relative;
      flex-direction: column-reverse;
      height: 260px; }
      .side-card:not(.side-card--full-width) .section__title {
        font-size: 21px;
        line-height: 1.5;
        margin-bottom: 12px; }
      .side-card:not(.side-card--full-width) .section__descr {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0; } }
  .side-card::before {
    content: ' ';
    display: block;
    height: 100%;
    width: 100vw;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: linear-gradient(97.22deg, #EAF3F9 10.66%, #FFFFFF 98.43%);
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    border-top-left-radius: 160px;
    border-bottom-left-radius: 160px; }
    @media (max-width: 1598.98px) {
      .side-card::before {
        left: -12vw; } }
    @media (max-width: 799.98px) {
      .side-card::before {
        left: 0; } }
  .side-card--full-width {
    padding-left: 0;
    height: auto;
    padding-top: 80px;
    padding-bottom: 100px; }
    .side-card--full-width::before {
      border-radius: 0; }
    @media (max-width: 1598.98px) {
      .side-card--full-width {
        padding-top: 60px;
        padding-bottom: 25px;
        margin-top: 0; } }
  .side-card__content {
    position: relative;
    z-index: 2;
    max-width: 290px;
    margin-top: 60px; }
    @media (max-width: 799.98px) {
      .side-card__content {
        max-width: 220px;
        left: 35px; } }
  .side-card__media {
    position: relative;
    z-index: 2;
    margin-top: 40px; }
    @media (max-width: 799.98px) {
      .side-card__media {
        margin-top: 38px;
        left: 12px; } }
  .side-card__center-media, .side-card__center-title {
    position: relative;
    z-index: 2; }
  .side-card__center-title {
    margin-bottom: 84px; }
    @media (max-width: 1198.98px) {
      .side-card__center-title {
        margin-bottom: 55px; } }

.popup-menu {
  position: absolute;
  top: 140px;
  left: 100px;
  padding: 40px 40px 40px 40px;
  display: flex;
  border: 1px solid #C2E7FF;
  box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.12);
  background: white;
  width: 1030px; }
  .popup-menu--with-descr {
    width: 1130px;
    padding-bottom: 0; }
  @media (max-width: 1198.98px) {
    .popup-menu {
      width: auto;
      position: static;
      padding: 0;
      box-shadow: none;
      border: none;
      padding-left: 20px;
      display: none; } }
  .popup-menu__descr {
    max-width: 300px;
    margin-right: 50px;
    flex-shrink: 0; }
    @media (max-width: 1198.98px) {
      .popup-menu__descr {
        display: none; } }
  .popup-menu__list {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    margin-right: -25px;
    margin-left: -25px; }
    @media (max-width: 1198.98px) {
      .popup-menu__list {
        margin-right: 0;
        margin-left: 0;
        flex-direction: column; } }
    .popup-menu__list .expanded-link {
      margin-right: 25px;
      margin-left: 25px;
      margin-bottom: 50px; }
    .popup-menu__list--single-line .expanded-link {
      margin-bottom: 0; }
  @media (max-width: 1198.98px) {
    .popup-menu__list .expanded-link {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 32px; }
      .popup-menu__list .expanded-link .expanded-link__thumbnail {
        display: none; }
      .popup-menu__list .expanded-link .expanded-link__caption {
        padding-bottom: 0;
        font-family: "Roobert", "Helvetica", Arial, sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400; }
        .popup-menu__list .expanded-link .expanded-link__caption::after, .popup-menu__list .expanded-link .expanded-link__caption::before {
          display: none; } }

.expanded-link {
  display: block;
  width: 199px; }
  .expanded-link:hover {
    text-decoration: none; }
    .expanded-link:hover .expanded-link__caption::before {
      opacity: 0; }
    .expanded-link:hover .expanded-link__caption::after {
      opacity: 1; }
  .expanded-link__thumbnail {
    border-radius: 10px;
    display: block;
    margin-bottom: 20px;
    height: 140px; }
  .expanded-link__caption {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    padding-bottom: 21px;
    position: relative;
    margin: 0; }
    .expanded-link__caption::before, .expanded-link__caption::after {
      content: ' ';
      display: block;
      width: 21px;
      height: 21px;
      transition: opacity 0.3s ease-in-out;
      position: absolute;
      bottom: -7px;
      left: 0; }
    .expanded-link__caption::before {
      opacity: 1;
      background: url(../images/sprites/main.stack.svg#image-arr-right);
      background-repeat: no-repeat; }
    .expanded-link__caption::after {
      opacity: 0;
      background: url(../images/sprites/main.stack.svg#image-arr-right-primary);
      background-repeat: no-repeat; }

.tabs-list__item {
  border: none;
  background: transparent;
  height: 40px;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  padding: 0;
  color: #81929B;
  transition: color 0.3s ease-in-out; }
  @media (max-width: 1598.98px) {
    .tabs-list__item {
      height: 50px; } }
  .tabs-list__item:not(.tabs-list__item--active):hover, .tabs-list__item:not(.tabs-list__item--active):focus {
    color: #222222; }
  .tabs-list__item:not(:last-child) {
    margin-right: 40px; }
  @media (max-width: 1598.98px) {
    .tabs-list__item--mobile-friendly:not(:last-child) {
      margin-right: 20px; } }
  .tabs-list__item--mobile-sm {
    height: 36px;
    font-size: 16px; }
  @media (max-width: 1598.98px) {
    .tabs-list__item--search-box {
      height: 40px; }
      .tabs-list__item--search-box:not(:last-child) {
        margin-right: 40px; } }
  .tabs-list__item:focus {
    outline: none; }
  .tabs-list__item::after {
    content: ' ';
    display: block;
    position: absolute;
    height: 2px;
    left: 0;
    width: 0;
    bottom: 0;
    background: #305C7B;
    transition: width 0.3s ease-in-out, color 0.3s ease-in-out; }
  .tabs-list__item--active {
    color: #305C7B; }
    .tabs-list__item--active::after {
      width: 100%; }

.tabs-content-list__item {
  margin-top: 73px;
  display: none; }
  @media (max-width: 1899.98px) {
    .tabs-content-list__item {
      margin-top: 71px; } }
  @media (max-width: 1198.98px) {
    .tabs-content-list__item {
      margin-top: 79px; } }
  @media (max-width: 799.98px) {
    .tabs-content-list__item {
      margin-top: 40px; } }
  .tabs-content-list__item--active {
    display: block; }
  .tabs-content-list__item--static {
    margin-top: 40px; }
  .tabs-content-list__item--modal > p {
    margin-bottom: 22px; }
    .tabs-content-list__item--modal > p:last-child {
      margin-bottom: 0; }
  .tabs-content-list__item--box {
    margin-top: 55px; }
    @media (max-width: 1598.98px) {
      .tabs-content-list__item--box {
        margin-top: 37px; } }
    @media screen and (max-height: 570px) {
      .tabs-content-list__item--box {
        margin-top: 10px; } }
  .tabs-content-list__item--chess {
    margin-top: 32px;
    margin-bottom: 32px; }
    @media (max-width: 1198.98px) {
      .tabs-content-list__item--chess {
        font-size: 14px;
        line-height: 18px; } }
    .tabs-content-list__item--chess p {
      margin-bottom: 13px; }
      @media (max-width: 1198.98px) {
        .tabs-content-list__item--chess p {
          margin-bottom: 10px; } }

.comment {
  display: block;
  margin-bottom: 36px; }
  @media (max-width: 799.98px) {
    .comment {
      margin-bottom: 77px; } }
  .comment--mb-lg {
    margin-bottom: 80px; }
  .comment__title {
    font-size: 22px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 24px; }
    @media (max-width: 1198.98px) {
      .comment__title--with-status {
        display: flex;
        flex-direction: column;
        align-items: flex-start; } }
  .comment__status {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase;
    padding: 4px 10px;
    line-height: 17px;
    margin-left: 15px;
    background: #eaeaea; }
    @media (max-width: 1198.98px) {
      .comment__status {
        display: block;
        margin-left: 0;
        width: auto;
        margin-top: 20px; } }
  .comment__meta-list {
    color: #81929B;
    margin-bottom: 24px; }
    .comment__meta-list a {
      text-decoration: underline;
      color: #81929B; }
      .comment__meta-list a:hover {
        color: #4AA6FA; }
  .comment__detail {
    margin: 0; }
  .comment__star {
    display: block;
    margin-right: 20px;
    width: 20px;
    height: 20px; }
  .comment__rating {
    margin-bottom: 24px;
    display: flex; }
  .comment__content p:last-child {
    margin-bottom: 0; }
  .comment__link {
    color: #4AA6FA; }

.instructor-slider--mt {
  margin-top: 101px; }
  @media (max-width: 1198.98px) {
    .instructor-slider--mt {
      margin-top: 55px; } }
  @media (max-width: 799.98px) {
    .instructor-slider--mt {
      margin-top: 34px; } }

.instructor-slider__title {
  font-size: 28px;
  line-height: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-bottom: 62px;
  font-weight: bold; }
  @media (max-width: 1598.98px) {
    .instructor-slider__title {
      font-size: 21px;
      line-height: 36px; } }
  @media (min-width: 800px) and (max-width: 1198.98px) {
    .instructor-slider__title {
      font-size: 28px;
      line-height: 36px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      margin-bottom: 53px; } }
  @media (max-width: 799.98px) {
    .instructor-slider__title {
      margin-bottom: 35px; } }

@media (max-width: 1198.98px) {
  .row-detail-view {
    flex-direction: column-reverse; } }

.row-detail-view__main-col {
  padding-top: 40px;
  padding-bottom: 40px; }
  @media (max-width: 1198.98px) {
    .row-detail-view__main-col {
      padding-top: 0; } }

.row-right-blue {
  position: relative; }
  .row-right-blue::after {
    content: ' ';
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    left: 51.2%;
    width: 50vw;
    background: #EDF1F8;
    z-index: -1; }
    @media (max-width: 1198.98px) {
      .row-right-blue::after {
        content: none; } }

.detail-view {
  position: sticky;
  top: 120px; }
  .detail-view__in {
    max-height: calc(100vh - 120px);
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    overflow-y: hidden;
    margin-top: -10px;
    overflow-y: auto;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */ }
    .detail-view__in::-webkit-scrollbar {
      width: 0;
      height: 0; }
    @media (max-width: 1598.98px) {
      .detail-view__in {
        margin-top: 0; } }
    @media (max-width: 1198.98px) {
      .detail-view__in {
        max-height: none;
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0; } }
  .detail-view__title {
    color: #305C7B;
    font-size: 40px;
    line-height: 56px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 35px; }
    @media (max-width: 1598.98px) {
      .detail-view__title {
        font-size: 30px;
        line-height: 36.57px; } }
    @media (max-width: 1598.98px) {
      .detail-view__title {
        font-size: 21px;
        line-height: 56px;
        margin-bottom: 16px; } }
  .detail-view__img {
    max-width: 280px;
    max-height: 262px;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-bottom: 60px; }
    @media (max-width: 1598.98px) {
      .detail-view__img {
        max-width: 180px;
        max-height: 168px; } }
    @media (max-width: 799.98px) {
      .detail-view__img {
        max-width: 235px;
        max-height: 219px; } }
  .detail-view__tabs {
    font-size: 20px;
    line-height: 28px; }
    .detail-view__tabs p {
      margin-bottom: 24px; }
    .detail-view__tabs a {
      color: #4AA6FA; }

@media (max-width: 1198.98px) {
  .row-map-view {
    min-height: 90vh; }
    .row-map-view .row-map-view__main-col {
      transition: all 0.3s ease-in-out; }
    .row-map-view--mobile-map-view .row-map-view__main-col {
      opacity: 0;
      transform: translateY(20px);
      z-index: -1;
      pointer-events: none;
      max-height: 100vh; }
    .row-map-view--mobile-map-view .map-slider {
      opacity: 1;
      pointer-events: auto; } }

.row-map-view__toggle {
  display: none;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  padding: 11px 20px 9px;
  background: rgba(34, 34, 34, 0.7);
  backdrop-filter: blur(4px);
  border-radius: 32px;
  font-size: 14px;
  line-height: 17px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  z-index: 4;
  border: none;
  color: white;
  transition: all 0.3s ease-in-out; }
  .row-map-view__toggle:hover, .row-map-view__toggle:focus {
    outline: none;
    background: rgba(34, 34, 34, 0.8); }
  .row-map-view__toggle::after {
    content: ' ';
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-left: 8px; }
  .row-map-view__toggle--to-map-view {
    bottom: 40px; }
    .row-map-view__toggle--to-map-view::after {
      background: url(../images/sprites/main.stack.svg#image-map-marker-white);
      background-repeat: no-repeat;
      background-size: cover; }
  .row-map-view__toggle--to-list-view {
    top: 156px; }
    .row-map-view__toggle--to-list-view::after {
      background: url(../images/sprites/main.stack.svg#image-list-white);
      background-repeat: no-repeat;
      background-size: cover; }

@media (max-width: 1198.98px) {
  .row-map-view--mobile-list-view .row-map-view__toggle--to-list-view {
    display: none; }
  .row-map-view--mobile-list-view .row-map-view__toggle--to-map-view {
    display: block; } }

@media (max-width: 1198.98px) {
  .row-map-view--mobile-map-view .row-map-view__toggle--to-list-view {
    display: block; }
  .row-map-view--mobile-map-view .row-map-view__toggle--to-map-view {
    display: none; } }

.row-map-view__main-col {
  padding-top: 65px;
  padding-bottom: 40px; }
  @media (min-width: 1199px) {
    .row-map-view__main-col {
      display: block !important; } }
  .row-map-view__main-col--lg {
    margin-top: 700px; }
  @media (max-width: 1198.98px) {
    .row-map-view__main-col {
      z-index: 4;
      padding-top: 00px;
      margin-top: 300px;
      position: relative; }
      .row-map-view__main-col::after {
        content: ' ';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        width: 40px;
        background: #81929B;
        top: 10px; }
      .row-map-view__main-col::before {
        content: ' ';
        display: block;
        left: 50%;
        transform: translateX(-50vw);
        z-index: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        position: absolute;
        background: #F7F7F9;
        box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.12);
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        z-index: -1; }
      .row-map-view__main-col .card-list {
        margin-top: 40px; }
        .row-map-view__main-col .card-list__title {
          text-align: center;
          z-index: 1;
          position: relative; } }
  @media (max-width: 1198.98px) {
    .row-map-view__main-col {
      padding-top: 0; } }

@media (max-width: 1198.98px) {
  .row-map-view__map-col {
    position: static; } }

.row-map-view__map-col--lg {
  position: static;
  position: absolute;
  left: 0;
  top: 140px;
  width: 100%;
  height: 720px; }
  @media (max-width: 1198.98px) {
    .row-map-view__map-col--lg {
      top: 0px; } }

@media (max-width: 1198.98px) {
  .row-map-view__map {
    top: 0;
    width: 100vw;
    bottom: 0;
    max-height: 90vh;
    left: 0;
    right: unset;
    position: absolute; } }

.card-list--pt {
  padding-top: 60px; }
  @media (max-width: 1598.98px) {
    .card-list--pt {
      padding-top: 0; } }

.card-list .card {
  margin-bottom: 55px; }

@media (max-width: 1198.98px) {
  .card-list--mobile-mt {
    margin-top: 125px; } }

@media (max-width: 799.98px) {
  .card-list--mobile-mt {
    margin-top: 94px; } }

.card-list__title {
  color: #81929B;
  margin-bottom: 70px;
  font-size: 22px;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600; }
  @media (max-width: 1198.98px) {
    .card-list__title {
      margin-bottom: 55px; } }

.card {
  position: relative;
  background: white; }
  .card--with-thumbnail {
    padding-right: 160px; }
    @media (max-width: 799.98px) {
      .card--with-thumbnail {
        padding-right: 0; } }
  .card--info-window .card__body {
    padding: 36px 40px 16px 30px; }
  .card--info-window .card__content {
    margin: 0; }
  .card--info-window .card__btns {
    margin-bottom: 10px; }
  .card__thumbnail {
    position: absolute;
    width: 200px;
    height: 200px;
    right: 0;
    top: 0;
    object-fit: cover; }
    @media (max-width: 1899.98px) {
      .card__thumbnail {
        height: 180px;
        width: 180px; } }
    @media (max-width: 799.98px) {
      .card__thumbnail {
        position: static;
        width: 100%;
        height: 280px; } }
  .card--wide-in-modal {
    margin-left: -60px;
    margin-right: -60px; }
  .card--mb-row {
    margin-bottom: 40px; }
  @media (max-width: 1198.98px) {
    .card--mt-mobile {
      margin-top: 43px; } }
  .card--mb-modal {
    margin-bottom: 60px; }
    @media (max-width: 1198.98px) {
      .card--mb-modal {
        margin-bottom: 40px; } }
  .card__title {
    font-size: 22px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 23px; }
    .card__title--mb {
      margin-bottom: 40px; }
  .card a:not(.btn) {
    color: #4AA6FA; }
  @media (max-width: 799.98px) {
    .card {
      margin-right: -20px;
      margin-left: -20px; } }
  @media (max-width: 799.98px) {
    .card--without-body {
      margin-right: 0;
      margin-left: 0; } }
  .card--map-slide {
    margin-top: 15px; }
    @media (max-width: 1198.98px) {
      .card--map-slide {
        margin-left: 0;
        margin-right: 0; } }
    .card--map-slide .card__text {
      margin-bottom: 16px; }
      .card--map-slide .card__text:not(:last-child) {
        margin-bottom: 6px; }
    .card--map-slide .card__body {
      padding: 32px 32px 16px 32px; }
      @media (max-width: 1198.98px) {
        .card--map-slide .card__body {
          margin: 0; } }
  .card__company-icon {
    position: absolute;
    max-height: 50px;
    max-width: 190px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right center;
    right: 40px;
    top: 40px; }
    @media (max-width: 1899.98px) {
      .card__company-icon {
        position: static;
        margin-bottom: 40px;
        object-position: left center; } }
  .card__body {
    padding: 38px 40px 33px 40px; }
    .card__body--wide-in-modal {
      padding-left: 60px;
      padding-right: 60px; }
    @media (max-width: 799.98px) {
      .card__body {
        padding-left: 20px;
        padding-right: 20px; } }
  .card__meta {
    position: absolute;
    font-size: 14px;
    left: 40px;
    top: -15px;
    display: flex;
    align-items: center; }
    .card__meta-item {
      padding: 2.5px 12px;
      background: #C2DEF0;
      font-weight: 500;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      margin-right: 10px;
      min-height: 29px;
      display: flex;
      align-items: center; }
      .card__meta-item--icon {
        height: 29px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        text-transform: none; }
        @media (min-width: 1199px) {
          .card__meta-item--icon {
            display: none; } }
        .card__meta-item--icon-always {
          display: flex; }
    .card__meta-icon {
      width: 24px;
      height: 24px;
      object-fit: cover; }
  .card__text:not(:last-child) {
    margin-bottom: 16px; }
  .card__text--mb-md:not(:last-child) {
    margin-bottom: 20px; }
  .card__text--mb-md:last-child {
    margin-bottom: 20px; }
  .card__text--mb-lg:not(:last-child) {
    margin-bottom: 23px; }
  .card__text--mb-lg:last-child {
    margin-bottom: 23px; }
  .card__text--sm {
    font-size: 12px; }
  .card__actions {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    @media (max-width: 1598.98px) {
      .card__actions .hint--html {
        display: none; } }
  .card__btns {
    display: flex;
    margin-bottom: 10px; }
    .card__btns .btn:not(:last-child) {
      margin-right: 20px; }
    @media (max-width: 1598.98px) {
      .card__btns {
        margin-bottom: 22px; } }
    .card__btns--alone {
      margin-bottom: 0px; }
      @media (max-width: 1198.98px) {
        .card__btns--alone {
          flex-wrap: wrap;
          margin-bottom: -20px; }
          .card__btns--alone .btn {
            margin-bottom: 20px; } }
  @media (max-width: 1598.98px) {
    .card__mini-info {
      display: none; } }
  @media (max-width: 1598.98px) {
    .card__content {
      margin-bottom: 35px; } }
  .card__content--sm .card__text:not(:last-child) {
    margin-bottom: 8px; }
  .card__content--mb {
    margin-bottom: 13px; }

.mini-info {
  background: #F7F7F9;
  border: 1px solid #C2E7FF;
  border-radius: 4px;
  padding: 8px 15px 15px 10px;
  width: 110px; }
  .mini-info__title {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 16px;
    white-space: nowrap; }
  .mini-info__icons {
    display: flex;
    justify-content: space-between; }
  .mini-info__icon {
    width: 32px;
    height: 32px;
    display: block;
    object-fit: contain; }

.pagination {
  display: inline-block; }
  @media (max-width: 1198.98px) {
    .pagination {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center; } }
  .pagination--mb {
    margin-bottom: 40px; }
  .pagination__nav {
    margin-bottom: 44px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif; }
  .pagination__list {
    display: flex; }
  .pagination__arr {
    border-bottom: 1px solid; }
    .pagination__arr:hover {
      text-decoration: none; }
    .pagination__arr--left {
      margin-right: 36px; }
      .pagination__arr--left::before {
        content: '< '; }
    .pagination__arr--right::after {
      content: ' >'; }
  .pagination__item {
    margin-right: 20px;
    color: #81929B;
    transition: color 0.3s ease-in-out; }
    .pagination__item--active {
      color: #4AA6FA; }
      .pagination__item--active:hover {
        cursor: default;
        text-decoration: none; }

.chess {
  max-width: 100%;
  overflow-x: hidden; }
  .chess__title {
    margin-bottom: 60px; }
    @media (max-width: 1598.98px) {
      .chess__title {
        margin-bottom: 71px; } }
    @media (max-width: 1198.98px) {
      .chess__title {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 21px;
        line-height: 30px; } }
  .chess__img {
    width: 100%;
    position: relative;
    height: 560px;
    object-fit: cover; }
    .chess__img--left {
      right: 18%; }
      @media (max-width: 1598.98px) {
        .chess__img--left {
          right: 21%; } }
    .chess__img--right {
      left: 18%; }
      @media (max-width: 1598.98px) {
        .chess__img--right {
          left: 21%; } }
    @media (max-width: 1198.98px) {
      .chess__img {
        height: 260px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        right: 0;
        width: 800px;
        max-width: 100vw; } }
    @media (max-width: 1198.98px) {
      .chess__img--right-always, .chess__img--left-always {
        transform: none;
        width: 70vw;
        height: auto;
        position: relative;
        margin-bottom: 40px;
        display: block;
        max-width: 500px; } }
    @media (max-width: 1198.98px) {
      .chess__img--left-always {
        right: 20px;
        left: auto; } }
    @media (max-width: 1198.98px) {
      .chess__img--right-always {
        left: 20px;
        right: 0;
        margin-left: auto; } }
  .chess__content {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .chess__content a:not(.btn) {
      color: #4AA6FA; }
    .chess__content > p {
      margin-bottom: 32px; }
      .chess__content > p:last-child {
        margin-bottom: 0; }
    .chess__content--lg {
      font-size: 20px;
      line-height: 28px; }
  .chess__tabs .tabs-content-list {
    max-width: 400px; }
  .chess__link-btn {
    margin-top: 17px; }
  @media (max-width: 1198.98px) {
    .chess__img-col {
      position: static;
      height: 260px; } }
  @media (max-width: 1198.98px) {
    .chess__row-mobile-reverse {
      flex-direction: column-reverse; } }
  .chess__btns {
    flex-direction: row;
    flex-wrap: wrap; }
    @media (max-width: 1198.98px) {
      .chess__btns {
        padding-bottom: 25px; } }

.step-col {
  position: relative;
  display: flex;
  align-items: center;
  height: 200px;
  padding-left: 31px;
  padding-right: 31px; }
  .step-col--static {
    padding-top: 63px;
    padding-bottom: 58px;
    height: auto;
    align-items: flex-start;
    width: 100%; }
    @media (max-width: 1598.98px) {
      .step-col--static {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px; }
        .step-col--static .step-col__meta {
          left: 20px; } }
    @media (max-width: 799.98px) {
      .step-col--static {
        margin-left: -20px;
        margin-right: -20px;
        padding-bottom: 40px; } }
  .step-col--gray {
    background: #F5F5F5; }
  .step-col--lightblue {
    background: #EDF1F8; }
  .step-col__text:last-child {
    margin-bottom: 0; }
  .step-col__meta {
    position: absolute;
    left: 32px;
    top: 17px;
    font-size: 22px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #C2DEF0; }

.number-info {
  display: flex;
  align-items: center;
  min-height: 140px;
  margin-bottom: 60px; }
  @media (max-width: 1198.98px) {
    .number-info {
      margin-bottom: 40px;
      padding-right: 10px;
      min-height: 1px; } }
  .number-info__digin {
    margin-right: 40px;
    font-size: 100px;
    line-height: 122px;
    color: #C2DEF0;
    font-weight: 700; }
    @media (max-width: 1198.98px) {
      .number-info__digin {
        font-size: 75px;
        line-height: 91px; } }
  .number-info__text {
    max-width: 270px;
    flex-shrink: 0;
    font-size: 20px;
    line-height: 28px; }
    @media (max-width: 1198.98px) {
      .number-info__text {
        max-width: 66vw; } }

.page-select {
  margin: 0 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between; }
  @media (max-width: 1198.98px) {
    .page-select {
      flex-direction: column;
      align-items: flex-start; } }
  .page-select__item {
    margin-bottom: 40px; }
    .page-select__item--prev {
      margin-right: 10px;
      align-self: flex-end; }

.info-row:not(:last-child) {
  margin-bottom: 100px; }
  @media (max-width: 1598.98px) {
    .info-row:not(:last-child) {
      margin-bottom: 80px; } }
  @media (max-width: 1198.98px) {
    .info-row:not(:last-child) {
      margin-bottom: 60px; } }

.info-row__title {
  font-size: 64px;
  line-height: 78px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
  color: #C2DEF0; }
  @media (max-width: 1598.98px) {
    .info-row__title {
      font-size: 48px;
      line-height: 58.51px; } }
  @media (max-width: 1198.98px) {
    .info-row__title {
      font-size: 30px;
      line-height: 40px; } }

.info-row__content p:not(:last-child) {
  margin-bottom: 16px; }

.info-row__content ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0; }
  .info-row__content ul:not(:last-child) {
    margin-bottom: 16px; }
  .info-row__content ul li:not(:last-child) {
    margin-bottom: 16px; }
  .info-row__content ul li::before {
    content: '- '; }

.info-row__icon {
  width: 100%;
  height: auto; }
  @media (max-width: 1198.98px) {
    .info-row__icon {
      width: 65px;
      height: 65px;
      margin-bottom: 20px; } }

@media (max-width: 1198.98px) {
  .info-row {
    flex-direction: column-reverse; } }

.info-list {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0; }
  .info-list:not(:last-child) {
    margin-bottom: 16px; }
  .info-list__item:not(:last-child) {
    margin-bottom: 16px; }
  .info-list__item::before {
    content: '- '; }

.welcome-row {
  padding-bottom: 60px;
  min-height: 660px; }
  @media (max-width: 1198.98px) {
    .welcome-row {
      min-height: 1px;
      padding-bottom: 46px; }
      .welcome-row::after {
        content: ' ';
        z-index: 1;
        display: block;
        position: absolute;
        left: 0;
        top: 76px;
        width: 100%;
        height: 320px;
        background: black;
        background: linear-gradient(180deg, #24374E 0%, rgba(36, 55, 78, 0.4) 70%);
        transform: rotate(180deg); } }
  .welcome-row__content {
    padding-top: 80px;
    font-size: 20px;
    line-height: 28px; }
    @media (max-width: 1598.98px) {
      .welcome-row__content {
        padding-top: 90px; } }
    @media (max-width: 1198.98px) {
      .welcome-row__content {
        padding-top: 0; } }
    @media (max-width: 1198.98px) {
      .welcome-row__content-in {
        text-align: center;
        margin-top: 360px; } }
  .welcome-row__title {
    z-index: 2;
    font-size: 40px;
    line-height: 56px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 40px; }
    @media (max-width: 1598.98px) {
      .welcome-row__title {
        font-size: 30px;
        line-height: 36.57px; } }
    @media (max-width: 1598.98px) {
      .welcome-row__title {
        margin-bottom: 50px; } }
    @media (max-width: 1198.98px) {
      .welcome-row__title {
        display: block;
        color: white;
        position: absolute;
        text-align: center;
        left: 10px;
        right: 10px;
        top: 160px;
        transform: translateY(-50%); } }
  .welcome-row__image {
    height: 600px;
    position: absolute;
    right: 0;
    object-fit: cover;
    top: 140px;
    right: 0;
    width: 48.8vw; }
    @media (max-width: 1598.98px) {
      .welcome-row__image {
        height: 450px; } }
    @media (max-width: 1198.98px) {
      .welcome-row__image {
        height: 320px;
        left: 0;
        top: 76px;
        width: 100%; } }

.row-side {
  position: relative; }
  .row-side__btn {
    margin-bottom: 80px; }
    @media (max-width: 1198.98px) {
      .row-side__btn {
        margin-bottom: 40px; } }
  .row-side__content {
    font-size: 20px;
    line-height: 28px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400; }
    @media (max-width: 1198.98px) {
      .row-side__content {
        font-family: "Roobert", "Helvetica", Arial, sans-serif; } }
    .row-side__content p {
      margin-bottom: 60px; }
      @media (max-width: 1198.98px) {
        .row-side__content p {
          margin-bottom: 40px; } }
      .row-side__content p:last-child {
        margin-bottom: 0; }
    .row-side__content--mobile-sm {
      font-size: 16px;
      line-height: 24px; }
  .row-side__col {
    position: static; }
  .row-side__image {
    height: 636px;
    position: absolute;
    left: 50%;
    transform: translateX(16.2vw);
    width: 33.8vw;
    top: 122px;
    object-fit: cover; }
    @media (max-width: 1198.98px) {
      .row-side__image {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        margin-top: 60px;
        margin-bottom: 60px;
        width: 100%;
        height: auto; } }
    @media (max-width: 799.98px) {
      .row-side__image {
        width: 100vw;
        height: 280px;
        margin-left: -20px; } }

.special-date {
  font-size: 64px;
  line-height: 80px;
  font-family: "Montserrat", sans-serif;
  color: #C2DEF0;
  font-weight: 700; }
  @media (max-width: 1598.98px) {
    .special-date {
      font-size: 48px;
      line-height: 59px; } }
  @media (max-width: 1198.98px) {
    .special-date {
      font-size: 40px;
      line-height: 49px; } }

.alert {
  padding: 32px 15px;
  background: #C2DEF0;
  color: #305C7B;
  text-align: center;
  margin-top: 140px; }
  @media (max-width: 1198.98px) {
    .alert {
      margin-top: 75px;
      padding-top: 17px;
      padding-bottom: 17px;
      padding-right: 30px;
      padding-left: 30px; } }
  .alert__text {
    font-size: 22px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
    .alert__text:last-child {
      margin-bottom: 0; }
    @media (max-width: 1198.98px) {
      .alert__text {
        font-size: 16px;
        line-height: 30px; } }

.wide-textblock-slider {
  overflow-x: hidden; }
  .wide-textblock-slider .splide__track {
    overflow: unset; }
  .wide-textblock-slider__in {
    padding-top: 122px;
    position: relative; }
    @media (max-width: 1198.98px) {
      .wide-textblock-slider__in {
        padding-top: 65px; } }
  .wide-textblock-slider__control {
    height: 6px;
    background: #EAEAEA;
    position: absolute;
    left: 0;
    left: 10px;
    right: 10px;
    top: 12px; }
    .wide-textblock-slider__control-in {
      display: block;
      height: 12px;
      background: #C2DEF0;
      width: 10%;
      transition: width 0.3s ease-in-out;
      position: absolute;
      top: -3px;
      left: 0; }
  .wide-textblock-slider__title {
    margin-bottom: 66px; }
    @media (max-width: 1198.98px) {
      .wide-textblock-slider__title {
        margin-bottom: 30px; } }

.text-info__title {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600; }
  @media (max-width: 1198.98px) {
    .text-info__title {
      font-size: 16px;
      line-height: 30px; } }
  .text-info__title--lg {
    font-size: 40px;
    line-height: 56px;
    font-weight: 700;
    margin-bottom: 40px; }

.text-info__content--lg {
  font-size: 20px;
  line-height: 28px; }

.text-info__content p {
  margin-bottom: 15px; }
  .text-info__content p:last-child {
    margin-bottom: 0; }

.help-block {
  padding-top: 40px;
  padding-bottom: 44px;
  padding-left: 20px;
  padding-right: 20px;
  background: linear-gradient(262.78deg, #EAF3F9 10.66%, #FFFFFF 98.43%);
  box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05); }
  @media (max-width: 799.98px) {
    .help-block {
      margin-right: -20px;
      margin-left: -20px; } }
  .help-block__title {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
  .help-block__text {
    margin-bottom: 24px; }
  .help-block__icon {
    display: block;
    width: 37.5px;
    height: 37.5px;
    margin-left: auto;
    margin-right: auto; }

.col-text {
  margin-top: 73px;
  margin-bottom: 72px;
  position: relative; }
  @media (max-width: 1198.98px) {
    .col-text {
      margin-top: 55px; } }
  .col-text:not(:last-child)::after {
    content: ' ';
    display: block;
    position: absolute;
    width: 2px;
    height: 100%;
    background: #C6D5DD;
    border-radius: 2px;
    right: 0;
    top: 0; }
    @media (max-width: 1198.98px) {
      .col-text:not(:last-child)::after {
        height: 2px;
        left: 3vw;
        width: 88vw;
        bottom: -60px;
        right: unset;
        top: unset; } }
  .col-text__content p {
    margin-bottom: 20px; }
    .col-text__content p:last-child {
      margin-bottom: 0; }
  .col-text__title {
    font-size: 28px;
    line-height: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 40px; }
    @media (max-width: 1198.98px) {
      .col-text__title {
        font-size: 22px;
        line-height: 30px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600; } }

.human-info {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media (max-width: 1198.98px) {
    .human-info {
      padding-top: 50px;
      padding-bottom: 37px; } }
  .human-info__title {
    font-size: 64px;
    line-height: 78px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 0; }
    @media (max-width: 1598.98px) {
      .human-info__title {
        font-size: 48px;
        line-height: 58.51px; } }
    @media (max-width: 1198.98px) {
      .human-info__title {
        font-size: 30px;
        line-height: 37px; } }
  .human-info__subtitle {
    font-size: 40px;
    line-height: 56px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 0; }
    @media (max-width: 1598.98px) {
      .human-info__subtitle {
        font-size: 30px;
        line-height: 36.57px; } }
    .human-info__subtitle--mb {
      margin-bottom: 24px; }
      @media (max-width: 1198.98px) {
        .human-info__subtitle--mb {
          font-size: 21px;
          line-height: 30px; } }

.value-info {
  padding-top: 55px;
  padding-right: 60px;
  margin-bottom: 60px; }
  @media (max-width: 1198.98px) {
    .value-info {
      padding-top: 35px;
      margin-bottom: 35px;
      padding-right: 0; } }
  .value-info__number {
    color: #C2DEF0;
    font-size: 200px;
    line-height: 1;
    margin-bottom: 40px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif; }
    @media (max-width: 1198.98px) {
      .value-info__number {
        font-size: 64px;
        margin-bottom: 25px; } }
  .value-info__title {
    margin-bottom: 40px;
    font-size: 28px;
    line-height: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
    @media (max-width: 1598.98px) {
      .value-info__title {
        font-size: 21px;
        line-height: 36px; } }
    @media (max-width: 1598.98px) {
      .value-info__title {
        font-size: 22px;
        line-height: 30px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600; } }
  .value-info__content {
    font-size: 20px;
    line-height: 28px; }
    @media (max-width: 1198.98px) {
      .value-info__content {
        font-size: 16px;
        line-height: 24px; } }
    .value-info__content p {
      margin-bottom: 20px; }
      .value-info__content p:last-child {
        margin-bottom: 0; }

.form-check {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }
  .form-check__input {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    z-index: -1;
    visibility: hidden; }
    .form-check__input:not(:checked) + .form-check__caption--error::before {
      border-color: #E12626; }
    .form-check__input:checked + .form-check__caption::before {
      background: url(../images/sprites/main.stack.svg#image-check-primary);
      background-position: center center;
      background-size: 13px;
      background-repeat: no-repeat; }
  .form-check__caption {
    margin-left: 40px;
    margin-bottom: 0;
    position: relative;
    margin-bottom: 16px; }
    .form-check__caption::before {
      content: ' ';
      position: absolute;
      display: block;
      left: -40px;
      top: 1px;
      width: 20px;
      height: 20px;
      border: 2px solid #4AA6FA;
      box-sizing: border-box;
      border-radius: 4px; }
    .form-check__caption--sm {
      margin-left: 30px; }
      .form-check__caption--sm::before {
        left: -30px; }
    .form-check__caption a {
      color: #4AA6FA; }
  .form-check--radio .form-check__caption::before {
    border-radius: 50%; }
  .form-check--radio .form-check__input:checked + .form-check__caption::before {
    background: url(../images/sprites/main.stack.svg#image-circle-primary);
    background-position: center center;
    background-size: 12px;
    background-repeat: no-repeat; }

.form-control {
  width: 100%;
  border-radius: 10px;
  padding: 5px 10px 6px;
  position: relative;
  transition: background 0.2s ease-in-out;
  margin-left: -10px; }
  .form-control__caption {
    display: block;
    margin-bottom: 16px; }
    .form-control__caption-detail {
      font-size: 80%;
      color: #81929B; }
  .form-control__select-content {
    opacity: 0;
    visibility: hidden;
    transform: translateY(105%);
    transition: all 0.2s ease-in-out;
    z-index: -1;
    display: flex;
    align-items: center;
    background: white;
    padding: 18px 20px;
    position: absolute;
    bottom: 0;
    left: 10px;
    border: 1px solid #C2E7FF;
    max-width: 100%;
    max-width: 360px;
    flex-wrap: wrap; }
    @media (max-width: 1198.98px) {
      .form-control__select-content {
        width: 222px;
        bottom: 40px; } }
  .form-control__select-input {
    display: block;
    padding: 0;
    border: none;
    background: transparent;
    width: 100%;
    border-bottom: 1px solid #eaeaea;
    flex-shrink: 0;
    margin-bottom: 10px;
    padding-bottom: 5px; }
    .form-control__select-input:focus {
      outline: none; }
  .form-control__select-item {
    min-width: 54px;
    text-align: center;
    padding: 0 12px;
    border-radius: 20px;
    background: #F7F7F9;
    border: none;
    margin-right: 20px;
    margin-bottom: 15px;
    transition: background 0.2s ease-in-out;
    flex-shrink: 0; }
    @media (max-width: 1198.98px) {
      .form-control__select-item {
        margin-bottom: 15px; } }
    .form-control__select-item:focus {
      outline: none; }
    .form-control__select-item:hover, .form-control__select-item:focus {
      background: #C2E7FF; }
    .form-control__select-item--active {
      background: #C2E7FF; }
      .form-control__select-item--active:hover, .form-control__select-item--active:focus {
        background: #C2E7FF; }
  .form-control__select-wrap {
    width: 100%;
    display: block;
    margin-top: 15px; }
  .form-control__select-caption {
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #81929B;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    margin: 0;
    flex-shrink: 0;
    min-width: 110px; }
    @media (max-width: 1198.98px) {
      .form-control__select-caption {
        width: 100%; } }
  .form-control--select-active .form-control__select-content {
    opacity: 1;
    visibility: visible;
    z-index: 3;
    transform: translateY(100%); }
  .form-control:not(.form-control--static):hover {
    background: #EAEAEA; }
  .form-control:not(.form-control--static):focus-within {
    background: #DFDFDF; }
  .form-control__check-list {
    display: flex;
    align-items: center; }
    .form-control__check-list > span {
      margin-left: 12px;
      margin-right: 12px; }
  .form-control__feedback {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    margin: 0;
    padding-right: 10px; }
    .form-control__feedback--select {
      position: static;
      transform: none;
      margin-bottom: 10px; }
    .form-control__feedback a {
      color: #4AA6FA; }
    @media (max-width: 1198.98px) {
      .form-control__feedback {
        bottom: -2px;
        font-size: 14px;
        line-height: 1.2; } }
  .form-control__icon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
    top: 47px; }
    .form-control__icon--pass {
      background: url(../images/icons/eye.png);
      background-size: cover; }
      .form-control__icon--pass-active {
        background: url(../images/icons/eye-open.png);
        background-size: cover;
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: center center; }
  .form-control__label {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    display: block; }
  .form-control__input {
    display: block;
    padding: 0;
    border: none;
    background: transparent;
    width: 100%; }
    .form-control__input--with-icon {
      margin-left: 30px; }
    .form-control__input:focus {
      outline: none; }
    .form-control__input:placeholder {
      color: #81929B;
      opacity: 1; }

/**************************\
 *  Basic Modal Styles
 *\************************* */
.modal {
  position: relative;
  z-index: 999; }
  .modal__feedback {
    margin-bottom: 35px; }
  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: flex-start; }
    @media (min-width: 1199px) {
      .modal__overlay {
        overflow-y: auto; } }
  .modal__step {
    display: none; }
    .modal__step--active {
      display: block; }
  .modal__split-row--mt {
    margin-top: 55px; }
  .modal__split-row:not(.no-gutters) {
    margin-left: -10px;
    margin-left: -10px; }
    .modal__split-row:not(.no-gutters) > .col,
    .modal__split-row:not(.no-gutters) > [class*="col-"] {
      padding-right: 10px;
      padding-left: 10px; }
  .modal__split-row > [class*="col-"] {
    position: relative; }
    .modal__split-row > [class*="col-"]::after {
      content: ' ';
      position: absolute;
      right: 8px;
      width: 2px;
      height: 60px;
      top: 10px;
      border-radius: 2px;
      background: #C6D5DD;
      display: block; }
    .modal__split-row > [class*="col-"]:nth-child(3n)::after {
      content: none; }
    @media (max-width: 1198.98px) {
      .modal__split-row > [class*="col-"]::after {
        content: none; }
      .modal__split-row > [class*="col-"]:nth-child(3n)::after {
        content: ' ';
        position: static;
        margin-top: 5px;
        margin-bottom: 32px;
        height: 2px;
        width: 100%; } }
  .modal__split-row--two-row > [class*="col-"]:nth-child(2n)::after {
    content: none; }
  @media (max-width: 1198.98px) {
    .modal__split-row--no-mobile > [class*="col-"]::after {
      content: none !important; } }
  .modal__container {
    background-color: #F7F7F9;
    padding: 60px;
    max-width: 680px;
    width: 100%;
    border-radius: 4px;
    margin-top: 140px;
    margin-bottom: 50px;
    position: relative; }
    .modal__container--sm {
      padding: 40px; }
    @media (max-width: 1198.98px) {
      .modal__container {
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 116px;
        background-color: #F7F7F9;
        min-height: 100vh;
        padding-top: 109px; } }
    @media (max-width: 1198.98px) {
      .modal__container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
        padding-left: 0;
        padding-right: 0;
        padding-top: 84px;
        padding-bottom: 0; } }
  .modal__info-card {
    margin-bottom: 60px;
    margin-top: 0; }
    @media (max-width: 1198.98px) {
      .modal__info-card {
        margin-bottom: 40px;
        margin-top: 8px; } }
  .modal__btns {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -40px; }
    .modal__btns .btn {
      margin-right: 10px;
      margin-bottom: 40px; }
    .modal__btns--lg .btn {
      margin-right: 32px;
      margin-bottom: 32px; }
  .modal__actions {
    display: flex; }
    @media (max-width: 1198.98px) {
      .modal__actions {
        flex-direction: column; } }
    .modal__actions .card-caption-link {
      flex: 1; }
      .modal__actions .card-caption-link:not(:last-child) {
        margin-right: 40px; }
        @media (max-width: 1198.98px) {
          .modal__actions .card-caption-link:not(:last-child) {
            margin-right: 0;
            margin-bottom: 40px; } }
  .modal__cta-text {
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
    .modal__cta-text--extend {
      margin-bottom: 0; }
      @media (min-width: 1199px) {
        .modal__cta-text--extend {
          margin-top: -20px; } }
  .modal__cta-icon {
    margin-top: 35px;
    margin-bottom: 35px;
    width: 40px;
    height: 40px;
    font-size: 22px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
  .modal__header {
    margin-bottom: 35px; }
    .modal__header + .modal__content .modal__content-in {
      padding-top: 30px; }
    @media (max-width: 1198.98px) {
      .modal__header {
        background: white;
        position: absolute;
        width: auto;
        top: 0;
        left: 0;
        right: 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 24px;
        padding-bottom: 24px;
        box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05); } }
    .modal__header--desk-hidden {
      display: none; }
      @media (max-width: 1198.98px) {
        .modal__header--desk-hidden {
          display: block; } }
      .modal__header--desk-hidden + .modal__content .modal__content-in {
        padding-top: 0; }
  .modal__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 28px;
    line-height: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
    @media (max-width: 1198.98px) {
      .modal__title {
        font-size: 20px; } }
  .modal__prev-step {
    background: transparent;
    border: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    top: -100px;
    left: 0;
    background-color: white;
    z-index: 5; }
    .modal__prev-step:focus {
      outline: none; }
    .modal__prev-step::before, .modal__prev-step::after {
      content: ' ';
      width: 18px;
      height: 18px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      transition: opacity 0.2s ease-in-out; }
    .modal__prev-step::before {
      background: url(../images/sprites/main.stack.svg#image-modal-back);
      background-size: contain;
      opacity: 1; }
    .modal__prev-step::after {
      background: url(../images/sprites/main.stack.svg#image-modal-back-primary);
      background-size: contain;
      opacity: 0; }
    .modal__prev-step:hover, .modal__prev-step:focus {
      color: #4AA6FA; }
      .modal__prev-step:hover::before, .modal__prev-step:focus::before {
        opacity: 0; }
      .modal__prev-step:hover::after, .modal__prev-step:focus::after {
        opacity: 1; }
    @media (max-width: 1198.98px) {
      .modal__prev-step {
        position: relative;
        background: none;
        margin-top: -20px;
        top: 0;
        left: -20px;
        display: flex;
        align-items: center;
        width: auto; }
        .modal__prev-step::before, .modal__prev-step::after {
          left: 15px;
          transform: translateY(-50%); } }
    .modal__prev-step-caption {
      display: none;
      transition: color 0.2s ease-in-out;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      text-transform: uppercase; }
      @media (max-width: 1198.98px) {
        .modal__prev-step-caption {
          display: inline-block;
          padding-left: 35px; } }
  .modal__close {
    background: transparent;
    border: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    top: -100px;
    right: 0;
    background-color: white;
    z-index: 5; }
    .modal__close:focus {
      outline: none; }
    .modal__close::before, .modal__close::after {
      content: ' ';
      width: 18px;
      height: 18px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      transition: opacity 0.2s ease-in-out; }
    .modal__close::before {
      background: url(../images/sprites/main.stack.svg#image-modal-close);
      background-size: contain;
      opacity: 1; }
    .modal__close::after {
      background: url(../images/sprites/main.stack.svg#image-modal-close-primary);
      background-size: contain;
      opacity: 0; }
    .modal__close:hover::before, .modal__close:focus::before {
      opacity: 0; }
    .modal__close:hover::after, .modal__close:focus::after {
      opacity: 1; }
    @media (max-width: 1198.98px) {
      .modal__close {
        top: 8px;
        right: 0; } }
  .modal__content {
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8); }
    @media (max-width: 1198.98px) {
      .modal__content {
        position: relative;
        width: 100%;
        height: 100%;
        flex-grow: 1; } }
    @media (max-width: 1198.98px) {
      .modal__content-in {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 25px !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        padding-bottom: 140px; } }
  .modal__text {
    margin-bottom: 35px; }
  .modal__form-control {
    margin-bottom: 54px; }
    .modal__form-control--mb-md {
      margin-bottom: 44px; }
    @media (max-width: 1198.98px) {
      .modal__form-control {
        margin-bottom: 25px; } }
    @media (max-width: 1198.98px) {
      .modal__form-control--mobile-mb-md {
        margin-bottom: 49px; } }
  .modal__check-list {
    margin-top: 35px; }
    @media (max-width: 1198.98px) {
      .modal__check-list {
        margin-top: 5px;
        margin-bottom: 20px; } }
  .modal__form-check {
    margin-bottom: 58px; }
    .modal__form-check--mb-sm {
      margin-bottom: 24px; }
    .modal__form-check--mb-md {
      margin-bottom: 40px; }
  .modal__form-send--mb {
    margin-bottom: 40px; }
  .modal__copyright {
    font-size: 12px;
    line-height: 18px; }
    .modal__copyright a {
      color: #4AA6FA; }
  .modal__form {
    min-height: 178px; }

/**************************\
 *  Demo Animation Style
 *\************************* */
@keyframes mmfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes mmfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes mmslideIn {
  from {
    transform: translateY(15%); }
  to {
    transform: translateY(0); } }

@keyframes mmslideOut {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-10%); } }

.micromodal-slide {
  display: none; }
  .micromodal-slide.is-open {
    display: block; }
  .micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .micromodal-slide .modal__container, .micromodal-slide .modal__overlay {
    will-change: transform; }

.feedback {
  padding: 10px 20px;
  display: inline-block; }
  .feedback--danger {
    background: rgba(225, 38, 38, 0.1);
    color: #E12626; }
  .feedback p {
    margin-bottom: 10px; }
    .feedback p:last-child {
      margin-bottom: 0px; }

.search-fail--mb-lg {
  margin-bottom: 98px; }
  @media (max-width: 1198.98px) {
    .search-fail--mb-lg {
      margin-bottom: 37px; } }

.search-fail__image {
  width: 160px;
  height: 160px;
  display: block;
  margin-bottom: 45px; }
  @media (max-width: 1198.98px) {
    .search-fail__image {
      margin-bottom: 40px; } }

.search-fail__text {
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600; }
  .search-fail__text:last-child {
    margin-bottom: 0; }

.badge {
  background: #C2DEF0;
  color: #222222;
  font-size: 13.5px;
  line-height: 17px;
  padding: 6px 12px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Montserrat", sans-serif; }

.line-link-list .line-link {
  margin-bottom: 140px; }
  @media (max-width: 1198.98px) {
    .line-link-list .line-link {
      margin-bottom: 61px; } }

.line-link {
  color: #222222; }
  .line-link:hover {
    text-decoration: none;
    color: #222222; }
  .line-link:focus {
    outline: none; }
  .line-link:focus .line-link__caption::before, .line-link:hover .line-link__caption::before {
    border-color: #4AA6FA; }
  .line-link:focus .line-link__caption::after, .line-link:hover .line-link__caption::after {
    opacity: 1;
    transform: none; }
  .line-link__caption {
    margin: 10px 0 0 0;
    display: block;
    width: 100%;
    font-size: 40px;
    line-height: 56px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-weight: 600; }
    .line-link__caption::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 85px;
      bottom: 75px;
      left: 0;
      right: 0;
      border: 2px solid #C6D5DD;
      transition: border-color 0.3s ease-in-out;
      z-index: 2; }
      @media (max-width: 1899.98px) {
        .line-link__caption::before {
          top: 45px;
          bottom: 25px; } }
      @media (max-width: 1198.98px) {
        .line-link__caption::before {
          top: 20px;
          bottom: 10px; } }
    @media (max-width: 1899.98px) {
      .line-link__caption {
        font-size: 28px;
        line-height: 36px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600; } }
    @media (max-width: 1198.98px) {
      .line-link__caption {
        font-size: 16px;
        line-height: 26px; } }
    .line-link__caption::after {
      content: '>';
      display: block;
      position: absolute;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 10.5px;
      padding-top: 10.5px;
      left: 14%;
      bottom: 56px;
      background: white;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      border: 2px solid #4AA6FA;
      box-sizing: border-box;
      border-radius: 32px;
      z-index: 3;
      color: #4AA6FA;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      transform: translateX(-100px);
      opacity: 0; }
      @media (max-width: 1899.98px) {
        .line-link__caption::after {
          transform: translateX(-50px);
          bottom: 6px; } }
      @media (max-width: 1598.98px) {
        .line-link__caption::after {
          transform: translateX(-10px); } }
      @media (max-width: 1198.98px) {
        .line-link__caption::after {
          bottom: -10px; } }
    .line-link__caption--left {
      margin-left: 12.6%; }
      .line-link__caption--left::before {
        right: -65px;
        left: 20px; }
        @media (max-width: 1198.98px) {
          .line-link__caption--left::before {
            left: 10px; } }
      @media (max-width: 1198.98px) {
        .line-link__caption--left {
          text-align: right; } }
    .line-link__caption--right {
      margin-right: 12.6%;
      text-align: right; }
      .line-link__caption--right::before {
        left: -65px;
        right: 20px; }
        @media (max-width: 1198.98px) {
          .line-link__caption--right::before {
            right: 10px; } }
      @media (max-width: 1198.98px) {
        .line-link__caption--right {
          text-align: left; } }
      .line-link__caption--right::after {
        content: '>';
        transform: translateX(100px);
        left: unset;
        right: 14%; }
        @media (max-width: 1899.98px) {
          .line-link__caption--right::after {
            transform: translateX(50px); } }
        @media (max-width: 1598.98px) {
          .line-link__caption--right::after {
            transform: translateX(10px); } }
    .line-link__caption-wrap {
      display: flex;
      align-items: center; }
  .line-link__thumbnail {
    width: 100%;
    height: 360px;
    object-fit: cover;
    position: relative;
    z-index: 3;
    box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.12); }
    @media (max-width: 1899.98px) {
      .line-link__thumbnail {
        height: 270px; } }
    @media (max-width: 1198.98px) {
      .line-link__thumbnail {
        height: 132px; } }

.card-icon-info {
  padding: 60px 60px 100px 60px;
  background: #F7F7F9;
  margin-bottom: 40px;
  position: relative; }
  @media (max-width: 1598.98px) {
    .card-icon-info {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 30px;
      padding-bottom: 40px; } }
  @media (min-width: 1199px) {
    .card-icon-info__row {
      margin-left: -60px;
      margin-right: -60px; } }
  .card-icon-info--lg {
    padding-top: 40px; }
  .card-icon-info__title {
    font-size: 28px;
    line-height: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 40px; }
    @media (min-width: 1199px) {
      .card-icon-info__title--mb {
        margin-bottom: 55px; } }
  .card-icon-info__content {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 28px; }
    @media (max-width: 1899.98px) {
      .card-icon-info__content {
        margin-bottom: 50px; } }
    .card-icon-info__content p {
      margin-bottom: 0; }
      .card-icon-info__content p:not(:last-child) {
        margin-bottom: 20px; }
    .card-icon-info__content--lg {
      margin-bottom: 62px; }
      @media (max-width: 1598.98px) {
        .card-icon-info__content--lg {
          margin-bottom: 40px; } }
  .card-icon-info__icon {
    position: absolute;
    width: 60px;
    height: 60px;
    display: block;
    right: 30px;
    top: 30px; }
    @media (max-width: 1598.98px) {
      .card-icon-info__icon {
        position: static;
        margin-bottom: 40px; } }
    @media (max-width: 1598.98px) {
      .card-icon-info__icon--mb-sm {
        margin-bottom: 30px; } }
  .card-icon-info__btn {
    position: absolute;
    left: 60px;
    bottom: 48px; }
    @media (max-width: 1598.98px) {
      .card-icon-info__btn {
        position: static;
        transform: none; } }
    .card-icon-info__btn--center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%); }
      @media (max-width: 1598.98px) {
        .card-icon-info__btn--center {
          bottom: 30px; } }
      @media (max-width: 1198.98px) {
        .card-icon-info__btn--center {
          position: static;
          transform: none; } }

.search-box {
  position: relative; }
  .search-box--link {
    display: block; }
    .search-box--link .search-box__input {
      pointer-events: none; }
  .search-box--mb {
    margin-bottom: 100px; }
    @media (max-width: 1198.98px) {
      .search-box--mb {
        margin-bottom: 40px; } }
  .search-box__content {
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: -1;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 40px;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    pointer-events: none; }
    @media (max-width: 1198.98px) {
      .search-box__content {
        max-height: 80vh;
        padding: 20px; } }
  .search-box--expand {
    cursor: pointer;
    position: relative; }
    .search-box--expand-open .search-box__content {
      opacity: 1;
      visibility: visible;
      z-index: 3;
      transform: none;
      pointer-events: auto; }
  @media (max-width: 1198.98px) {
    .search-box {
      position: fixed;
      top: 95px;
      z-index: 5;
      width: 100%;
      max-width: 760px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05); } }
  @media (max-width: 799.98px) {
    .search-box {
      left: 20px;
      right: 20px;
      width: auto; } }
  .search-box--static {
    position: relative;
    top: 0; }
  .search-box__close {
    width: 24px;
    height: 24px;
    right: 40px;
    top: 40px;
    position: absolute;
    display: block;
    background: url(../images/sprites/main.stack.svg#image-close-expand);
    background-repeat: no-repeat;
    border: none;
    transition: opacity 0.2s ease-in-out; }
    .search-box__close:focus, .search-box__close:hover {
      outline: none;
      opacity: 0.5; }
    @media (max-width: 1198.98px) {
      .search-box__close {
        top: 26px;
        right: 22px; } }
  .search-box__control {
    margin-bottom: 27px;
    position: relative; }
    @media screen and (max-height: 570px) {
      .search-box__control {
        margin-bottom: 20px; } }
    .search-box__control::after {
      content: ' ';
      position: absolute;
      display: block;
      width: 60px;
      height: 1px;
      left: 10px;
      bottom: -15px;
      background: #eaeaea; }
  .search-box__apply {
    margin-top: 48px; }
    @media (max-width: 1598.98px) {
      .search-box__apply {
        margin-top: 30px; } }
  .search-box__input {
    font-size: 22px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-overflow: ellipsis;
    padding: 35px 80px 35px 40px;
    background: #FFFFFF;
    width: 100%;
    line-height: 1;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    border: none; }
    .search-box__input:focus {
      outline: none; }
    @media (max-width: 1198.98px) {
      .search-box__input {
        font-size: 16px;
        line-height: 24px; } }
    .search-box__input--sm {
      font-size: 16px;
      line-height: 24px;
      font-weight: normal; }
      @media (max-width: 1198.98px) {
        .search-box__input--sm {
          padding-top: 8px;
          padding-bottom: 8px; } }
    .search-box__input--prepand-icon {
      padding-left: 100px;
      padding-right: 40px; }
      @media (max-width: 1198.98px) {
        .search-box__input--prepand-icon {
          padding-left: 60px;
          padding-right: 20px; } }
  .search-box__btn {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    border: none;
    padding: 0;
    background: #4AA6FA;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out; }
    .search-box__btn:hover, .search-box__btn:focus {
      outline: none;
      background: #72B5F3; }
    .search-box__btn--sm {
      width: 20px;
      height: 20px;
      background: transparent;
      right: unset;
      left: 40px;
      top: 37px;
      transform: none; }
      .search-box__btn--sm:hover, .search-box__btn--sm:focus {
        background: transparent; }
      @media (max-width: 1198.98px) {
        .search-box__btn--sm {
          top: 10px;
          left: 20px; } }
    .search-box__btn--opacity-hover:hover, .search-box__btn--opacity-hover:focus {
      opacity: 0.5; }
  .search-box__icon {
    width: 24px;
    display: block;
    height: 24px; }
    .search-box__icon--sm {
      width: 20px;
      height: 20px; }

.article-list .article {
  margin-bottom: 60px; }
  @media (max-width: 1198.98px) {
    .article-list .article {
      margin-bottom: 40px; } }

.article-list .pagination {
  margin-top: 30px; }
  @media (max-width: 1198.98px) {
    .article-list .pagination {
      margin-top: 75px; } }

.article {
  position: relative;
  background: white;
  box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.04); }
  @media (max-width: 799.98px) {
    .article {
      margin-left: -20px;
      margin-right: -20px; } }
  .article__body {
    padding: 60px 120px 130px;
    position: relative; }
    @media (max-width: 1198.98px) {
      .article__body {
        padding-top: 39px;
        padding-left: 20px;
        padding-right: 20px; } }
  .article__title {
    font-size: 22px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 20px; }
    @media (max-width: 1198.98px) {
      .article__title {
        margin-bottom: 32px; } }
  .article__meta {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase;
    line-height: 17px;
    margin-bottom: 20px; }
    @media (max-width: 1198.98px) {
      .article__meta {
        margin-bottom: 32px; } }
  .article__thumbnail {
    width: 100%;
    height: 500px;
    display: block;
    object-fit: cover; }
    @media (max-width: 1198.98px) {
      .article__thumbnail {
        height: 360px; } }
    @media (max-width: 799.98px) {
      .article__thumbnail {
        height: 240px; } }
  .article__more {
    display: none; }
  .article__media {
    position: absolute;
    left: 120px;
    bottom: 46px;
    height: 24px;
    display: flex; }
    @media (max-width: 1198.98px) {
      .article__media {
        left: 20px; } }
    .article__media-link {
      display: block;
      margin-right: 20px;
      transition: transform 0.2s ease-in-out; }
      .article__media-link:hover, .article__media-link:focus {
        outline: none;
        transform: translateY(-4px); }
    .article__media-img {
      width: 24px;
      height: 24px; }
  .article__content {
    font-size: 20px;
    line-height: 28px; }
    .article__content-more {
      display: none; }
    .article__content p {
      margin-bottom: 20px; }

.info-circle {
  display: flex;
  align-items: center;
  padding-right: 20px; }
  .info-circle--mb {
    margin-bottom: 100px; }
    @media (max-width: 1198.98px) {
      .info-circle--mb {
        margin-bottom: 20px; } }
  .info-circle__icon-wrap {
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    background: #F7F7F9;
    flex-shrink: 0; }
    @media (max-width: 799.98px) {
      .info-circle__icon-wrap {
        width: 40px;
        height: 40px; } }
  .info-circle__icon {
    width: 48px;
    height: 48px; }
    @media (max-width: 799.98px) {
      .info-circle__icon {
        height: 16px;
        width: 16px; } }
  .info-circle__text {
    margin: 0;
    font-size: 20px;
    line-height: 28px; }
    @media (max-width: 799.98px) {
      .info-circle__text {
        margin-top: 20px; } }

.event-card {
  min-height: 300px;
  position: relative;
  margin-bottom: 60px;
  background: white;
  display: flex; }
  @media (max-width: 799.98px) {
    .event-card {
      flex-direction: column;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 50px; } }
  .event-card__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    max-width: 70%; }
    @media (max-width: 1598.98px) {
      .event-card__body {
        max-width: 75%; } }
    @media (max-width: 799.98px) {
      .event-card__body {
        padding: 40px 20px 36px; } }
  .event-card__thumbnail {
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
    height: 238px;
    object-fit: cover; }
    @media (max-width: 1598.98px) {
      .event-card__thumbnail {
        width: 133px;
        height: 158px; } }
    @media (max-width: 799.98px) {
      .event-card__thumbnail {
        width: 100%;
        height: 240px;
        position: static; } }
  .event-card__meta {
    position: absolute;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase;
    left: 40px;
    top: 0;
    transform: translateY(-50%);
    background: #C2DEF0;
    padding: 6px 12px 5px; }
    @media (max-width: 799.98px) {
      .event-card__meta {
        display: none; } }
  .event-card__title {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
  .event-card__content {
    margin-top: 20px;
    margin-bottom: 20px; }
    .event-card__content p {
      margin-bottom: 10px; }
      .event-card__content p:last-child {
        margin-bottom: 0; }

.course-list__item:not(:last-child) {
  margin-bottom: 43px; }

.course-item {
  display: flex; }
  .course-item--single {
    display: block;
    padding: 0 0 0 60px;
    width: 100%;
    background: none;
    border: none;
    position: relative;
    transition: color 0.2s ease-in-out;
    text-align: left; }
    .course-item--single::before, .course-item--single::after {
      content: ' ';
      display: block;
      width: 40px;
      height: 20px;
      background: #F7F7F9;
      position: absolute;
      left: 0;
      top: 4px;
      border-radius: 4px;
      transition: opacity 0.2s ease-in-out;
      opacity: 1; }
    .course-item--single::before {
      background: url(../images/sprites/main.stack.svg#image-arr-course-default);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
    .course-item--single::after {
      background: url(../images/sprites/main.stack.svg#image-arr-course-primary);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 0; }
    .course-item--single:focus {
      outline: none; }
    .course-item--single:hover, .course-item--single:focus {
      color: #4AA6FA; }
      .course-item--single:hover::before, .course-item--single:focus::before {
        opacity: 0; }
      .course-item--single:hover::after, .course-item--single:focus::after {
        opacity: 1; }
  .course-item__thumbnail {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-top: 5px;
    border-radius: 4px;
    margin-right: 20px; }
  .course-item__content {
    flex: 1;
    display: flex;
    flex-direction: column; }
  .course-item__type {
    color: #81929B;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 17px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-bottom: 12px; }
  .course-item__name {
    padding: 0;
    border: none;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    background: none;
    transition: color 0.2s ease-in-out; }
    .course-item__name:not(:last-child) {
      margin-bottom: 12px; }
    .course-item__name:focus {
      outline: none; }
    .course-item__name:hover {
      color: #4AA6FA; }

.footer {
  padding-top: 60px;
  padding-bottom: 70px;
  background: linear-gradient(180deg, #1C3B48 0%, #0E232C 100%);
  color: white;
  position: relative;
  z-index: 3; }
  @media (max-width: 1198.98px) {
    .footer {
      padding-bottom: 49px; } }
  @media (max-width: 799.98px) {
    .footer {
      padding-top: 40px;
      padding-bottom: 53px; } }
  .footer__container {
    max-width: 1715px; }
    @media (max-width: 1899.98px) {
      .footer__container {
        max-width: 1440px; } }
    @media (max-width: 1598.98px) {
      .footer__container {
        max-width: 1040px; } }
    @media (min-width: 1199px) and (max-width: 1598.98px) {
      .footer__container {
        max-width: none;
        width: auto;
        margin-left: 100px;
        margin-right: 100px; } }
    @media (min-width: 1599px) and (max-width: 1899.98px) {
      .footer__container {
        max-width: none;
        width: auto;
        margin-left: 100px;
        margin-right: 100px; } }
  .footer__logo {
    max-width: 200px;
    height: auto; }
    @media (max-width: 1198.98px) {
      .footer__logo {
        max-width: 120px;
        margin-bottom: 80px; } }
    @media (max-width: 799.98px) {
      .footer__logo {
        margin-bottom: 10px; } }
  .footer__menu {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 2px;
    margin-right: 50px; }
    @media (max-width: 1198.98px) {
      .footer__menu {
        margin-right: 0;
        margin-bottom: 79px; } }
    @media (max-width: 799.98px) {
      .footer__menu {
        flex-wrap: wrap;
        justify-content: space-between; } }
  .footer__col {
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    flex-direction: column; }
    @media (max-width: 1198.98px) {
      .footer__col {
        margin-left: 40px;
        margin-right: 40px; } }
    @media (max-width: 799.98px) {
      .footer__col {
        margin-left: 0;
        margin-right: 14px;
        margin-top: 62px; } }
  .footer__link {
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase; }
    .footer__link:not(:last-child) {
      margin-bottom: 20px; }
  .footer__in {
    display: flex;
    justify-content: space-between; }
    .footer__in:not(:last-child) {
      margin-bottom: 14px; }
      @media (max-width: 1198.98px) {
        .footer__in:not(:last-child) {
          margin-bottom: 10px; } }
    .footer__in--end {
      align-items: flex-end; }
    @media (max-width: 1198.98px) {
      .footer__in {
        flex-direction: column;
        align-items: center; } }
    @media (max-width: 799.98px) {
      .footer__in--start {
        align-items: flex-start; } }
  .footer__contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .footer__contact-link {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      text-transform: uppercase;
      color: white; }
      .footer__contact-link[href] {
        border-bottom: 1px solid; }
        .footer__contact-link[href]:hover {
          text-decoration: none; }
      .footer__contact-link:not(:last-child) {
        margin-bottom: 16px; }
      @media (max-width: 1198.98px) {
        .footer__contact-link {
          display: none; } }
  .footer__copyright {
    text-align: right;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase;
    margin: 0;
    font-size: 11px;
    line-height: 13px;
    position: relative;
    top: 10px; }
    @media (max-width: 1198.98px) {
      .footer__copyright {
        text-align: center; } }
  @media (max-width: 799.98px) {
    .footer__resource {
      width: 100%; } }

@media (min-width: 1199px) {
  body.body-home {
    background: url(../images/bg/bubbles-pattern.webp);
    background-size: contain;
    background-repeat: no-repeat; }
  .no-webp body.body-home {
    background: url(../images/bg/bubbles-pattern.jpg);
    background-size: contain;
    background-repeat: no-repeat; } }
