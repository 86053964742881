.mini-info {
  background: $bg-gray;
  border: 1px solid #C2E7FF;
  border-radius: 4px;
  padding: 8px 15px 15px 10px;
  width: 110px;
  &__title {
    @include small-caps;
    font-size: 11px;
    margin-bottom: 16px;
    white-space: nowrap; }
  &__icons {
    display: flex;
    justify-content: space-between; }
  &__icon {
    width: 32px;
    height: 32px;
    display: block;
    object-fit: contain; } }
