.row-detail-view {
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse; }
  &__main-col {
    padding-top: 40px;
    padding-bottom: 40px;
    @include media-breakpoint-down(sm) {
      padding-top: 0; } } }

.row-right-blue {
  position: relative;
  &::after {
    content:  ' ';
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    left: 51.2%;
    width: 50vw;
    background: #EDF1F8;
    z-index: -1;
    @include media-breakpoint-down(sm) {
      content: none; } } }


.detail-view {
  position: sticky;
  top: 120px;
  &__in {
    max-height: calc(100vh - 120px);
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    overflow-y: hidden;
    margin-top: -10px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */;
    &::-webkit-scrollbar  /* WebKit */ {
        width: 0;
        height: 0; }
    @include media-breakpoint-down(md) {
      margin-top: 0; }
    @include media-breakpoint-down(sm) {
      max-height: none;
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0; } }
  &__title {
    color: $text-color;
    @include f-subheader;
    margin-bottom: 35px;
    @include media-breakpoint-down(md) {
      font-size: 21px;
      line-height: 56px;
      margin-bottom: 16px; } }
  &__img {
    max-width: 280px;
    max-height: 262px;
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-bottom: 60px;
    @include media-breakpoint-down(md) {
      max-width: 180px;
      max-height: 168px; }
    @include media-breakpoint-down(xs) {
      max-width: 235px;
      max-height: 219px; } }
  &__tabs {
    @include f-body-lg;
    p {
      margin-bottom: 24px; }
    a {
      color: $primary; } } }
