.circle-media {
  position: relative;
  height: 480px;
  width: 100%;
  &__img {
    position: absolute;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    transition: transform 0.3s ease-in-out; }
  &__icon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.3s ease-in-out;
    z-index: 3;
    &--sm {
      width: 32px;
      height: 32px; } }
  &__item {
    display: block;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    &:hover {
      .circle-media__img {
        transform: scale(1.03); } }
    &::before {
      content: ' ';
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: linear-gradient(180deg, #24374E 0%, rgba(36, 55, 78, 0) 70%); }
    &--lg {
      width: 220px;
      height: 220px; }
    &--sm {
      width: 160px;
      height: 160px; }
    &--xs {
      width: 120px;
      height: 120px; }
    &--top {
      &-left {
        left: 0;
        top: 0;
        animation: float 18s ease-in-out infinite; }
      &-right {
        right: 0;
        top: 0;
        animation: float 15s ease-in-out infinite; } }
    &--bottom {
      &-left {
        top: 260px;
        left: 0;
        animation: float 12s ease-in-out infinite; }
      &-right {
        top: 180px;
        right: 0;
        animation: float 9s ease-in-out infinite; } } } }

@keyframes float {
 0% {
   transform: translatey(0px); }

 50% {
   transform: translatey(-15px); }

 100% {
   transform: translatey(0px); } }



