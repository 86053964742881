.expanded-link {
  display: block;
  width: 199px;
  &:hover {
    text-decoration: none;
    .expanded-link__caption {
      &::before {
        opacity: 0; }
      &::after {
        opacity: 1; } } }
  &__thumbnail {
    border-radius: 10px;
    display: block;
    margin-bottom: 20px;
    height: 140px; }
  &__caption {
    @include f-body-lg;
    font-weight: 600;
    padding-bottom: 21px;
    position: relative;
    margin: 0;
    &::before,
    &::after {
      content: ' ';
      display: block;
      width: 21px;
      height: 21px;
      transition: opacity 0.3s ease-in-out;
      position: absolute;
      bottom: -7px;
      left: 0; }
    &::before {
      opacity: 1;
      background: url(../images/sprites/main.stack.svg#image-arr-right);
      background-repeat: no-repeat; }
    &::after {
      opacity: 0;
      background: url(../images/sprites/main.stack.svg#image-arr-right-primary);
      background-repeat: no-repeat; } } }

