.footer {
  padding-top: 60px;
  padding-bottom: 70px;
  background: linear-gradient(180deg, #1C3B48 0%, #0E232C 100%);
  color: white;
  position: relative;
  z-index: 3;
  @include media-breakpoint-down(sm) {
    padding-bottom: 49px; }
  @include media-breakpoint-down(xs) {
    padding-top: 40px;
    padding-bottom: 53px; }
  &__container {
    max-width: 1715px;
    @include media-breakpoint-down(lg) {
      max-width: 1440px; }
    @include media-breakpoint-down(md) {
      max-width: 1040px; }
    @include media-breakpoint-only(md) {
      max-width: none;
      width: auto;
      margin-left: 100px;
      margin-right: 100px; }
    @include media-breakpoint-only(lg) {
      max-width: none;
      width: auto;
      margin-left: 100px;
      margin-right: 100px; } }
  &__logo {
    max-width: 200px;
    height: auto;
    @include media-breakpoint-down(sm) {
      max-width: 120px;
      margin-bottom: 80px; }
    @include media-breakpoint-down(xs) {
      margin-bottom: 10px; } }
  &__menu {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 2px;
    margin-right: 50px;
    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-bottom: 79px; }
    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      justify-content: space-between; } }
  &__col {
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(sm) {
      margin-left: 40px;
      margin-right: 40px; }
    @include media-breakpoint-down(xs) {
      margin-left: 0;
      margin-right: 14px;
      margin-top: 62px; } }
  &__link {
    color: white;
    @include small-caps;
    &:not(:last-child) {
      margin-bottom: 20px; } }
  &__in {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 14px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 10px; } }
    &--end {
      align-items: flex-end; }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center; }
    &--start {
      @include media-breakpoint-down(xs) {
        align-items: flex-start; } } }
  &__contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &-link {
      @include small-caps;
      color: white;
      &[href] {
        border-bottom: 1px solid;
        &:hover {
          text-decoration: none; } }
      &:not(:last-child) {
        margin-bottom: 16px; }
      @include media-breakpoint-down(sm) {
        display: none; } } }
  &__copyright {
    text-align: right;
    @include small-caps;
    margin: 0;
    font-size: 11px;
    line-height: 13px;
    position: relative;
    top: 10px;
    @include media-breakpoint-down(sm) {
      text-align: center; } }
  &__resource {
    @include media-breakpoint-down(xs) {
      width: 100%; } } }

