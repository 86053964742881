.comment {
  display: block;
  margin-bottom: 36px;
  @include media-breakpoint-down(xs) {
    margin-bottom: 77px; }
  &--mb-lg {
    margin-bottom: 80px; }
  &__title {
    @include f-subtitle;
    margin-bottom: 24px;
    &--with-status {
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        align-items: flex-start; } } }
  &__status {
    @include small-caps;
    padding: 4px 10px;
    line-height: 17px;
    margin-left: 15px;
    background: $text-light-color;
    @include media-breakpoint-down(sm) {
      display: block;
      margin-left: 0;
      width: auto;
      margin-top: 20px; } }

  &__meta-list {
    color: $placeholder-text-color;
    margin-bottom: 24px;
    a {
      text-decoration: underline;
      color: $placeholder-text-color;
      &:hover {
        color: $primary; } } }
  &__detail {
    margin: 0; }
  &__star {
    display: block;
    margin-right: 20px;
    width: 20px;
    height: 20px; }
  &__rating {
    margin-bottom: 24px;
    display: flex; }
  &__content {
    p {
      &:last-child {
        margin-bottom: 0; } } }
  &__link {
    color: $primary; } }
