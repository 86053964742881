.search-box {
  position: relative;
  &--link {
    display: block;
    .search-box__input {
      pointer-events: none; } }
  &--mb {
    margin-bottom:  100px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px; } }
  &__content {
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: -1;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 40px;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    pointer-events: none;
    @include media-breakpoint-down(sm) {
      max-height: 80vh;
      padding: 20px; } }
  &--expand {
    cursor: pointer;
    position: relative;
    &-open {
      .search-box__content {
        opacity: 1;
        visibility: visible;
        z-index: 3;
        transform: none;
        pointer-events: auto; } } }
  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 95px;
    z-index: 5;
    width: 100%;
    max-width: 760px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05); }
  @include media-breakpoint-down(xs) {
    left: 20px;
    right: 20px;
    width: auto; }
  &--static {
    position: relative;
    top: 0; }
  &__close {
    width: 24px;
    height: 24px;
    right: 40px;
    top: 40px;
    position: absolute;
    display: block;
    background: url(../images/sprites/main.stack.svg#image-close-expand);
    background-repeat: no-repeat;
    border: none;
    transition: opacity 0.2s ease-in-out;
    &:focus,
    &:hover {
      outline: none;
      opacity: 0.5; }
    @include media-breakpoint-down(sm) {
      top: 26px;
      right: 22px; } }
  &__control {
    margin-bottom: 27px;
    position: relative;
    @media screen and (max-height: 570px) {
      margin-bottom: 20px; }
    &::after {
      content: ' ';
      position: absolute;
      display: block;
      width: 60px;
      height: 1px;
      left: 10px;
      bottom: -15px;
      background: $text-light-color; } }
  &__apply {
    margin-top: 48px;
    @include media-breakpoint-down(md) {
      margin-top: 30px; } }
  &__input {
    @include f-subtitle;
    text-overflow: ellipsis;
    padding: 35px 80px 35px 40px;
    background: #FFFFFF;
    width: 100%;
    line-height: 1;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    border: none;
    &:focus {
      outline: none; }
    @include media-breakpoint-down(sm) {
      @include f-body; }
    &--sm {
      @include f-body;
      font-weight: normal;
      @include media-breakpoint-down(sm) {
        padding-top: 8px;
        padding-bottom: 8px; } }
    &--prepand-icon {
      padding-left: 100px;
      padding-right: 40px;
      @include media-breakpoint-down(sm) {
        padding-left: 60px;
        padding-right: 20px; } } }

  &__btn {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    border: none;
    padding: 0;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
    &:hover,
    &:focus {
      outline: none;
      background: $primary-hover; }
    &--sm {
      width: 20px;
      height: 20px;
      background: transparent;
      right: unset;
      left: 40px;
      top: 37px;
      transform: none;
      &:hover,
      &:focus {
        background: transparent; }
      @include media-breakpoint-down(sm) {
        top: 10px;
        left: 20px; } }

    &--opacity-hover {
      &:hover,
      &:focus {
        opacity: 0.5; } } }
  &__icon {
    width: 24px;
    display: block;
    height: 24px;
    &--sm {
      width: 20px;
      height: 20px; } } }
