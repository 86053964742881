.facilities-item {
  width: 100%;
  transition: color 0.2s ease-in-out;
  margin-bottom: 60px;
  display: block;
  @include media-breakpoint-down(sm) {
    text-decoration: underline;
    margin-bottom: 20px; }
  &__image-wrap {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      display: none; } }
  &__image {
    height: 56px;
    width: auto;
    @include media-breakpoint-down(md) {
      height: 50px; } }
  &__descr {
    @include f-body-lg;
    @include media-breakpoint-down(sm) {
      @include f-body;
      text-align: center;
      br {
        display: none; } } } }
