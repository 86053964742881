.hero {
  padding-top: 432px;
  padding-bottom: 253px;
  background: black;
  color: white;
  position: relative;
  @include media-breakpoint-down(lg) {
    padding-top: 313px;
    padding-bottom: 217px; }
  @include media-breakpoint-down(md) {
    padding-top: 216px;
    padding-bottom: 151px; }
  @include media-breakpoint-down(sm) {
    background: transparent;
    margin-top: 76px;
    padding-top: 254px;
    padding-bottom: 15px; }
  @include media-breakpoint-down(xs) {
    padding-top: 56px; }
  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    @include media-breakpoint-down(sm) {
      height: 700px;
 }      //display: none
    @include media-breakpoint-down(xs) {
      height: 210px; } }
  &::before {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(8, 16, 18, 0.4) 0%, rgba(24, 42, 48, 0.4) 16.58%, rgba(159, 159, 159, 0.076) 100%);
    z-index: 2;
    @include media-breakpoint-down(sm) {
      height: 700px; }
    @include media-breakpoint-down(xs) {
      height: 210px; } }
  &--static-height {
    padding-top: 170px;
    padding-bottom: 50px;
    min-height: 100vh;
    display: flex;
    background: transparent;
    @include media-breakpoint-down(md) {
      min-height: 1px;
      min-height: 525px; }
    @include media-breakpoint-down(sm) {
      margin-top: 76px;
      height: 210px;
      min-height: 210px;
      padding-top: 0;
      padding-bottom: 0; }
    &::before {
      background: linear-gradient(180deg, #24374E 0%, rgba(36, 55, 78, 0.4) 70%);
      transform: rotate(180deg);
      height: 100%; } }
  &--always-sm {
    padding-top: 0;
    padding-bottom: 0;
    height: 320px;
    min-height: 320px;
    margin-top: 140px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include media-breakpoint-down(sm) {
      margin-top: 75px; }
    &::before {
      content: none; } }
  &--bg-gradient {
    background: $bg-gradient-1; }
  &--has-mobile-descr {
    @include media-breakpoint-down(sm) {
      .hero__title {
        font-size: 30px;
        line-height: 36px; }
      padding-top: 47px;
      padding-bottom: 47px;
      background: $bg-gradient-1-reverse;
      height: auto;
      .hero__bg-img {
        display: none; } } }

  &__container-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2; }
  &__bg-img {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  &--home {
    padding-top: 140px;
    padding-bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include media-breakpoint-down(md) {
      padding-bottom: 114px; }
    @include media-breakpoint-down(sm) {
      padding-top: 256px;
      padding-bottom: 0; }
    @include media-breakpoint-down(xs) {
      padding-top: 56px; }
    @include media-breakpoint-up(md) {
      min-height: 100vh; }
    &::after {
      height: 700px;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      background: url(../images/bg/hero.webp);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      z-index: 1;
      @include media-breakpoint-down(sm) {
 }        //content: ' '
      @include media-breakpoint-down(xs) {
        height: 210px; } } }
  &__row {
    position: relative;
    z-index: 3;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      justify-content: center; }
    &--mb {
      margin-bottom: 120px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 97px; }
      @include media-breakpoint-down(md) {
        margin-bottom: 70px; }
      @include media-breakpoint-down(xs) {
        margin-bottom: 100px; } } }
  &__title {
    @include f-header;
    margin-bottom: 40px;
    @include media-breakpoint-down(sm) {
      text-align: center; }
    @include media-breakpoint-down(xs) {
      font-size: 21px;
      line-height: 30px;
      margin-bottom: 20px; }
    &--mobile-alone {
      margin-bottom: 40px;
      &-mb {
        margin-bottom: 65px; }
      @include media-breakpoint-down(sm) {
        font-size: 30px;
        line-height: 40px; }
      @include media-breakpoint-down(sm) {
        margin-bottom: 0; } } }
  &__subtitle {
    @include f-title;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      text-align: center; }
    @include media-breakpoint-down(xs) {
      font-size: 16px;
      line-height: 19.5px; } }
  &__col-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-bottom: 10px;
    @include media-breakpoint-down(sm) {
      text-align: center;
      align-items: center;
      margin-top: 314px;
      color: $title-text-color; }
    @include media-breakpoint-down(xs) {
      margin-top: 125px; } }
  &__text {
    margin-bottom: 45px;
    @include f-body-lg;
    @include media-breakpoint-only(md) {
      margin-top: 8px; }
    @include media-breakpoint-down(md) {
      margin-bottom: 20px; }
    @include media-breakpoint-down(sm) {
      margin-bottom: 27px; }
    &--desktop-only {
      &:last-child {
        margin-bottom: 0; }
      @include media-breakpoint-down(sm) {
        display: none; } } }
  &__link {
    text-decoration: none !important;
    &:not(:hover) {
      color: white;
      @include media-breakpoint-down(sm) {
        color: $title-text-color; } }

    position: relative;
    display: inline-block;
    border-bottom: 1px solid #FFFFFF;
    transition: all 0.15s ease-in-out;
    @include small-caps;
    @include media-breakpoint-down(sm) {
      border-bottom-color: $title-text-color; }
    &:hover {
      border-bottom-color: $primary; } } }

.hero-append {
  margin-top: -140px;
  position: relative;
  z-index: 5;
  @include media-breakpoint-down(md) {
    margin-top: -95px; } }

.hero-append-text {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
    margin-top: 40px; } }

.no-webp {
  .hero {
    &--home {
      &::after {
        background: url(../images/bg/hero.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat; } } } }

