.row-side {
  position: relative;
  &__btn {
    margin-bottom: 80px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px; } }
  &__content {
    @include f-body-lg;
    font-family: $title-ff;
    font-weight: 400;
    @include media-breakpoint-down(sm) {
      font-family: $text-ff; }

    p {
      margin-bottom: 60px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 40px; }
      &:last-child {
        margin-bottom: 0; } }
    &--mobile-sm {
      font-size: 16px;
      line-height: 24px; } }
  &__col {
    position: static; }
  &__image {
    height: 636px;
    position: absolute;
    left: 50%;
    transform: translateX(16.2vw);
    width: 33.8vw;
    top: 122px;
    object-fit: cover;
    @include media-breakpoint-down(sm) {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      margin-top: 60px;
      margin-bottom: 60px;
      width: 100%;
      height: auto; }
    @include media-breakpoint-down(xs) {
      width: 100vw;
      height: 280px;
      margin-left: -20px; } } }

