.special-date {
  font-size: 64px;
  line-height: 80px;
  font-family: $title-ff;
  color: $text-special-color;
  font-weight: 700;
  @include media-breakpoint-down(md) {
    font-size: 48px;
    line-height: 59px; }
  @include media-breakpoint-down(sm) {
    font-size: 40px;
    line-height: 49px; } }


