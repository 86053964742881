.step-col {
  position: relative;
  display: flex;
  align-items: center;
  height: 200px;
  padding-left: 31px;
  padding-right: 31px;
  &--static {
    padding-top: 63px;
    padding-bottom: 58px;
    height: auto;
    align-items: flex-start;
    width: 100%;
    @include media-breakpoint-down(md) {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
      .step-col__meta {
        left: 20px; } }
    @include media-breakpoint-down(xs) {
      margin-left: -20px;
      margin-right: -20px;
      padding-bottom: 40px; } }
  &--gray {
    background: #F5F5F5; }
  &--lightblue {
    background: #EDF1F8; }
  &__text {
    &:last-child {
      margin-bottom: 0; } }
  &__meta {
    position: absolute;
    left: 32px;
    top: 17px;
    @include f-subtitle;
    color: $text-special-color; } }
