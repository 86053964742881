.card-icon-info {
  padding: 60px 60px 100px 60px;
  background: $bg-gray;
  margin-bottom: 40px;
  position: relative;
  @include media-breakpoint-down(md) {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 40px; }
  &__row {
    @include media-breakpoint-up(md) {
      margin-left: -60px;
      margin-right: -60px; } }
  &--lg {
    padding-top: 40px; }
  &__title {
    @include f-title-forced;
    margin-bottom: 40px;
    &--mb {
      @include media-breakpoint-up(md) {
        margin-bottom: 55px; } } }

  &__content {
    margin-bottom: 40px;
    @include f-body-lg;
    @include media-breakpoint-down(lg) {
      margin-bottom: 50px; }
    p {
      margin-bottom: 0;
      &:not(:last-child) {
        margin-bottom: 20px; } }
    &--lg {
      margin-bottom: 62px;
      @include media-breakpoint-down(md) {
        margin-bottom: 40px; } } }
  &__icon {
    position: absolute;
    width: 60px;
    height: 60px;
    display: block;
    right: 30px;
    top: 30px;
    @include media-breakpoint-down(md) {
      position: static;
      margin-bottom: 40px; }
    &--mb-sm {
      @include media-breakpoint-down(md) {
        margin-bottom: 30px; } } }
  &__btn {
    position: absolute;
    left: 60px;
    bottom: 48px;
    @include media-breakpoint-down(md) {
      position: static;
      transform: none; }
    &--center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      @include media-breakpoint-down(md) {
        bottom: 30px; }
      @include media-breakpoint-down(sm) {
        position: static;
        transform: none; } } } }
