.value-info {
  padding-top: 55px;
  padding-right: 60px;
  margin-bottom: 60px;
  @include media-breakpoint-down(sm) {
    padding-top: 35px;
    margin-bottom: 35px;
    padding-right: 0; }
  &__number {
    color: $text-special-color;
    font-size: 200px;
    line-height: 1;
    margin-bottom: 40px;
    font-weight: 700;
    font-family: $title-ff;
    @include media-breakpoint-down(sm) {
      font-size: 64px;
      margin-bottom: 25px; } }
  &__title {
    margin-bottom: 40px;
    @include f-title;
    @include media-breakpoint-down(md) {
      @include f-subtitle; } }
  &__content {
    @include f-body-lg;
    @include media-breakpoint-down(sm) {
      @include f-body; }
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0; } } } }
