a.thumbnail-preview {
  &:hover {
    text-decoration: none;
    .thumbnail-preview__img {
       transform: scale(1.03); }
    .thumbnail-preview__title {
      text-decoration: underline; } } }

.thumbnail-preview {
  display: block;
  height: 320px;
  width: 100%;
  position: relative;
  padding: 30px;
  margin-bottom: 40px;
  overflow: hidden;
  display: flex;
  // fixed safari border-radius bug on scale into overflow block
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  @include media-breakpoint-down(md) {
    height: 220px; }
  @include media-breakpoint-down(sm) {
    height: 370px;
    margin-bottom: 20px; }
  @include media-breakpoint-down(xs) {
    height: 150px;
    padding: 20px; }
  &--sm {
    height: 220px;
    padding: 20px;
    @include media-breakpoint-down(xs) {
      height: 176px; } }
  &--rounded {
    border-radius: 20px;
    &::before {
      border-radius: 20px; } }
  &--slider {
    margin: 0; }
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #24374E 0%, rgba(36, 55, 78, 0) 70%);
    transform: rotate(-180deg);
    z-index: 3; }
  &__in {
    position: relative;
    z-index: 4;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    width: 100%; }
  &__title {
    color: white;
    text-decoration: none;
    margin: 0; }
  &__icon {
    width: 20px;
    height: 20px;
    display: block;
    background: url(../images/sprites/main.stack.svg#image-inst-footer);
    background-size: contain; }
  &__img {
    transition: transform 0.3s ease-in-out;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; } }
