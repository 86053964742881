.row-map-view {
  @include media-breakpoint-down(sm) {
    min-height: 90vh;
    .row-map-view__main-col {
      transition: all 0.3s ease-in-out; }
    &--mobile-map-view {
      .row-map-view__main-col {
        opacity: 0;
        transform: translateY(20px);
        //position: absolute
        z-index: -1;
        pointer-events: none;
        //left: 0px
        //max-width: 100vh
        //width: 100%
        //overflow: hidden
        max-height: 100vh; }
      .map-slider {
        opacity: 1;
        pointer-events: auto; } } }
  &__toggle {
    display: none;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    padding: 11px 20px 9px;
    background: rgba(34, 34, 34, 0.7);
    backdrop-filter: blur(4px);
    border-radius: 32px;
    font-size: 14px;
    line-height: 17px;
    font-family: $title-ff;
    font-weight: 500;
    z-index: 4;
    border: none;
    color: white;
    transition: all 0.3s ease-in-out;
    &:hover,
    &:focus {
      outline: none;
      background: rgba(34, 34, 34, 0.8); }
    &::after {
      content: ' ';
      width: 13px;
      height: 13px;
      display: inline-block;
      margin-left: 8px; }
    &--to-map-view {
      bottom: 40px;
      &::after {
        background: url(../images/sprites/main.stack.svg#image-map-marker-white);
        background-repeat: no-repeat;
        background-size: cover; } }
    &--to-list-view {
      top: 156px;
      &::after {
        background: url(../images/sprites/main.stack.svg#image-list-white);
        background-repeat: no-repeat;
        background-size: cover; } } }
  &--mobile-list-view {
    @include media-breakpoint-down(sm) {
      .row-map-view__toggle--to-list-view {
        display: none; }
      .row-map-view__toggle--to-map-view {
        display: block; } } }
  &--mobile-map-view {
    @include media-breakpoint-down(sm) {
      .row-map-view__toggle--to-list-view {
        display: block; }
      .row-map-view__toggle--to-map-view {
        display: none; } } }
  &__main-col {
    padding-top: 65px;
    padding-bottom: 40px;
    @include media-breakpoint-up(md) {
      display: block !important; }
    &--lg {
      margin-top: 700px; }
    @include media-breakpoint-down(sm) {
      z-index: 4;
      padding-top: 00px;
      margin-top: 300px;
      position: relative;
      &::after {
        content: ' ';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        width: 40px;
        background: $placeholder-text-color;
        top: 10px; }
      &::before {
        content: ' ';
        display: block;
        left: 50%;
        transform: translateX(-50vw);
        z-index: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        position: absolute;
        background: $bg-gray;
        box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.12);
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        z-index: -1; }
      .card-list {
        margin-top: 40px;
        &__title {
          text-align: center;
          z-index: 1;
          position: relative; } } }
    @include media-breakpoint-down(sm) {
      padding-top: 0; } }
  &__map-col {
    @include media-breakpoint-down(sm) {
      position: static; }
    &--lg {
      position: static;
      position: absolute;
      left: 0;
      top: 140px;
      width: 100%;
      height: 720px;
      @include media-breakpoint-down(sm) {
        top: 0px; } } }
  &__map {
    @include media-breakpoint-down(sm) {
      top: 0;
      width: 100vw;
      bottom: 0;
      max-height: 90vh;
      left: 0;
      right: unset;
      position: absolute; } } }

