.section {
  padding-top: 120px;
  padding-bottom: 120px;
  &--first {
    margin-top: 140px;
    @include media-breakpoint-down(sm) {
      margin-top: 76px; } }
  &-search {
    padding-top: 0;
    padding-bottom: 90px; }
  &-benefists-package {
    padding-top: 40px;
    padding-bottom: 18px;
    @include media-breakpoint-down(sm) {
      padding-top: 60px; } }
  &-slider-shedule {
    padding-top: 80px;
    padding-bottom: 185px;
    background: $bg-gray;
    @include media-breakpoint-down(sm) {
      padding-top: 50px;
      padding-bottom: 77px; } }
  &-article-list {
    @include media-breakpoint-down(md) {
      padding-top: 102px; }
    @include media-breakpoint-down(sm) {
      padding-top: 45px; } }
  &-article-info {
    padding-top: 20px;
    @include media-breakpoint-down(md) {
      padding-top: 0;
      padding-bottom: 40px; } }
  &-events {
    padding-top: 72px;
    @include media-breakpoint-down(md) {
      padding-bottom: 40px; }
    @include media-breakpoint-down(xs) {
      padding-top: 60px; } }

  &-slider-facts {
    padding-top: 105px;
    padding-bottom: 240px;
    .section__title {
      @include media-breakpoint-up(lg) {
        margin-bottom: 60px; } }
    @include media-breakpoint-down(sm) {
      padding-top: 70px;
      padding-bottom: 60px; } }
  &-welcome-row-side {
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 918px;
    @include media-breakpoint-down(sm) {
      min-height: 1px;
      padding-top: 40px;
      padding-bottom: 62px; } }
  &-welcome-row {
    padding-top: 0;
    padding-bottom: 0; }
  &-image-info {
    padding-top: 80px;
    padding-bottom: 40px;
    @include media-breakpoint-down(sm) {
      padding-top: 40px;
      margin-bottom: 0; } }
  &-info {
    padding-top: 84px;
    @include f-body-lg;
    .section__title {
      margin-bottom: 40px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 50px; } }
    padding-bottom: 40px;
    @include media-breakpoint-down(lg) {
      padding-bottom: 20px; }
    @include media-breakpoint-down(md) {
      padding-top: 75px; } }
  &-info-card {
    padding-top: 40px;
    padding-bottom: 40px;
    @include media-breakpoint-down(md) {
      padding-bottom: 20px; } }
  &-line-links {
    padding-top: 80px;
    padding-bottom: 1px;
    @include media-breakpoint-down(md) {
      padding-top: 10px;
      padding-bottom: 10px; } }
  &-instructor-detail {
    padding-top: 80px;
    padding-bottom: 80px;
    background: $bg-gray;
    @include f-body-lg;
    p {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 40px; } }
    @include media-breakpoint-down(sm) {
      padding-top: 40px;
      padding-bottom: 40px;
      > .container > .row {
        flex-direction: column-reverse; }
      .section__btns {
        margin-bottom: 40px; } } }
  &-core-values {
    padding-top: 80px;
    padding-bottom: 60px;
    background: $bg-gray;
    @include media-breakpoint-down(sm) {
      padding-top: 40px;
      padding-bottom: 0; }
    .section__title {
      margin-bottom: 13px; } }
  &-course-detail {
    padding-top: 80px;
    min-height: 316px;
    padding-bottom: 80px;
    @include media-breakpoint-down(sm) {
      text-align: center;
      padding-top: 40px;
      padding-bottom: 40px; }
    @include f-body-lg;
    p {
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 0; } }
    .section__title {
      margin-bottom: 40px; }
    .special-date {
      position: absolute;
      right: 20px;
      top: 0;
      @include media-breakpoint-down(sm) {
        position: static;
        margin-bottom: 40px; } } }
  &-with-side-image {
    padding-bottom: 80px;
    padding-bottom: 256px;
    @include media-breakpoint-down(lg) {
      padding-bottom: 150px; }
    @include media-breakpoint-down(sm) {
      padding-top: 60px; }
    @include f-body-lg;
    @include media-breakpoint-down(sm) {
      padding-bottom: 100px;
      @include f-body; }
    p {
      margin-bottom: 80px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 40px; }
      &:last-child {
        margin-bottom: 0; } } }
  &-offer {
    margin-top: 120px;
    margin-bottom: 108px;
    padding-top: 60px;
    padding-bottom: 68px;
    background: $bg-gradient-1;
    color: white;
    &--mobile-wide {
      @include media-breakpoint-down(sm) {
        margin-right: -20px;
        margin-left: -20px; } }
    &--mt-sm {
      margin-top: 100px; }
    &--mt-xs {
      margin-top: 60px; }
    &--mt-sm-down {
      margin-top: 80px; }
    &--second-bg {
      background: $bg-gradient-2;
      color: $title-text-color; }
    &__btn {
      margin-bottom: 35px; }
    @include media-breakpoint-down(sm) {
      margin-top: 0px;
      margin-bottom: 40px; }
    &--sm {
      margin-top: 80px;
      padding-top: 40px;
      padding-bottom: 48px;
      @include media-breakpoint-down(sm) {
        margin-top: 100px;
        margin-bottom: 80px; }
      @include media-breakpoint-down(xs) {
        margin-left: -20px;
        margin-right: -20px; } }
    &--mobile-sm {
      @include media-breakpoint-down(sm) {
        padding-top: 40px;
        padding-bottom: 40px; } }
    &--mobile-mt {
      @include media-breakpoint-down(sm) {
        margin-top: 60px; } }
    &--mb-sm {
      margin-bottom: 48px; } }
  &-details {
    padding-top: 60px;
    padding-bottom: 62px;
    background: $bg-gray;
    @include media-breakpoint-down(sm) {
      padding-top: 40px;
      padding-bottom: 55px; } }
  &-chess {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 80px;
    @include media-breakpoint-down(md) {
      margin-bottom: 87px; }
    @include media-breakpoint-down(sm) {
      margin-bottom: 42px; }
    &--special {
      overflow-x: hidden;
      > .chess > .container > .row {
        position: relative;
        &::before {
          content:  ' ';
          display: block;
          position: absolute;
          z-index: -1;
          left: 50%;
          top: 0;
          width: 100vw;
          transform: translateX(-50vw);
          height: 100%;
          background: $bg-gray; }
        @include media-breakpoint-down(sm) {
          position: static;
          &::before {
            content: none; } } } }
    &--first {
      margin-top: 100px;
      @include media-breakpoint-down(md) {
        margin-top: 108px; }
      @include media-breakpoint-down(sm) {
        margin-top: 60px; } } }
  &-facilities {
    padding-top: 0;
    @include media-breakpoint-down(sm) {
      margin-top: 40px;
      padding-bottom: 0; } }
  &-other {
    padding-top: 180px; }
  &-map-view {
    @include media-breakpoint-down(sm) {
      padding-top: 0;
      padding-bottom: 0;
      position: relative;
      margin-top: 75px; } }
  &-detail-view {
    padding-top: 140px;
    padding-bottom: 0;
    @include media-breakpoint-down(sm) {
      padding-top: 96px; } }
  &-statistic {
    .container {
      position: relative; }
    padding-bottom: 145px;
    @include media-breakpoint-down(lg) {
      padding-bottom: 124px; }
    @include media-breakpoint-down(md) {
      padding-top: 132px; }
    @include media-breakpoint-down(sm) {
      padding-top: 50px;
      .section__title {
        margin-bottom: 27px; } } }
  &-col-steps {
    padding-bottom: 70px;
    @include media-breakpoint-down(sm) {
      padding-top: 60px;
      padding-bottom: 40px; }
    .section__title {
      margin-bottom: 80px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 40px; } } }
  &-col-steps-lg {
    padding-top: 100px;
    padding-bottom: 92px;
    background: $bg-gray;
    @include media-breakpoint-down(sm) {
      padding-top: 60px;
      padding-bottom: 40px; }
    .section__title {
      margin-bottom: 100px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 40px; } } }
  &-steps {
    background: $bg-gray;
    padding-top: 80px;
    padding-bottom: 20px;
    .section__title {
      margin-bottom: 100px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 60px; } } }
  &-instructor-list {
    margin-top: 170px;
    padding-top: 128px;
    padding-bottom: 69px;
    background: $bg-gradient-1;
    color: white;
    @include media-breakpoint-down(lg) {
      margin-top: 176px;
      padding-top: 120px;
      padding-bottom: 77px; }
    @include media-breakpoint-down(md) {
      margin-top: 73px;
      padding-top: 103px;
      padding-bottom: 45px; }
    @include media-breakpoint-down(sm) {
      padding-bottom: 76px;
      .section__descr {
        margin-bottom: 20px; } }
    @include media-breakpoint-down(xs) {
      margin-top: 15px;
      padding-bottom: 32px; } }
  &-our-facilities {
    padding-top: 100px;
    padding-bottom: 88px;
    @include media-breakpoint-down(lg) {
      padding-top: 83px;
      padding-bottom: 80px; }
    @include media-breakpoint-only(md) {
      .section__title {
        margin-bottom: 76px; } }
    @include media-breakpoint-down(sm) {
      padding-bottom: 97px; }
    @include media-breakpoint-down(xs) {
      padding-top: 53px;
      padding-bottom: 72px; } }
  &-current-facilities {
    padding-top: 50px;
    padding-bottom: 88px;
    @include media-breakpoint-down(md) {
      padding-bottom: 70px; }
    @include media-breakpoint-down(sm) {
      padding-top: 10px;
      .section__title {
        margin-bottom: 40px; } } }
  &-comment-list {
    padding-top: 100px;
    @include media-breakpoint-down(sm) {
      padding-top: 60px;
      padding-bottom: 60px; } }
  &-find-instructor {
    padding-top: 50px; }
  &-our-family {
    padding-top: 80px;
    background: $bg-gray;
    padding-bottom: 116px;
    overflow-x: hidden;
    @include media-breakpoint-down(md) {
      padding-top: 90px;
      padding-bottom: 124px; }
    @include media-breakpoint-down(sm) {
      padding-top: 79px;
      padding-bottom: 144px;
      > .container {
        > .section__title {
          margin-bottom: 50px;
          @include media-breakpoint-down(xs) {
            font-size: 21px;
            line-height: 1.5;
            margin-bottom: 26px; } } } }
    @include media-breakpoint-down(xs) {
      padding-top: 35px;
      padding-bottom: 75px; } }


  &-verify {
    padding-top: 60px;
    padding-bottom: 65px;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
    background: $bg-gradient-2;
    @include media-breakpoint-down(sm) {
      .section__title {
        margin-bottom: 58px; } }
    @include media-breakpoint-down(xs) {
      .section__title {
        margin-bottom: 30px; } }
    &--lg {
      padding-top: 77px;
      padding-bottom: 100px;
      @include media-breakpoint-down(sm) {
        padding-top: 60px; } } }
  &-questions {
    padding-top: 129px;
    padding-bottom: 87px;

    @include media-breakpoint-down(lg) {
      padding-top: 100px;
      padding-bottom: 190px; }
    @include media-breakpoint-down(md) {
      padding-top: 126px;
      padding-bottom: 133px; }
    @include media-breakpoint-down(sm) {
      padding-top: 101px;
      padding-bottom: 185px;
      .section__descr {
        margin-bottom: 38px;
        font-size: 14px;
        line-height: 18px; } }
    @include media-breakpoint-down(xs) {
      padding-top: 61px;
      padding-bottom: 67px; }
    .container {

      position: relative; }
    .section__image {
      position: absolute;
      object-fit: cover;
      right: 145px;
      width: 440px;
      height: auto;
      bottom: -87px;
      @include media-breakpoint-down(lg) {
        bottom: -100px; }
      @include media-breakpoint-down(md) {
        width: 330px;
        bottom: -31px;
        right: 110px; }
      @include media-breakpoint-down(sm) {
        display: none; } } }
  &__back-btn {
    margin-bottom: 60px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px; } }
  &__title {
    margin-bottom: 20px;
    @include f-subheader;
    @include media-breakpoint-only(md) {
      margin-bottom: 27px; }
    &--subheader-desktop {
      @include f-subheader-forced;
      @include media-breakpoint-down(sm) {
        font-size: 21px;
        line-height: 25px; } }
    &--mb-md {
      margin-bottom: 40px; }
    &--mb-lg {
      margin-bottom: 60px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 27px; } }
    &--mobile-mb-md {
      margin-bottom: 50px; }
    &--mb-xl {
      margin-bottom: 100px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 40px; } }
    &--sm-forced {
      @include f-title-forced; }
    &--sm {
      @include f-title;
      @include media-breakpoint-down(sm) {
        font-size: 21px;
        line-height: 30px; } }
    &--xs {
      @include f-subtitle; }
    &--mobile-xxs {
      @include media-breakpoint-down(sm) {
        font-size: 16px; } }
    &--mobile-subtitle {
      @include media-breakpoint-down(sm) {
        @include f-subtitle; } } }
  &__descr {
    margin-bottom: 60px;
    &--mb-sm {
      margin-bottom: 40px; } }
  &__btns {
    display: flex;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start; }
    .btn {
      &:not(:last-child) {
        margin-right: 20px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 20px; } } } } }

