.btn {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  padding: 13px 33px 9px;
  text-transform: uppercase;
  border-radius: 32px;
  font-family: $title-ff;
  font-weight: $font-weight-btn;
  border: none;
  transition: all 0.2s ease-in-out;
  border: 2px solid;
  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer; }
  &:focus {
    outline: none; }
  &--default-font {
    font-family: $text-ff;
    font-weight: 400;
    font-size: 16px;
    font-variant: normal;
    text-transform: none; }
  &--back {
    padding-left: 60px;
    position: relative;
    text-transform: none;
    &::before,
    &::after {
      content: ' ';
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      left: 20px;
      transform: translateY(-50%);
      top: 50%;
      transition: opacity 0.3s ease-in-out; }
    &::before {
      background: url(../images/sprites/main.stack.svg#image-arr-back);
      background-repeat: no-repeat;
      opacity: 1; }
    &::after {
      background: url(../images/sprites/main.stack.svg#image-arr-back-primary);
      background-repeat: no-repeat;
      opacity: 0; }
    &:hover {
      &::before {
        opacity: 0; }
      &::after {
        opacity: 1; } } }
  &--fill-white {
    background: white;
    color: $body-text-color;
    border-color: white;
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05); }
  &--primary {
    border-color: $primary;
    background: $primary;
    color: white;
    &:hover,
    &:focus {
      color: white;
      box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
      background: $primary-hover;
      border-color: $primary-hover; } }
  &--outline-primary {
    border-color: $primary;
    color: $primary;
    background: transparent;
    &:hover,
    &:focus {
      color: $bg-white;
      background: $primary; } }
  &--white {
    border-color: $bg-white;
    color: $bg-white;
    background: transparent;
    &:hover,
    &:focus {
      color: $primary;
      background: $bg-white; } }
  &--link {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    border: none;
    background: transparent;
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      bottom: 8px;
      width: 100%;
      height: 1px;
      transition: background 0.2s ease-in-out; } }
  &--link-dark {
    color: $title-text-color;
    &::after {
      background: $title-text-color; }
    &:hover,
    &:focus {
      color: $primary;
      &::after {
        background: $primary; } } }
  &--link-primary {
    color: $primary;
    &::after {
      background: $primary; }
    &:hover,
    &:focus {
      color: $primary-hover;
      &::after {
        background: $primary-hover; } } } }



