.header {
  padding-left: 100px;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10;
  background: white;
  transition: background 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
  @include media-breakpoint-down(sm) {
    padding-left: 0; }
  &--top {
    background: transparent;
    .header__link {
      &:not(.header__link--toggle-active) {
        &:not(:hover) {
          color: white;
          @include media-breakpoint-down(sm) {
            color: $title-text-color; }
          &::before {
            background: url(../images/sprites/main.stack.svg#image-expand-down-white);
            @include media-breakpoint-down(sm) {
              background: url(../images/sprites/main.stack.svg#image-expand-down); } } } } } }
  &--shadow {
    box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05); }
  &--thin {
    @include media-breakpoint-up(md) {
      .header__in {
        height: 90px; }
      .popup-menu {
        top: 90px; } } }
  &__in {
    height: 140px;
    transition: height 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      height: 76px;
      background: white;
      padding-left: 20px;
      padding-right: 20px; } }
  &__logo-link {
    margin-right: 83px;
    position: relative;
    z-index: 3;
    @include media-breakpoint-down(lg) {
      margin-right: 60px; }
    @include media-breakpoint-down(md) {
      margin-right: 26px; } }
  &__menu-in {
    @include media-breakpoint-down(sm) {
      position: absolute;
      padding:  96px 20px 20px 20px;
      background: white;
      flex-direction: column;
      align-items: flex-start;
      left: 0;
      width: 100%;
      bottom: 76px;
      transform: none;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
      display: flex;
      z-index: 3;
      max-height: 100vh;
      overflow-y: auto;
      box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05); } }

  &__burger {
    display: none;
    width: 36px;
    height: 36px;
    position: relative;
    padding: 0;
    border: none;
    background: transparent;
    z-index: 4;
    &:focus {
      outline: none; }
    &::before,
    &::after {
      content: ' ';
      position: absolute;
      display: block;
      left: 50%;
      height: 2px;
      width: 20px;
      transform: translateX(-50%);
      transition: all 0.3s ease-in-out;
      background: $text-color; }
    &::before {
      top: 13px; }
    &::after {
      bottom: 13px; }
    &--active {
      &::before {
        top: 17px;
        transform: translateX(-50%) rotate(45deg); }
      &::after {
        bottom: 17px;
        transform: translateX(-50%) rotate(-45deg); }
      @include media-breakpoint-down(sm) {
        ~ .header__menu-in {
          transform: translateY(100%);
          visibility: visible; } } }
    @include media-breakpoint-down(sm) {
      display: block; } }

  &__logo {
    width: 120px;
    height: auto;
    @include media-breakpoint-down(sm) {
 } }      //width: 86px
  &__submenu {
    transform: translateX(-20%);
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    @include media-breakpoint-down(sm) {
      transform: none;
      opacity: 1;
      z-index: 1;
      visibility: visible;
      display: none; } }
  &__link {
    &:not(:last-child) {
      margin-right: 13px; }
    @include small-caps;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 24.38px;
      margin-right: 0;
      margin-bottom: 32px;
      display: inline-block; }
    &:hover,
    &:focus {
      text-decoration: none; }
    &--toggle {
      padding-right: 24px;
      position: relative;
      &::before,
      &::after {
        content: ' ';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        right: 3px;
        top: 55%;
        transform: translateY(-50%);
        transition: opacity .1s ease-in-out, transform 0.2s ease-in-out; }
      &::after {
        opacity: 0; }
      &::before {
        background: url(../images/sprites/main.stack.svg#image-expand-down); }
      &::after {
        background: url(../images/sprites/main.stack.svg#image-expand-down-primary); }
      &:hover {
        &::after {
          opacity: 1; }
        &::before {
          opacity: 0; } }
      &-active {
        color: $primary;
        &::before,
        &::after {
          transform: translateY(-50%) rotate(180deg); }
        &::before {
          opacity: 0; }
        &::after {
          opacity: 1; }
        + .header__submenu {
          transform: none;
          opacity: 1;
          visibility: visible;
          z-index: 3;
          @include media-breakpoint-down(sm) {
            animation: fadeIn 0.5s;
            display: block; } } } } }
  &__menu {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    @include media-breakpoint-down(sm) {
      width: 100%;
      justify-content: space-between;
      background: white;
      z-index: 3; } }
  &__side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #4AA6FA;
    border-right: none;
    box-sizing: border-box;
    border-radius: 60px 0px 0px 60px;
    width: auto;
    position: relative;
    @include media-breakpoint-down(sm) {
      display: none; }

    &::after {
      content: ' ';
      height: 32px;
      width: 1px;
      background: $primary;
      position: absolute;
      right: 114px;
      top: 50%;
      transform: translateY(-50%); }
    &-search-link {
      display: flex;
      position: relative;
      height: 60px;
      padding-left: 62px;
      padding-right: 29px;
      align-items: center;
      border-radius: 60px 0px 0px 60px;
      margin-left: -1px;
      @include small-caps;
      color: $primary;
      transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
      @include media-breakpoint-down(md) {
        max-width: 310px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      &-caption {
        font-size: 13px;
        @include media-breakpoint-down(md) {
          vertical-align: middle;
          line-height: 60px; } }
      &::before,
      &::after {
        content: ' ';
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        transition: opacity 0.3s ease-in-out;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 32px;
        top: 53%;
        transform: translateY(-50%); }
      &::before {
        opacity: 1;
        background: url(../images/sprites/main.stack.svg#image-side-search-primary);
        background-size: contain;
        background-repeat: no-repeat; }
      &::after {
        opacity: 0;
        background: url(../images/sprites/main.stack.svg#image-side-search-white);
        background-size: contain;
        background-repeat: no-repeat; }
      &:hover {
        color: white;
        background: $primary;
        text-decoration: none;
        &::before {
          opacity: 0; }
        &::after {
          opacity: 1; } } }
    &-menu {
      display: flex;
      flex-direction: column;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      background: white;
      padding: 40px 30px;
      border: 2px solid #C2E7FF;
      box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05);
      position: absolute;
      bottom: -40px;
      right: 30px;
      transform: translateY(100%);
      &-link {
        &:not(:last-of-type) {
          margin-bottom: 32px; } }
      &-toggle {
        display: block;
        flex-grow: 1;
        border: none;
        height: 60px;
        border: none;
        background: transparent;
        transition: background 0.3s ease-in-out;
        position: relative;
        margin-left: -1px;
        width: 115px;
        &:focus {
          outline: none; }
        &:hover,
        &--active {
          background: $primary;
          .header__side-menu-toggle-icon,
          .header__side-menu-toggle-arr, {
            &::before {
              opacity: 0; }
            &::after {
              opacity: 1; } } }
        &--active {
          + .header__side-menu {
            bottom: -20px;
            visibility: visible;
            opacity: 1; }
          .header__side-menu-toggle-arr {
            transform: translateY(-50%) rotate(180deg); } }
        &-icon {
          width: 32px;
          height: 32px;
          display: block;
          position: absolute;
          top: 50%;
          left: 32px;
          transform: translateY(-50%);
          &::before,
          &::after {
            content: ' ';
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            transition: opacity 0.3s ease-in-out;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%; }
          &::before {
            opacity: 1;
            background: url(../images/sprites/main.stack.svg#image-side-user-primary);
            background-size: contain;
            background-repeat: no-repeat; }
          &::after {
            opacity: 0;
            background: url(../images/sprites/main.stack.svg#image-side-user-white);
            background-size: contain;
            background-repeat: no-repeat; } }
        &-arr {
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          right: 32px;
          top: 50%;
          transform: translateY(-50%);
          &::before,
          &::after {
            content: ' ';
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            transition: opacity 0.3s ease-in-out;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%; }
          &::before {
            opacity: 1;
            background: url(../images/sprites/main.stack.svg#image-expand-down-primary);
            background-size: contain;
            background-repeat: no-repeat; }
          &::after {
            opacity: 0;
            background: url(../images/sprites/main.stack.svg#image-expand-down-white);
            background-size: contain;
            background-repeat: no-repeat; } } } } } }


@-moz-document url-prefix() {
  .header__side-search-link {
    font-size: 15px; } }



