.search-fail {
  &--mb-lg {
    margin-bottom: 98px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 37px; } }
  &__image {
    width: 160px;
    height: 160px;
    display: block;
    margin-bottom: 45px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px; } }
  &__text {
    margin-bottom: 30px;
    @include f-subtitle;
    &:last-child {
      margin-bottom: 0; } } }
