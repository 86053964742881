.info-list {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
  &:not(:last-child) {
    margin-bottom: 16px; }
  &__item {
    &:not(:last-child) {
      margin-bottom: 16px; }
    &::before {
      content:  '- '; } } }
