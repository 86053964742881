.alert {
  padding: 32px 15px;
  background: $text-special-color;
  color: $text-color;
  text-align: center;
  margin-top: 140px;
  @include media-breakpoint-down(sm) {
    margin-top: 75px;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-right: 30px;
    padding-left: 30px; }
  &__text {
    @include f-subtitle;
    &:last-child {
      margin-bottom: 0; }
    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 30px; } } }
