.wide-textblock-slider {
  overflow-x: hidden;
  .splide__track {
    overflow: unset; }
  &__in {
    padding-top: 122px;
    position: relative;
    @include media-breakpoint-down(sm) {
      padding-top: 65px; } }
  &__control {
    height: 6px;
    background: #EAEAEA;
    position: absolute;
    left: 0;
    left: 10px;
    right: 10px;
    top: 12px;
    &-in {
      display: block;
      height: 12px;
      background: #C2DEF0;
      width: 10%;
      transition: width 0.3s ease-in-out;
      position: absolute;
      top: -3px;
      left: 0; } }
  &__title {
    margin-bottom: 66px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 30px; } } }


