.chess {
  max-width: 100%;
  overflow-x: hidden;
  &__title {
    margin-bottom: 60px;
    @include media-breakpoint-down(md) {
      margin-bottom: 71px; }
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 21px;
      line-height: 30px; } }
  &__img {
    width: 100%;
    position: relative;
    height: 560px;
    object-fit: cover;
    &--left {
      right: 18%;
      @include media-breakpoint-down(md) {
        right: 21%; } }
    &--right {
      left: 18%;
      @include media-breakpoint-down(md) {
        left: 21%; } }
    @include media-breakpoint-down(sm) {
      height: 260px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      right: 0;
      width: 800px;
      max-width: 100vw; }
    &--right-always,
    &--left-always {
      @include media-breakpoint-down(sm) {
        transform: none;
        width: 70vw;
        height: auto;
        position: relative;
        margin-bottom: 40px;
        display: block;
        max-width: 500px; } }
    &--left-always {
      @include media-breakpoint-down(sm) {
        right: 20px;
        left: auto; } }
    &--right-always {
      @include media-breakpoint-down(sm) {
        left: 20px;
        right: 0;
        margin-left: auto; } } }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
      &:not(.btn) {
        color: $primary; } }
    > p {
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0; } }
    &--lg {
      @include f-body-lg; } }
  &__tabs {
    .tabs-content-list {
      max-width: 400px; } }
  &__link-btn {
    margin-top: 17px; }
  &__img-col {
    @include media-breakpoint-down(sm) {
      position: static;
      height: 260px; } }
  &__row-mobile-reverse {
    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse; } }
  &__btns {
    flex-direction: row;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
      padding-bottom: 25px; } } }
