.article-list {
  .article {
    margin-bottom: 60px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px; } }
  .pagination {
    margin-top: 30px;
    @include media-breakpoint-down(sm) {
      margin-top: 75px; } } }

.article {
  position: relative;
  background: white;
  box-shadow: -10px 10px 40px rgba(33, 78, 119, 0.04);
  @include media-breakpoint-down(xs) {
    margin-left: -20px;
    margin-right: -20px; }
  &__body {
    padding: 60px 120px 130px;
    position: relative;
    @include media-breakpoint-down(sm) {
      padding-top: 39px;
      padding-left: 20px;
      padding-right: 20px; } }
  &__title {
    @include f-subtitle;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 32px; } }
  &__meta {
    @include small-caps;
    line-height: 17px;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 32px; } }
  &__thumbnail {
    width: 100%;
    height: 500px;
    display: block;
    object-fit: cover;
    @include media-breakpoint-down(sm) {
      height: 360px; }
    @include media-breakpoint-down(xs) {
      height: 240px; } }
  &__more {
    display: none; }
  &__media {
    position: absolute;
    left: 120px;
    bottom: 46px;
    height: 24px;
    display: flex;
    @include media-breakpoint-down(sm) {
      left: 20px; }
    &-link {
      display: block;
      margin-right: 20px;
      transition: transform 0.2s ease-in-out;
      &:hover,
      &:focus {
        outline: none;
        transform: translateY(-4px); } }
    &-img {
      width: 24px;
      height: 24px; } }
  &__content {
    @include f-body-lg;
    &-more {
      display: none; }
    p {
      margin-bottom: 20px; } } }


