.tabs-list {
  &__item {
    border: none;
    background: transparent;
    height: 40px;
    position: relative;
    @include f-body-lg;
    font-weight: 600;
    padding: 0;
    color: $placeholder-text-color;
    transition: color 0.3s ease-in-out;
    @include media-breakpoint-down(md) {
      height: 50px; }
    &:not(.tabs-list__item--active) {
      &:hover,
      &:focus {
        color: $title-text-color; } }
    &:not(:last-child) {
      margin-right: 40px; }
    &--mobile-friendly {
      &:not(:last-child) {
        @include media-breakpoint-down(md) {
          margin-right: 20px; } } }
    &--mobile-sm {
      height: 36px;
      font-size: 16px; }
    &--search-box {
      @include media-breakpoint-down(md) {
        height: 40px;
        &:not(:last-child) {
          margin-right: 40px; } } }
    &:focus {
      outline: none; }
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      height: 2px;
      left: 0;
      width: 0;
      bottom: 0;
      background: $text-color;
      transition: width 0.3s ease-in-out, color 0.3s ease-in-out; }
    &--active {
      color: $text-color;
      &::after {
        width: 100%; } } } }

.tabs-content-list {
  &__item {
    margin-top: 73px;
    display: none;
    @include media-breakpoint-down(lg) {
      margin-top: 71px; }
    @include media-breakpoint-down(sm) {
      margin-top: 79px; }
    @include media-breakpoint-down(xs) {
      margin-top: 40px; }
    &--active {
      display: block; }
    &--static {
      margin-top: 40px; }
    &--modal {
      > p {
        margin-bottom: 22px;
        &:last-child {
          margin-bottom: 0; } } }
    &--box {
      margin-top: 55px;
      @include media-breakpoint-down(md) {
        margin-top: 37px; }
      @media screen and (max-height: 570px) {
        margin-top: 10px; } }
    &--chess {
      margin-top: 32px;
      margin-bottom: 32px;
      @include media-breakpoint-down(sm) {
        font-size: 14px;
        line-height: 18px; }
      p {
        margin-bottom: 13px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 10px; } } } } }
