.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 10px;
  user-select: none;
  &__title {
    margin-bottom: 100px;
    @include media-breakpoint-down(md) {
      margin-bottom: 40px; } }
  &__image {
    width: 400px;
    height: auto;
    max-width: 80vw;
    margin-bottom: 80px;
    pointer-events: none;
    @include media-breakpoint-down(md) {
      margin-bottom: 40px; } }
  &__text {
    text-align: center;
    margin-bottom: 24px;
    a {
      color: $primary; } } }
