.resource-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 144px;
  @include media-breakpoint-down(sm) {
    max-width: none; }
  @include media-breakpoint-down(xs) {
    justify-content: center; }
  &--lg {
    max-width: 244px; }
  &--xl {
    max-width: 100%; }
  &__item {
    width: 32px;
    height: 32px;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    &--hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0; } }
  &__link {
    display: inline-block;
    margin-left: 40px;
    margin-bottom: 40px;
    transition: opacity 0.3s ease-in-out;
    position: relative;
    @include media-breakpoint-down(lg) {
      margin-left: 38px; }
    @include media-breakpoint-down(sm) {
      margin-right: 20px;
      margin-left: 20px; }
    &--lg {
      margin-left: 0;
      margin-right: 60px;
      @include media-breakpoint-down(xs) {
        margin-left: 24px;
        margin-right: 0;
        margin-bottom: 50px; }
      .resource-list__item {
        width: 60px;
        height: 60px;
        @include media-breakpoint-down(xs) {
          height: 32px;
          width: 32px; } } }
    &--mobile-lg {
      @include media-breakpoint-down(md) {
        .resource-list__item {
          width: 40px;
          height: 40px;
          margin-right: 24px;
          margin-bottom: 0; } } }
    &:not(.resource-list--custom-hover) {
      &:hover {
        opacity: 0.5; } }
    &--custom-hover {
      &:hover {
        .resource-list__item {
          opacity: 0;
          &--hover {
            opacity: 1; } } } } } }
