.info-circle {
  display: flex;
  align-items: center;
  padding-right: 20px;
  &--mb {
    margin-bottom: 100px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px; } }
  &__icon-wrap {
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    background: $bg-gray;
    flex-shrink: 0;
    @include media-breakpoint-down(xs) {
      width: 40px;
      height: 40px; } }
  &__icon {
    width: 48px;
    height: 48px;
    @include media-breakpoint-down(xs) {
      height: 16px;
      width: 16px; } }
  &__text {
    margin: 0;
    @include f-body-lg;
    @include media-breakpoint-down(xs) {
      margin-top: 20px; } } }
