@import "./base/layout";

@import "./blocks/header";

@import "./blocks/main";

@import "./blocks/btn";
@import "./blocks/accordion";
@import "./blocks/not-found";
@import "./blocks/loader";
@import "./blocks/hero";
@import "./blocks/gradient-hero";
@import "./blocks/gradient-hero-slider";
@import "./blocks/scroll-down-caption";
@import "./blocks/card-image-info";
@import "./blocks/section";
@import "./blocks/big-statistic";
@import "./blocks/facilities-item";
@import "./blocks/resource-list";
@import "./blocks/thumbnail-preview";
@import "./blocks/circle-media";
@import "./blocks/side-card";
@import "./blocks/popup-menu";
@import "./blocks/expanded-link";
@import "./blocks/tabs";
@import "./blocks/comment";
@import "./blocks/instructor-slider";
@import "./blocks/detail-view";
@import "./blocks/map-view";
@import "./blocks/card";
@import "./blocks/mini-info";
@import "./blocks/pagination";
@import "./blocks/chess";
@import "./blocks/step-col";
@import "./blocks/number-info";
@import "./blocks/page-select";
@import "./blocks/info-row";
@import "./blocks/info-list";
@import "./blocks/welcome-row";
@import "./blocks/row-side";
@import "./blocks/special-date";
@import "./blocks/alert";
@import "./blocks/wide-slider";
@import "./blocks/text-info";
@import "./blocks/help-block";
@import "./blocks/col-text";
@import "./blocks/human-info";
@import "./blocks/value-info";
@import "./blocks/form";
@import "./blocks/modal";
@import "./blocks/feedback";
@import "./blocks/search-fail";
@import "./blocks/badge";
@import "./blocks/line-link";
@import "./blocks/card-icon-info";
@import "./blocks/search-box";
@import "./blocks/article";
@import "./blocks/info-circle";
@import "./blocks/event-card";
@import "./blocks/course-item";


@import "./blocks/footer";

@include media-breakpoint-up(md) {
  body.body-home {
    background: url(../images/bg/bubbles-pattern.webp);
    background-size: contain;
    background-repeat: no-repeat; }

  .no-webp {
    body.body-home {
      background: url(../images/bg/bubbles-pattern.jpg);
      background-size: contain;
      background-repeat: no-repeat; } } }
