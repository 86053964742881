@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'Roobert';
  src: url('../fonts/Roobert/Roobert-Regular.woff2') format('woff2'), url('../fonts/Roobert/Roobert-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roobert';
  src: url('../fonts/Roobert/Roobert-SemiBold.woff2') format('woff2'), url('../fonts/Roobert/Roobert-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Roobert';
  src: url('../fonts/Roobert/Roobert-Bold.woff2') format('woff2'), url('../fonts/Roobert/Roobert-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

