/**************************\
 *  Basic Modal Styles
 *\************************* */

.modal {
  position: relative;
  z-index: 999;
  &__feedback {
    margin-bottom: 35px; }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include media-breakpoint-up(md) {
      overflow-y: auto; } }
  &__step {
    display: none;
    &--active {
      display: block; } }
  &__split-row {
    &--mt {
      margin-top: 55px; }
    &:not(.no-gutters) {
      margin-left: -10px;
      margin-left: -10px;
      > .col,
      > [class*="col-"] {
        padding-right: 10px;
        padding-left: 10px; } }
    > [class*="col-"] {
      position: relative;
      &::after {
        content: ' ';
        position: absolute;
        right: 8px;
        width: 2px;
        height: 60px;
        top: 10px;
        border-radius: 2px;
        background: #C6D5DD;
        display: block; }
      &:nth-child(3n) {
        &::after {
          content: none; } }
      @include media-breakpoint-down(sm) {
        &::after {
          content: none; }
        &:nth-child(3n) {
          &::after {
            content: ' ';
            position: static;
            margin-top: 5px;
            margin-bottom: 32px;
            height: 2px;
            width: 100%; } } } }
    &--two-row {
      > [class*="col-"] {
        &:nth-child(2n) {
          &::after {
            content: none; } } } }
    &--no-mobile {
      > [class*="col-"] {
        &::after {
          @include media-breakpoint-down(sm) {
            content: none !important; } } } } }

  &__container {
    background-color: #F7F7F9;
    padding: 60px;
    max-width: 680px;
    width: 100%;
    //max-height: 100vh
    border-radius: 4px;
    margin-top: 140px;
    margin-bottom: 50px;
    //overflow-y: auto
    //overflow-x: hidden
    position: relative;
    &--sm {
      padding: 40px; }
    @include media-breakpoint-down(sm) {
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 0;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 116px;
      background-color: $bg-gray;
      min-height: 100vh;
      padding-top: 109px; }
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
      padding-left: 0;
      padding-right: 0;
      padding-top: 84px;
      padding-bottom: 0; } }
  &__info-card {
    margin-bottom: 60px;
    margin-top: 0;
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
      margin-top: 8px; } }
  &__btns {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -40px;
    .btn {
      margin-right: 10px;
      margin-bottom: 40px; }
    &--lg {
      .btn {
        margin-right: 32px;
        margin-bottom: 32px; } } }
  &__actions {
    display: flex;
    @include media-breakpoint-down(sm) {
      flex-direction: column; }
    .card-caption-link {
      flex: 1;
      &:not(:last-child) {
        margin-right: 40px;
        @include media-breakpoint-down(sm) {
          margin-right: 0;
          margin-bottom: 40px; } } } }
  &__cta-text {
    margin-bottom: 40px;
    @include f-subtitle;
    &--extend {
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        margin-top: -20px; } } }
  &__cta-icon {
    margin-top: 35px;
    margin-bottom: 35px;
    width: 40px;
    height: 40px;
    @include f-subtitle; }
  &__header {
    margin-bottom: 35px;
    + .modal__content {
      .modal__content-in {
        padding-top: 30px; } }
    @include media-breakpoint-down(sm) {
      background: white;
      position: absolute;
      width: auto;
      top: 0;
      left: 0;
      right: 0;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 24px;
      padding-bottom: 24px;
      box-shadow: -30px 30px 40px rgba(33, 78, 119, 0.05); }
    &--desk-hidden {
      display: none;
      @include media-breakpoint-down(sm) {
        display: block; }
      + .modal__content {
        .modal__content-in {
          padding-top: 0; } } } }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    @include f-title-forced;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: {} } }
  &__prev-step {
    background: transparent;
    border: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    top: -100px;
    left: 0;
    background-color: white;
    z-index: 5;
    &:focus {
      outline: none; }
    &::before,
    &::after {
      content: ' ';
      width: 18px;
      height: 18px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      transition: opacity 0.2s ease-in-out; }
    &::before {
      background: url(../images/sprites/main.stack.svg#image-modal-back);
      background-size: contain;
      opacity: 1; }
    &::after {
      background: url(../images/sprites/main.stack.svg#image-modal-back-primary);
      background-size: contain;
      opacity: 0; }
    &:hover,
    &:focus {
      color: $primary;
      &::before {
        opacity: 0; }
      &::after {
        opacity: 1; } }
    @include media-breakpoint-down(sm) {
      position: relative;
      background: none;
      margin-top: -20px;
      top: 0;
      left: -20px;
      display: flex;
      align-items: center;
      width: auto;
      &::before,
      &::after {
        left: 15px;
        transform: translateY(-50%); } }
    &-caption {
      display: none;
      transition: color 0.2s ease-in-out;
      @include small-caps;
      @include media-breakpoint-down(sm) {
        display: inline-block;
        padding-left: 35px; } } }

  &__close {
    background: transparent;
    border: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    top: -100px;
    right: 0;
    background-color: white;
    z-index: 5;
    &:focus {
      outline: none; }
    &::before,
    &::after {
      content: ' ';
      width: 18px;
      height: 18px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      transition: opacity 0.2s ease-in-out; }
    &::before {
      background: url(../images/sprites/main.stack.svg#image-modal-close);
      background-size: contain;
      opacity: 1; }
    &::after {
      background: url(../images/sprites/main.stack.svg#image-modal-close-primary);
      background-size: contain;
      opacity: 0; }
    &:hover,
    &:focus {
      &::before {
        opacity: 0; }
      &::after {
        opacity: 1; } }
    @include media-breakpoint-down(sm) {
      top: 8px;
      right: 0; } }

  &__content {
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    @include media-breakpoint-down(sm) {
      position: relative;
      width: 100%;
      height: 100%;
      flex-grow: 1; }
    &-in {
      @include media-breakpoint-down(sm) {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 25px !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        padding-bottom: 140px; } } }
  &__text {
    margin-bottom: 35px; }
  &__form-control {
    margin-bottom: 54px;
    &--mb-md {
      margin-bottom: 44px; }
    @include media-breakpoint-down(sm) {
      margin-bottom: 25px; }
    &--mobile-mb-md {
      @include media-breakpoint-down(sm) {
        margin-bottom: 49px; } } }

  &__check-list {
    margin-top: 35px;
    @include media-breakpoint-down(sm) {
      margin-top: 5px;
      margin-bottom: 20px; } }
  &__form-check {
    margin-bottom: 58px;
    &--mb-sm {
      margin-bottom: 24px; }
    &--mb-md {
      margin-bottom: 40px; } }
  &__form-send {
    &--mb {
      margin-bottom: 40px; } }
  &__copyright {
    font-size: 12px;
    line-height: 18px;
    a {
      color: $primary; } }
  &__form {
    min-height: 178px; } }

/**************************\
 *  Demo Animation Style
 *\************************* */
@keyframes mmfadeIn {
  from {
    opacity: 0; }

  to {
    opacity: 1; } }

@keyframes mmfadeOut {
  from {
    opacity: 1; }

  to {
    opacity: 0; } }

@keyframes mmslideIn {
  from {
    transform: translateY(15%); }

  to {
    transform: translateY(0); } }

@keyframes mmslideOut {
  from {
    transform: translateY(0); }

  to {
    transform: translateY(-10%); } }

.micromodal-slide {
  display: none;

  &.is-open {
    display: block; }

  &[aria-hidden="false"] {
    .modal__overlay {
      animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

    .modal__container {
      animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1); } }

  &[aria-hidden="true"] {
    .modal__overlay {
      animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

    .modal__container {
      animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1); } }

  .modal__container, .modal__overlay {
    will-change: transform; } }
