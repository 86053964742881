.loader {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-in-out;
  visibility: visible;
  z-index: 100;
  background: linear-gradient(180deg, #FFFFFF 0%, #D3EBFB 100%);
  opacity: 1;
  &__image {
    width: 400px;
    height: auto;
    max-width: 80vw;
    position: relative;
    transition: top 0.2s ease-in-out;
    opacity: 1;
    top: 0;
    animation: loaderImageUpDown 1s infinite  alternate; } }

.is-loaded {
  .loader {
    opacity: 0;
    visibility: hidden; } }

@keyframes loaderImageUpDown {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-20px); } }

