.card-list {
  &--pt {
    padding-top: 60px;
    @include media-breakpoint-down(md) {
      padding-top: 0; } }
  .card {
    margin-bottom: 55px; }
  &--mobile-mt {
    @include media-breakpoint-down(sm) {
      margin-top: 125px; }
    @include media-breakpoint-down(xs) {
      margin-top: 94px; } }
  &__title {
    color: $placeholder-text-color;
    margin-bottom: 70px;
    @include f-subtitle;
    @include media-breakpoint-down(sm) {
      margin-bottom: 55px; } } }

.card {
  position: relative;
  background: white;
  &--with-thumbnail {
    padding-right: 160px;
    @include media-breakpoint-down(xs) {
      padding-right: 0; } }
  &--info-window {
    .card__body {
      padding: 36px 40px 16px 30px; }
    .card__content {
      margin: 0; }
    .card__btns {
      margin-bottom: 10px; } }
  &__thumbnail {
    position: absolute;
    width: 200px;
    height: 200px;
    right: 0;
    top: 0;
    object-fit: cover;
    @include media-breakpoint-down(lg) {
      height: 180px;
      width: 180px; }
    @include media-breakpoint-down(xs) {
      position: static;
      width: 100%;
      height: 280px; } }
  &--wide-in-modal {
    margin-left: -60px;
    margin-right: -60px; }
  &--mb-row {
    margin-bottom: 40px; }
  &--mt-mobile {
    @include media-breakpoint-down(sm) {
      margin-top: 43px; } }
  &--mb-modal {
    margin-bottom: 60px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px; } }
  &__title {
    @include f-subtitle;
    margin-bottom: 23px;
    &--mb {
      margin-bottom: 40px; } }
  a {
    &:not(.btn) {
      color: $primary; } }
  @include media-breakpoint-down(xs) {
    margin-right: -20px;
    margin-left: -20px; }
  &--without-body {
    @include media-breakpoint-down(xs) {
      margin-right: 0;
      margin-left: 0; } }
  &--map-slide {
    margin-top: 15px;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0; }
    .card__text {
      margin-bottom: 16px;
      &:not(:last-child) {
        margin-bottom: 6px; } }
    .card__body {
      padding: 32px 32px 16px 32px;
      @include media-breakpoint-down(sm) {
        margin: 0; } } }
  &__company-icon {
    position: absolute;
    max-height: 50px;
    max-width: 190px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right center;
    right: 40px;
    top: 40px;
    @include media-breakpoint-down(lg) {
      position: static;
      margin-bottom: 40px;
      object-position: left center; } }

  &__body {
    padding: 38px 40px 33px 40px;
    &--wide-in-modal {
      padding-left: 60px;
      padding-right: 60px; }
    @include media-breakpoint-down(xs) {
      padding-left: 20px;
      padding-right: 20px; } }
  &__meta {
    position: absolute;
    font-size: 14px;
    left: 40px;
    top: -15px;
    display: flex;
    align-items: center;
    &-item {
      padding: 2.5px 12px;
      background: $text-special-color;
      font-weight: 500;
      text-transform: uppercase;
      font-family: $title-ff;
      margin-right: 10px;
      min-height: 29px;
      display: flex;
      align-items: center;
      &--icon {
        height: 29px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        text-transform: none;
        @include media-breakpoint-up(md) {
          display: none; }
        &-always {
          display: flex; } } }
    &-icon {
      width: 24px;
      height: 24px;
      object-fit: cover; } }


  &__text {
    &:not(:last-child) {
      margin-bottom: 16px; }
    &--mb-md {
      &:not(:last-child) {
        margin-bottom: 20px; }
      &:last-child {
        margin-bottom: 20px; } }
    &--mb-lg {
      &:not(:last-child) {
        margin-bottom: 23px; }
      &:last-child {
        margin-bottom: 23px; } }
    &--sm {
      font-size: 12px; } }
  &__actions {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      .hint--html {
        display: none; } } }
  &__btns {
    display: flex;
    margin-bottom: 10px;
    .btn {
      &:not(:last-child) {
        margin-right: 20px; } }
    @include media-breakpoint-down(md) {
      margin-bottom: 22px; }
    &--alone {
      margin-bottom: 0px;
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        margin-bottom: -20px;
        .btn {
          margin-bottom: 20px; } } } }
  &__mini-info {
    @include media-breakpoint-down(md) {
      display: none; } }
  &__content {
    @include media-breakpoint-down(md) {
      margin-bottom: 35px; }
    &--sm {
      .card__text {
        &:not(:last-child) {
          margin-bottom: 8px; } } }
    &--mb {
      margin-bottom: 13px; } } }

