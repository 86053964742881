.course-list {
  &__item {
    &:not(:last-child) {
      margin-bottom: 43px; } } }

.course-item {
  display: flex;
  &--single {
    display: block;
    padding: 0 0 0 60px;
    width: 100%;
    background: none;
    border: none;
    position: relative;
    transition: color 0.2s ease-in-out;
    text-align: left;
    &::before,
    &::after {
      content: ' ';
      display: block;
      width: 40px;
      height: 20px;
      background: $bg-gray;
      position: absolute;
      left: 0;
      top: 4px;
      border-radius: 4px;
      transition: opacity 0.2s ease-in-out;
      opacity: 1; }
    &::before {
      background: url(../images/sprites/main.stack.svg#image-arr-course-default);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
    &::after {
      background: url(../images/sprites/main.stack.svg#image-arr-course-primary);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 0; }
    &:focus {
      outline: none; }
    &:hover,
    &:focus {
      color: $primary;
      &::before {
        opacity: 0; }
      &::after {
        opacity: 1; } } }
  &__thumbnail {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-top: 5px;
    border-radius: 4px;
    margin-right: 20px; }
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column; }
  &__type {
    color: $placeholder-text-color;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 17px;
    font-family: $title-ff;
    font-weight: 500;
    margin-bottom: 12px; }
  &__name {
    padding: 0;
    border: none;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    background: none;
    transition: color 0.2s ease-in-out;
    &:not(:last-child) {
      margin-bottom: 12px; }
    &:focus {
      outline: none; }
    &:hover {
      color: $primary; } } }
