.scroll-down-caption {
  position: relative;
  padding-bottom: 18px;
  color: white !important;
  text-decoration: none !important;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  transition: opacity 0.15s ease-in-out;
  z-index: 2;
  &:hover {
    opacity: 0.5; }
  &::after {
    content: ' ';
    width: 22px;
    height: 22px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: scrollDownCaptionUpDown  1s infinite  alternate;
    background: url(../images/sprites/main.stack.svg#image-scroll-down-arr) no-repeat 0 0;
    background-size: contain;
    background-repeat: no-repeat; } }


@keyframes scrollDownCaptionUpDown {
  0% {
    transform: translateX(-50%) translateY(0); }
  100% {
    transform: translateX(-50%) translateY(5px); } }




